import { Link as RouterLink } from 'react-router-dom';
import { TOP_LEVEL_ROUTES, CLINICAL_PAGE_ROUTES } from './routes';
import { pageKey } from './pageConstants';

export const ASHCareSubdomain = () => {
	const { origin } = window.location;
	if (origin.includes('localhost') || origin.includes('dev')) {
		return 'dev.';
	}
	if (origin.includes('stg')) {
		return 'stg.';
	}
	if (origin.includes('tst')) {
		return 'tst.';
	}
	return '';
};

export const HOME_URL = `/ASH/default.aspx`;
// when testing this locally, the user will be signed out of the dev clinical ashlink site
export const LOGOUT_URL = `/clinical/auth/logout`;
export const ASHCARE_URL = `//${ASHCareSubdomain()}portal.ashcare.com/landing`;

const FAQ_PATH = `/${TOP_LEVEL_ROUTES.help}/${CLINICAL_PAGE_ROUTES.faq}`;

export const MESSAGE_CENTER = {
	key: pageKey.messageCenter,
	label: 'Message Center',
};

export const PROFILE_ITEMS = [
	{
		key: pageKey.accountManagement,
		label: 'Account Management',
		order: 1,
	},
	{
		key: pageKey.transactionSummary,
		label: 'Transaction Summary',
		order: 2,
	},
	{
		key: pageKey.favoriteCPTCode,
		label: 'Favorite CPT Codes',
		order: 3,
	},
	{
		key: pageKey.confirmUpdateInformation,
		label: 'Confirm/Update Your Information',
		order: 4,
	},
];

export const FOOTER_ITEMS = [
	{
		key: pageKey.contactUs,
		label: 'Contact Us',
		newTab: false,
	},
	{
		key: pageKey.privacyStatement,
		label: 'Privacy Statement',
		newTab: false,
	},
	{
		key: pageKey.termsAndCondition,
		label: 'Terms & Conditions',
		newTab: false,
	},
	{
		key: pageKey.frequentlyAskedQuestion,
		label: 'FAQ',
		Component: RouterLink,
		path: FAQ_PATH,
		newTab: false,
	},
];

export const HELP_NAV_ITEMS = [
	{
		key: pageKey.ASHLinkWebsiteTip,
		label: 'ASHLink Website Tips',
		order: 1,
	},
	{
		key: pageKey.frequentlyAskedQuestion,
		label: 'FAQ',
		path: `/${TOP_LEVEL_ROUTES.help}/${CLINICAL_PAGE_ROUTES.faq}`,
		order: 2,
	},
	{
		key: pageKey.submitAnOnlineQuestionOrComment,
		label: 'Submit an online question or comment',
		order: 3,
	},
];

export const RESOURCES_NAV_ITEMS = [
	{
		key: pageKey.clientSummary,
		path: `/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.clientSummaries}`,
		label: 'Client Summaries',
		order: 1,
	},
	{
		key: pageKey.CPSAnnualReview,
		label: 'CPS Annual Review/Tier Assignment Letter',
		order: 2,
	},
	{
		key: pageKey.resourcesForm,
		path: `/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.forms}`,
		label: 'Forms',
		order: 3,
	},
	{
		key: pageKey.newsletter,
		path: `/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.newsletters}`,
		label: 'Newsletters',
		order: 4,
	},
	{
		key: pageKey.notification,
		path: `/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.notifications}`,
		label: 'Notifications',
		order: 5,
	},
	{
		key: pageKey.operationsManual,
		path: `/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.operationsManual}`,
		label: 'Operations Manuals',
		order: 6,
	},
	{
		key: pageKey.practitionerEducationLibrary,
		path: `/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.practitionerEducationLibrary}`,
		label: 'Practitioner Education Library',
		order: 7,
	},
	{
		key: pageKey.practitionerServiceAgreement,
		path: `/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.practitionerServiceAgreements}`,
		label: 'Practitioner Services Agreements',
		order: 8,
	},
	{
		key: pageKey.providerEducationLibrary,
		path: `/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.providerEducationLibrary}`,
		label: 'Provider Education Library',
		order: 9,
	},
	{
		key: pageKey.providerServiceAgreement,
		path: `/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.providerServiceAgreements}`,
		label: 'Provider Services Agreements',
		order: 10,
	},
	{
		key: pageKey.valueAddedProgram,
		path: `/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.valueAddedProgram}`,
		label: 'Value Added Programs',
		order: 11,
	},
	{
		key: pageKey.providerPractitionerVirtualServicesAgreement,
		path: `/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.providerPractitionerVirtualServicesAgreement}`,
		label: 'Provider Services / Practitioner Virtual Service Agreement',
		order: 12,
	},
];

export const BATCH_CLAIMS_NAV_ITEMS = [
	{
		key: pageKey.ASHClearinghouse,
		label: 'ASH Clearinghouse',
		order: 1,
	},
	{
		key: pageKey.batchClaimsSummary,
		label: 'Batch Claims Summary',
		order: 2,
	},
	{
		key: pageKey.batchClaimsUpload,
		label: 'Batch Claims Upload',
		order: 3,
	},
];

export const TOP_SEARCH_NAV_ITEMS = [
	{
		key: pageKey.newPatientSearch,
		label: 'New Patient',
		order: 1,
	},
	{
		key: pageKey.existingPatientSearch,
		label: 'Existing Patient',
		order: 2,
	},
	{
		key: pageKey.claimsSearch,
		label: 'Claims',
		order: 3,
	},
	{
		key: pageKey.paymentsSearch,
		label: 'Payments',
		order: 4,
	},
	{
		key: pageKey.remittanceAdviceSearch,
		label: 'Remittance Advice',
		order: 5,
	},
	{
		key: pageKey.MNRFormSearch,
		label: 'MNR Form',
		order: 6,
	},
	{
		key: pageKey.reopenModificationsSearch,
		label: 'Reopen/Modifications',
		order: 7,
	},
];

export const TOP_NAV_ITEMS = [
	{
		key: 'home',
		label: 'Home',
		isDropdown: false,
		order: 1,
	},
	{
		key: 'search',
		label: 'Search',
		isDropdown: true,
		menuItems: TOP_SEARCH_NAV_ITEMS,
		order: 2,
	},
	{
		key: 'member-referral',
		label: 'Referrals',
		isDropdown: false,
		order: 3,
	},
	{
		key: 'resources',
		label: 'Resources',
		isDropdown: true,
		menuItems: RESOURCES_NAV_ITEMS,
		order: 4,
	},
	{
		key: 'batch-claims',
		label: 'Batch Claims',
		isDropdown: true,
		menuItems: BATCH_CLAIMS_NAV_ITEMS,
		order: 5,
	},
	{
		key: 'compliance',
		label: 'Compliance',
		isDropdown: false,
		path: TOP_LEVEL_ROUTES.compliance,
		order: 6,
	},
	{
		key: 'help',
		label: 'Help',
		isDropdown: true,
		menuItems: HELP_NAV_ITEMS,
		order: 7,
	},
	{
		key: 'ashcare',
		label: 'ASHCare',
		isDropdown: false,
		isLegacy: true,
		path: ASHCARE_URL,
		isExternal: true,
		order: 8,
	},
];

export const MY_ACCOUNT_NAV_ITEMS = [
	{
		key: 'account-management',
		label: 'Account Management',
		order: 1,
	},
	{
		key: 'manage-sub-accounts',
		label: 'Manage Sub Accounts',
		order: 2,
	},
	{
		key: 'transaction-summary',
		label: 'Transaction Summary',
		order: 3,
	},
	{
		key: 'favorite-cpt-codes',
		label: 'Favorite CPT Codes',
		order: 4,
	},
	{
		key: 'update-info',
		label: 'Confirm/Update Your Information',
		order: 5,
	},
];
