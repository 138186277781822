import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const ValueAddedProgramDetails = ({ valueAddedVendor, onBackButtonClick }) => {
	const onVendorButtonClick = vendorUrl =>
		window.open(vendorUrl, '_self', 'noreferrer');

	return (
		<>
			<Box mb={2}>
				<img
					src={valueAddedVendor.imageUrl}
					alt={`${valueAddedVendor.displayName} logo`}
					width='230'
				/>
			</Box>
			<Box mb={4}>
				<Typography fontWeight='fontWeightBold' gutterBottom>
					ASH Practitioner Discount
				</Typography>
				<Typography>{valueAddedVendor.providerDiscount}</Typography>
			</Box>
			{valueAddedVendor.howToRedeemDiscount && (
				<Box mb={4}>
					<Typography fontWeight='fontWeightBold' gutterBottom>
						How to Redeem
					</Typography>
					<Typography>
						{valueAddedVendor.howToRedeemDiscount}
					</Typography>
				</Box>
			)}
			<Stack mb={4} direction={{ xs: 'column', sm: 'row' }} spacing={2}>
				<Button
					variant='contained'
					role='link'
					endIcon={<OpenInNewIcon />}
					onClick={() =>
						onVendorButtonClick(valueAddedVendor.vendorLink)
					}
				>
					Go to vendor site
				</Button>
				<Button variant='outlined' onClick={onBackButtonClick}>
					Back to value added programs
				</Button>
			</Stack>
			<Typography gutterBottom>
				(*For security purposes, your current ASHLink session will end
				and you will need to re-authenticate into ASHLink should you
				wish to return to this site. Please do not use the
				&apos;Back&apos; button feature, as returning to the ASHLink
				site by use of the &apos;Back&apos; button will result in an
				error.)
			</Typography>
		</>
	);
};

export default ValueAddedProgramDetails;

ValueAddedProgramDetails.propTypes = {
	valueAddedVendor: PropTypes.object.isRequired,
	onBackButtonClick: PropTypes.func.isRequired,
};
