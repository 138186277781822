import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme, alpha } from '@mui/material/styles';
import PractitionerInfo from './PractitionerInfo';

const PractitionerInfoHeader = () => {
	const { palette } = useTheme();
	return (
		<Box
			sx={{
				width: '100%',
				height: '2rem',
				backgroundColor: alpha(palette.primary.main, 0.08),
			}}
		>
			<Container
				sx={{
					px: { xs: 1, sm: 1 },
					maxWidth: '100%',
				}}
			>
				<Box
					sx={{
						minWidth: '900px',
						pr: '40px',
						display: 'flex',
						height: '36px',
						alignItems: 'center',
						justifyContent: 'flex-end',
					}}
				>
					<PractitionerInfo />
				</Box>
			</Container>
		</Box>
	);
};
export default PractitionerInfoHeader;
