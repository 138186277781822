import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const ConfirmationAuth = ({ memberInformation, eAuthNo }) => (
	<Container>
		<Stack
			direction='column'
			alignItems='center'
			justifyContent='center'
			spacing={2}
			marginBottom={3}
		>
			<CheckCircleIcon style={{ color: 'green' }}> </CheckCircleIcon>
			<Typography variant='h5' component='h1'>
				Your Medical Record has been successfully submitted.
			</Typography>
		</Stack>

		<Grid container direction='column' spacing={2} marginBottom={3}>
			<Grid item>
				<Typography>
					<b>E-MNR # : </b>
					{eAuthNo}
				</Typography>
			</Grid>
			{/* <Grid item>
				<Typography>
					<b>Submitted : </b>
					{submittedDate}
				</Typography>
			</Grid> */}
		</Grid>
		<Box>
			<Typography color='primary'>
				<strong>Member Information</strong>
			</Typography>
		</Box>
		<Divider sx={{ marginTop: 1, marginBottom: 3 }} />
		<Grid container direction='column' spacing={2} marginBottom={3}>
			<Grid item>
				<Typography>
					<b>First Name : </b>
					{memberInformation?.MemFirst}
				</Typography>
			</Grid>
			<Grid item>
				<Typography>
					<b>Last Name : </b>
					{memberInformation?.MemLast}
				</Typography>
			</Grid>
			<Grid item>
				<Typography>
					<b>DOB : </b>
					{memberInformation?.DOB
						? dayjs(memberInformation?.DOB).format('MM/DD/YYYY')
						: ''}
				</Typography>
			</Grid>
			<Grid item>
				<Typography>
					<b>Member ID : </b>
					{memberInformation?.MemId}
				</Typography>
			</Grid>
			<Grid item>
				<Typography>
					<b>Group # : </b>
					{memberInformation?.GroupNo}
				</Typography>
			</Grid>
			<Grid item>
				<Typography>
					<b>Address : </b>
					{memberInformation?.Address}
				</Typography>
			</Grid>
		</Grid>

		<Stack>
			<Typography>
				<strong>
					Medical Records have been attached to your submission. This
					Information cannot be displayed at this time.
				</strong>
			</Typography>
		</Stack>
	</Container>
);

ConfirmationAuth.propTypes = {
	memberInformation: PropTypes.object.isRequired,
	eAuthNo: PropTypes.number,
};

ConfirmationAuth.defaultProps = {
	eAuthNo: 0,
};

export default ConfirmationAuth;
