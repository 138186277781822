import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import {
	PostLegacySessionDataFn,
	PutPartialAuthFormFn,
	PostCreateAuthfn,
} from '../../../../data/apiCalls';
import useSessionContext from '../../../../data/queryHooks/useSessionContext';
import useSessionData from '../../../../data/queryHooks/useSessionData';
import {
	RedirectToLegacyForm,
	legacyAuthFormsList,
} from '../../../../scripts/legacyAuthSubmissionRedirectHelper';
import CustomLoading from '../../../shared/CustomLoading';
import { AuthSubmissionContext } from './shared/AuthSubmissionContext';
import CompleteAuth from './shared/CompleteAuth/CompleteAuth';
import ConfirmationAuth from './shared/ConfirmationAuth';
import MemberInformation from './shared/MemberInformation';
import ReviewAuth from './shared/ReviewAuth/ReviewAuth';
import SelectAuth from './shared/SelectAuth/SelectAuth';

const AuthSubmission = () => {
	const {
		providerNetCode,
		providerSpecialtyCode,
		providerId,
		practitionerId,
		practitionerSpecialtyCode,
		userInfo,
	} = useSessionContext();
	const { data: memberSessionInfo, isLoading } =
		useSessionData('SharedPatient');
	const [submissionStep, setSubmissionStep] = useState(0);
	const [errorMessage, setErrorMessage] = useState();
	const [selectionData, setSelectionData] = useState(null);
	const [authFormData, setAuthFormData] = useState(null);
	const [eAuthNo, setEAuthNo] = useState(0);
	const [submitClick, setSubmitClicked] = useState(false);

	const {
		mutate: mutateLegacySessionData,
		isSuccess: legacySessionDataPostIsSuccess,
		isLoading: legacySessionDataPostIsLoading,
	} = useMutation(PostLegacySessionDataFn, {
		onError: () => {
			setErrorMessage(
				"We're sorry, something went wrong. We're unable to load the form at this time. Please try again later."
			);
		},
	});

	useEffect(() => {
		if (legacySessionDataPostIsSuccess) {
			RedirectToLegacyForm(selectionData.authFormType);
		}
	}, [legacySessionDataPostIsSuccess]);

	const {
		mutate: mutatePartialAuthForm,
		isSuccess: PartialAuthFormPutIsSuccess,
		isLoading: PartialAuthFormPutIsLoading,
	} = useMutation(PutPartialAuthFormFn, {
		onSuccess: ({ data: { data: { eAuthNo: eAuthNumber } = {} } = {} }) => {
			setEAuthNo(eAuthNumber);
		},
		onError: () => {
			setErrorMessage(
				"We're sorry, something went wrong. We're unable to load the form at this time. Please try again later."
			);
		},
	});

	useEffect(() => {
		if (eAuthNo && selectionData?.authFormType) {
			if (legacyAuthFormsList.includes(selectionData.authFormType)) {
				const authNumberObject = {
					AuthNumber: eAuthNo,
				};
				mutateLegacySessionData({
					name: 'SharedAuthNumber',
					jsonData: JSON.stringify(authNumberObject),
				});
			} else {
				setSubmissionStep(1);
				window.scrollTo({
					top: 0,
					left: 0,
				});
			}
		}
	}, [eAuthNo, selectionData]);

	const {
		mutate: mutateCreateAuthForm,
		isSuccess: CreateAuthFormPostIsSuccess,
		isLoading: CreateAuthFormPostIsLoading,
	} = useMutation(PostCreateAuthfn, {
		onSuccess: ({ data: { resultCode = {} } = {} }) => {
			if (resultCode !== 0) {
				setErrorMessage(
					'Submission failed. \n Please try again later.'
				);
			}
		},
		onError: () => {
			setErrorMessage('Submission failed. \n Please try again later.');
		},
	});

	useEffect(() => {
		if (eAuthNo !== 0 && !errorMessage && CreateAuthFormPostIsSuccess) {
			setSubmissionStep(3);
			window.scrollTo({
				top: 0,
				left: 0,
			});
		}
	}, [eAuthNo, CreateAuthFormPostIsSuccess]);

	useEffect(() => {
		if (eAuthNo !== 0 && submitClick === true) {
			mutateCreateAuthForm({
				selectionData,
				eAuthNo,
				authFormData,
			});
		}
	}, [submitClick, eAuthNo]);

	const handleReviewComplete = () => {
		setSubmitClicked(true);
	};

	const onSelectAuthContinueClick = formSelectionData => {
		setSelectionData(formSelectionData);
		mutatePartialAuthForm({
			sessionProviderId: providerId,
			sessionProviderSpecialtyCode: providerSpecialtyCode,
			memberSessionInfo,
			formSelectionData,
		});
	};
	const handleFormComplete = () => {
		setSubmissionStep(2);
		window.scrollTo({
			top: 0,
			left: 0,
		});
	};
	const handleEditClick = () => {
		setErrorMessage(undefined);
		setSubmissionStep(1);
		window.scrollTo({
			top: 0,
			left: 0,
		});
	};

	const contextValue = useMemo(
		() => ({
			sessionProviderId: providerId,
			sessionProviderSpecialtyCode: providerSpecialtyCode,
			sessionProviderNetCode: providerNetCode,
			sessionProviderFirstName: userInfo.firstName,
			sessionProviderLastName: userInfo.lastName,
			sessionPractitionerId: practitionerId,
			sessionPractitionerSpecialtyCode: practitionerSpecialtyCode,
			errorMessage,
			setError: setErrorMessage,
			selectionData,
			setSelectionData,
			authFormData,
			setAuthFormData,
			memberSessionInfo,
			eAuthNo,
		}),
		[
			providerId,
			providerSpecialtyCode,
			providerNetCode,
			userInfo.firstName,
			userInfo.lastName,
			practitionerId,
			practitionerSpecialtyCode,
			errorMessage,
			setErrorMessage,
			selectionData,
			setSelectionData,
			authFormData,
			setAuthFormData,
			memberSessionInfo,
			eAuthNo,
		]
	);

	return (
		<AuthSubmissionContext.Provider value={contextValue}>
			<Container>
				<Stack spacing={3}>
					{errorMessage ? (
						<>
							<Alert severity='error'>{errorMessage}</Alert>
							<Typography variant='h5' component='h1'>
								Submit an E-MNR
							</Typography>
						</>
					) : (
						<Typography variant='h5' component='h1' mt={3}>
							Submit an E-MNR
						</Typography>
					)}
					<Divider />
					<Stack direction='row' justifyContent='center'>
						<Stepper
							activeStep={submissionStep}
							sx={{ maxWidth: 900, flex: 1 }}
						>
							<Step key='Select E-MNR'>
								<StepLabel>Select E-MNR</StepLabel>
							</Step>
							<Step key='Complete E-MNR'>
								<StepLabel>Complete E-MNR</StepLabel>
							</Step>
							<Step key='Review E-MNR'>
								<StepLabel>Review E-MNR</StepLabel>
							</Step>
						</Stepper>
					</Stack>
					{(submissionStep === 0 ||
						submissionStep === 1 ||
						submissionStep === 2) && (
						<MemberInformation
							memberInformation={memberSessionInfo ?? {}}
							isLoading={isLoading}
						/>
					)}
					{submissionStep === 0 &&
						!PartialAuthFormPutIsLoading &&
						!PartialAuthFormPutIsSuccess &&
						!legacySessionDataPostIsLoading &&
						!legacySessionDataPostIsSuccess && (
							<SelectAuth
								onContinueClick={onSelectAuthContinueClick}
							/>
						)}
					{submissionStep === 0 &&
						(PartialAuthFormPutIsLoading ||
							PartialAuthFormPutIsSuccess ||
							legacySessionDataPostIsLoading) && (
							<CustomLoading />
						)}
					{submissionStep === 0 && legacySessionDataPostIsSuccess && (
						<Paper elevation={1} variant='wrapper'>
							<Typography variant='h6'>
								Redirecting to selected form
							</Typography>
						</Paper>
					)}
					{submissionStep === 1 && (
						<CompleteAuth onFormComplete={handleFormComplete} />
					)}
					{submissionStep === 2 && (
						<ReviewAuth
							onEditClick={handleEditClick}
							onSubmitClick={handleReviewComplete}
						/>
					)}
					{submissionStep === 2 && CreateAuthFormPostIsLoading && (
						<CustomLoading />
					)}
					{submissionStep === 2 &&
						CreateAuthFormPostIsSuccess &&
						!errorMessage &&
						eAuthNo !== 0 && (
							<Paper elevation={1} variant='wrapper'>
								<Typography variant='h6'>
									Redirecting to confirmation page
								</Typography>
							</Paper>
						)}
					{submissionStep === 3 && (
						<ConfirmationAuth
							memberInformation={memberSessionInfo}
							eAuthNo={eAuthNo}
						/>
					)}
				</Stack>
			</Container>
		</AuthSubmissionContext.Provider>
	);
};

export default AuthSubmission;
