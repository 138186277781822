export const dateToShortString = date => {
	if (!date) {
		return null;
	}
	return new Date(date).toLocaleDateString('en-US', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	});
};

export const stringtoNAIfNullOrEmpty = untestedString => {
	if (untestedString === 0 || untestedString === '0') {
		return untestedString;
	}
	if (!untestedString) {
		return 'N/A';
	}
	return untestedString;
};
