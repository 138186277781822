import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const SelectSubmissionType = ({ onSelect }) => (
	<Box>
		<Typography fontWeight='bold'>Select Submission Type:</Typography>
		<RadioGroup
			data-testid='select-submission-type'
			onChange={(e, v) => {
				onSelect(v);
			}}
		>
			<FormControlLabel
				value='ASH Electronic MNR Form'
				control={<Radio />}
				label='ASH Electronic MNR Form'
			/>
			<FormControlLabel
				value='Medical Records'
				control={<Radio />}
				label='Medical Records (Requires PDF attachment)'
			/>
		</RadioGroup>
	</Box>
);

SelectSubmissionType.propTypes = {
	onSelect: PropTypes.func.isRequired,
};

export default SelectSubmissionType;
