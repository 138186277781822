import Typography from '@mui/material/Typography';
import ClinicalFileType from '../../scripts/clinicalFileType';

import ResourceTablePage from './shared/ResourceTablePage';

const clinicalFileType =
	ClinicalFileType.ashlinkClinicalProviderServicesAgreementResource.value;

const ProviderServicesAgreements = () => {
	const pageTitle = 'Provider Services Agreements';
	const resourceColumnTitle = 'Provider Services Agreement';
	const pageSummary = (
		<Typography mb={2}>
			The ASH Provider Services Agreement goes into detail about Provider
			obligations agreed to for participation in our health care network.
			The table of contents gives a quick overview of all sections of the
			Provider Services Agreement. Other portions of the Provider Services
			Agreement including the Provider Operations Manual, Client Summaries
			and reimbursement Fee Schedule Amounts are also posted in the
			Resources section.
		</Typography>
	);

	return (
		<ResourceTablePage
			pageTitle={pageTitle}
			pageSummary={pageSummary}
			resourceColumnTitle={resourceColumnTitle}
			clinicalFileType={clinicalFileType}
			disableReleaseDateColumn
		/>
	);
};

export default ProviderServicesAgreements;
