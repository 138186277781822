import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { formatAddress } from '../../../../../scripts/addressHelper';
import { GetAuthFormLabelFromLegacyRehabMnrFormType } from '../../../../../scripts/authFormHelpers';
import { AuthSubmissionContext } from './AuthSubmissionContext';

const Header = ({ children }) => (
	<Typography variant='caption'>{children}</Typography>
);

Header.propTypes = {
	children: PropTypes.node.isRequired,
};

const MemberInformation = ({ memberInformation, isLoading }) => {
	const { selectionData } = useContext(AuthSubmissionContext);
	const isMobile = useIsMobile();

	return (
		<Paper variant='wrapper'>
			<Box>
				<Typography variant='h5' component='h1'>
					Member Information
				</Typography>
			</Box>
			<Divider sx={{ marginTop: 4, marginBottom: 2 }} />
			<Grid container direction='row' spacing={isMobile ? 1 : 6}>
				<Grid item>
					<Header>First Name</Header>
					<Typography>
						{isLoading ? <Skeleton /> : memberInformation?.MemFirst}
					</Typography>
				</Grid>
				<Grid item>
					<Header>Last Name</Header>
					<Typography>
						{isLoading ? <Skeleton /> : memberInformation?.MemLast}
					</Typography>
				</Grid>
				<Grid item>
					<Header>Date of Birth</Header>
					<Typography>
						{isLoading && <Skeleton />}
						{!isLoading && memberInformation?.DOB
							? dayjs(memberInformation?.DOB).format('MM/DD/YYYY')
							: ''}
					</Typography>
				</Grid>
				<Grid item>
					<Header>Member ID</Header>
					<Typography>
						{isLoading ? <Skeleton /> : memberInformation?.MemId}
					</Typography>
				</Grid>
				{selectionData?.authFormType && (
					<Grid item>
						<Header>E-MNR Form</Header>
						<Typography>
							{GetAuthFormLabelFromLegacyRehabMnrFormType(
								selectionData.authFormType
							)}
						</Typography>
					</Grid>
				)}
				{selectionData?.serviceLocation && (
					<Grid item>
						<Header>Service Location</Header>
						<Typography>
							{selectionData.serviceLocation.name},{' '}
							{formatAddress(
								selectionData.serviceLocation.address1,
								selectionData.serviceLocation.address2,
								selectionData.serviceLocation.city,
								selectionData.serviceLocation.state,
								selectionData.serviceLocation.zipCode
							)}
						</Typography>
					</Grid>
				)}
			</Grid>
		</Paper>
	);
};

MemberInformation.propTypes = {
	memberInformation: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
};

MemberInformation.defaultProps = {
	isLoading: false,
};

export default MemberInformation;
