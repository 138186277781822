// These values are from dbs_ihis.dbo.Dr_Specialty table
const Specialty = Object.freeze({
	Chiropractic: 1,
	1: 'Chiropractic',
	HPPTProvider: 3,
	3: 'HP PT Provider',
	HPOTProvider: 4,
	4: 'HP OT Provider',
	MedicalDoctor: 14,
	14: 'Medical Doctor',
	RehabProvider: 26,
	26: 'Rehab Provider',
	PTPractitioner: 27,
	27: 'PT Practitioner',
	OTPractitioner: 28,
	28: 'OT Practitioner',
	SLPPractitioner: 36,
	36: 'SLP Practitioner',
	AthleticTrainer: 42,
	42: 'Athletic Trainer',
	CignaPhysicalMedicine: 46,
	46: 'Cigna Physical Medicine',
});

export const SpecialtyDescription = Object.freeze({
	3: 'Physical Therapy',
	4: 'Occupational Therapy',
	14: 'Medical',
	26: 'Rehabilitation Services',
	27: 'Physical Therapy',
	28: 'Occupational Therapy',
	36: 'Speech Therapy',
	42: 'Athletic Trainer',
	46: 'Rehabilitation Services',
});

export const isVPTR = providerNetCode => providerNetCode === 116;

export const specialtyLabel = (
	providerSpecialtyCode,
	practitionerSpecialtyCode
) => {
	switch (providerSpecialtyCode) {
		case Specialty.HPPTProvider:
			return 'Physical Therapy';
		case Specialty.HPOTProvider:
			return 'Occupational Therapy';
		case Specialty.MedicalDoctor:
			return 'Medical';
		case Specialty.CignaPhysicalMedicine:
			return 'Rehabilitation Services';
		case Specialty.RehabProvider:
			switch (practitionerSpecialtyCode) {
				case Specialty.PTPractitioner:
					return 'Physical Therapy';
				case Specialty.OTPractitioner:
					return 'Occupational Therapy';
				case Specialty.SLPPractitioner:
					return 'Speech Therapy';
				case Specialty.AthleticTrainer:
					return 'Athletic Trainer';
				default:
					return 'Rehabilitation Services';
			}
		default:
			return null;
	}
};

export default Specialty;
