import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import Specialty from '../../../../../../../scripts/specialty';
import { AuthSubmissionContext } from '../../AuthSubmissionContext';
import {
	AuthFormLabels,
	LegacyRehabAuthFormTypes,
} from '../../../../../../../scripts/authFormHelpers';

const AuthForms = {
	PTOT: [
		{
			label: AuthFormLabels.PtotNuero,
			value: LegacyRehabAuthFormTypes.Neuro_Peds,
		},
		{
			label: AuthFormLabels.PtotOrthopedic,
			value: LegacyRehabAuthFormTypes.Orthopedic,
		},
		{
			label: AuthFormLabels.PtotPediatric,
			value: LegacyRehabAuthFormTypes.Pediatric,
		},
	],
	STNewCare: [
		{
			label: AuthFormLabels.StDysphagia,
			value: LegacyRehabAuthFormTypes.Dysphagia,
		},
		{
			label: AuthFormLabels.StCognitive,
			value: LegacyRehabAuthFormTypes.Cognitive,
		},
	],
	STContinuingCare: [
		{
			label: AuthFormLabels.StContinuingCare,
			value: LegacyRehabAuthFormTypes.ContinuingCare,
		},
	],
	AT: [
		{
			label: AuthFormLabels.AtOrthopedic,
			value: LegacyRehabAuthFormTypes.AT_Orthopedic,
		},
	],
};

const getAuthFormsToRender = (
	providerSpecialtyCode,
	practitionerSpecialtyCode,
	isNewCare
) => {
	switch (providerSpecialtyCode) {
		case Specialty.HPPTProvider:
		case Specialty.HPOTProvider:
			return [...AuthForms.PTOT];
		case Specialty.MedicalDoctor:
		case Specialty.CignaPhysicalMedicine:
			return [
				...AuthForms.PTOT,
				...(isNewCare
					? AuthForms.STNewCare
					: AuthForms.STContinuingCare),
				...AuthForms.AT,
			];
		case Specialty.RehabProvider:
			switch (practitionerSpecialtyCode) {
				case Specialty.PTPractitioner:
				case Specialty.OTPractitioner:
					return [...AuthForms.PTOT];
				case Specialty.SLPPractitioner:
					return [
						...(isNewCare
							? AuthForms.STNewCare
							: AuthForms.STContinuingCare),
					];
				case Specialty.AthleticTrainer:
					return [...AuthForms.AT];
				default:
					return [
						...AuthForms.PTOT,
						...(isNewCare
							? AuthForms.STNewCare
							: AuthForms.STContinuingCare),
						...AuthForms.AT,
					];
			}
		default:
			return [];
	}
};

const SelectAuthForm = ({
	practitionerSpecialtyCodeOverride,
	isMedicalRecords,
	isNewCare,
	onSelect,
	value,
}) => {
	const { sessionProviderSpecialtyCode, sessionPractitionerSpecialtyCode } =
		useContext(AuthSubmissionContext);
	return (
		<Box>
			<Typography fontWeight='bold'>Select E-MNR Form:</Typography>
			{isMedicalRecords ? (
				<Typography>Medical Records</Typography>
			) : (
				<RadioGroup
					value={value}
					onChange={(e, v) => {
						onSelect(Number(v));
					}}
				>
					{getAuthFormsToRender(
						sessionProviderSpecialtyCode,
						practitionerSpecialtyCodeOverride ??
							sessionPractitionerSpecialtyCode,
						isNewCare
					).map(form => (
						<FormControlLabel
							key={form.label}
							value={form.value}
							control={<Radio />}
							label={form.label}
						/>
					))}
				</RadioGroup>
			)}
		</Box>
	);
};

SelectAuthForm.propTypes = {
	practitionerSpecialtyCodeOverride: PropTypes.number,
	isMedicalRecords: PropTypes.bool.isRequired,
	isNewCare: PropTypes.bool.isRequired,
	onSelect: PropTypes.func.isRequired,
	value: PropTypes.string,
};

SelectAuthForm.defaultProps = {
	practitionerSpecialtyCodeOverride: null,
	value: null,
};

export default SelectAuthForm;
