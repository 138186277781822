import { rest } from 'msw';

export const results = {
	fail: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 32,
		subResultText: 'ContentManagementApiFail',
		errors: [
			{
				message:
					'Expected exception. (TransactionId: 1b62f160-92ba-4999-b3f1-e2a45c0d99af)',
			},
		],
		date: '2023-02-22T05:16:50.4312862Z',
	},
	success: {
		resultCode: 0,
		resultText: 'Success',
		data: {
			entries: [
				{
					displayName: 'Choose Healthy',
					vendorLink: 'https://www.choosehealthy.com/home?pc=BY',
					description:
						'Access to discounted health and fitness products and services.',
					providerDiscount:
						"Up to 57% on a variety of popular products from health and fitness vendors, with free shipping and handling. Access to over 10,000 fitness centers and YMCA's nationwide for $25 per month (plus a $25 enrollment fee and applicable taxes).",
					howToRedeemDiscount:
						'No code, just link to program specific URL',
					imageUrl:
						'//images.ctfassets.net/1g6by23er3py/14ejsQ0TMNJuFurB551VaK/066d60ce62e4f02e117c3b74dce0e804/CHLogo_tcm7-151069.png',
					id: 'u3xoPt0UlsApAI0mPQt4C',
				},
				{
					displayName: 'Appointment.com',
					vendorLink: 'https://www.appointment.com/',
					description:
						'Custom, online calendar for patients and clients to schedule their appointments 24 hours a day, 7 days a week',
					providerDiscount:
						'$150 initial set-up fee and a $79 monthly fee for all online transactions',
					howToRedeemDiscount: 'Use code ASHN on the website.',
					imageUrl:
						'//images.ctfassets.net/1g6by23er3py/4qnesNQPS6QyROPBcMRSe/62eb28db6d458bed2db08894d099fe46/appointmentcom_tcm7-2381.jpg',
					id: '3WyZUbVZ9cHPavswGbRWZ1',
				},
				{
					displayName: 'ApexEDI',
					vendorLink: 'https://apexedi.com/',
					description:
						'Electronic Clearinghouse for Claims Submission',
					providerDiscount:
						'$150 initial set-up fee and a $79 monthly fee for all online transactions',
					howToRedeemDiscount: 'Use code ASHN on the website.',
					imageUrl:
						'//images.ctfassets.net/1g6by23er3py/3xbM8CdaV2pYcRWCinZT71/d428379b165b7c2a09f994c0322e7af4/ApexLogo_tcm7-2379.gif',
					id: '3Zq0LKCPSBsosS6VnjePKi',
				},
			],
		},
		date: '2023-02-15T23:25:39.313711Z',
	},
	notFound: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 9001,
		subResultText: 'ContentNotFound',
		errors: [
			{
				message: 'No content returned from Contentful.',
			},
		],
		date: '2023-02-22T05:36:28.3714538Z',
	},
};

export const valueAddedVendorBySpecialtySuccess = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ValueAddedVendorBySpecialty',
		(req, res, ctx) => res(ctx.status(200), ctx.json(results.success))
	),
];

export const valueAddedVendorBySpecialtyLoading = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ValueAddedVendorBySpecialty',
		(req, res, ctx) => res(ctx.delay('infinite'))
	),
];

export const valueAddedVendorBySpecialtyFailWithSubResultCode32 = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ValueAddedVendorBySpecialty',
		(req, res, ctx) => res(ctx.status(200), ctx.json(results.fail))
	),
];

export const valueAddedVendorBySpecialtyNoResults = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ValueAddedVendorBySpecialty',
		(req, res, ctx) => res(ctx.status(200), ctx.json(results.notFound))
	),
];

export const valueAddedVendorBySpecialtyFail = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ValueAddedVendorBySpecialty',
		(req, res, ctx) => res(ctx.status(500), ctx.json(results.fail))
	),
];

export const valueAddedVendorBySpecialty = [
	...valueAddedVendorBySpecialtySuccess,
	...valueAddedVendorBySpecialtyLoading,
	...valueAddedVendorBySpecialtyFail,
	...valueAddedVendorBySpecialtyNoResults,
	...valueAddedVendorBySpecialtyFailWithSubResultCode32,
];
