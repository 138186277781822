import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {
	AuthTypeLabels,
	AuthTypes,
} from '../../../../../../../scripts/authFormHelpers';

const SelectAuthType = ({ onSelect }) => (
	<Box>
		<Typography fontWeight='bold'>Select E-MNR Type:</Typography>
		<RadioGroup
			data-testid='select-auth-type'
			onChange={(e, v) => {
				onSelect(Number(v));
			}}
		>
			<FormControlLabel
				value={AuthTypes.NewCondition}
				control={<Radio />}
				label={AuthTypeLabels.NewCondition}
			/>
			<FormControlLabel
				value={AuthTypes.ContinuingCare}
				control={<Radio />}
				label={AuthTypeLabels.ContinuingCare}
			/>
		</RadioGroup>
	</Box>
);

SelectAuthType.propTypes = {
	onSelect: PropTypes.func.isRequired,
};

export default SelectAuthType;
