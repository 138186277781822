import Typography from '@mui/material/Typography';
import ClinicalFileType from '../../scripts/clinicalFileType';

import ResourceTablePage from './shared/ResourceTablePage';

const clinicalFileType =
	ClinicalFileType.ashlinkClinicalProviderServicesAgreementResource.value;

const PractitionerServicesAgreements = () => {
	const pageTitle = 'Practitioner Services Agreements';
	const resourceColumnTitle = 'Practitioner Services Agreement';
	const pageSummary = (
		<Typography mb={2}>
			The ASH Practitioner Services Agreement goes into detail about
			Practitioner obligations agreed to for participation in our health
			care network. The table of contents gives a quick overview of all
			sections of the Practitioner Services Agreement. Other portions of
			the Practitioner Services Agreement including the Practitioner
			Operations Manual, Client Summaries and reimbursement Fee Schedule
			Amounts are also posted in the Resources section.
		</Typography>
	);

	return (
		<ResourceTablePage
			pageTitle={pageTitle}
			pageSummary={pageSummary}
			resourceColumnTitle={resourceColumnTitle}
			clinicalFileType={clinicalFileType}
			disableReleaseDateColumn
		/>
	);
};

export default PractitionerServicesAgreements;
