import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useQuery } from 'react-query';
import { useState } from 'react';
import useSessionContext from '../../data/queryHooks/useSessionContext';
import { GetValueAddedVendorsBySpecialtyFn } from '../../data/apiCalls';
import ValueAddedProgramList from './ValueAddedProgramList';
import ValueAddedProgramDetails from './ValueAddedProgramDetails';

const ValueAddedProgram = () => {
	const { drFedId } = useSessionContext();

	const [selectedVendor, setSelectedVendor] = useState(undefined);

	const { data: valueAddedVendorsData, isLoading } = useQuery(
		['ValueAddedProgram'],
		() => GetValueAddedVendorsBySpecialtyFn(drFedId),
		{ staleTime: Infinity }
	);
	const { data: { data: { entries = [] } = {} } = {} } =
		valueAddedVendorsData ?? {};
	const onVendorClick = vendorId => {
		const selected = entries.find(entry => entry.id === vendorId);
		setSelectedVendor(selected);
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};
	const onBackButtonClick = () => setSelectedVendor(undefined);

	return (
		<Paper elevation={0}>
			<Container sx={{ py: 2 }}>
				<Typography variant='h5' component='h1' gutterBottom>
					Value Added Program
				</Typography>
				<Divider sx={{ mb: 2 }} />
				{!selectedVendor && (
					<ValueAddedProgramList
						valueAddedVendors={valueAddedVendorsData}
						valueAddedVendorsIsLoading={isLoading}
						onVendorClick={onVendorClick}
					/>
				)}
				{selectedVendor && (
					<ValueAddedProgramDetails
						valueAddedVendor={selectedVendor}
						onBackButtonClick={onBackButtonClick}
					/>
				)}
			</Container>
		</Paper>
	);
};

export default ValueAddedProgram;
