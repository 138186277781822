import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import useSessionContext from '../../../data/queryHooks/useSessionContext';
import { GetClinicalGeneralResourcesFn } from '../../../data/apiCalls';

const TierLevel = ({ tierLevel, specialtyDescription }) => {
	const shouldShowTier = ['PT/OT', 'Chiropractic', 'Acupuncture'];
	const { drFedId } = useSessionContext();
	const { data } = useQuery(
		['ClinicalGeneralResources', drFedId],
		() => GetClinicalGeneralResourcesFn(drFedId, 1),
		{ staleTime: Infinity }
	);

	const url = data?.data?.data?.result[0]?.url || '';

	return (
		<div>
			{specialtyDescription &&
			shouldShowTier.includes(specialtyDescription) ? (
				<Typography variant='subtitle2'>
					{`${specialtyDescription}`}
					{` - `}
					<Link
						variant='subtitle2'
						href={url}
						target='_blank'
						rel='noreferrer'
					>
						Tier Level: {tierLevel}
					</Link>
				</Typography>
			) : (
				<Typography variant='subtitle2'>
					{`${specialtyDescription}`}
				</Typography>
			)}
		</div>
	);
};

TierLevel.propTypes = {
	specialtyDescription: PropTypes.string.isRequired,
	tierLevel: PropTypes.number.isRequired,
};

export default TierLevel;
