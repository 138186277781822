import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import { useQuery } from 'react-query';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { GetClinicalPELResourcesFn } from '../../../data/apiCalls';
import useSessionContext from '../../../data/queryHooks/useSessionContext';
import CustomNoResultsOverlay from '../../shared/CustomNoResultsOverlay';
import CustomLoading from '../../shared/CustomLoading';
import EducationPagingTable from './EducationPagingTable';

const EducationLibrary = ({ pageTitle, pageSummary, siteSections }) => {
	const { drFedId, practitionerId } = useSessionContext();

	const response = useQuery(
		['ClinicalPELResources', drFedId],
		() => GetClinicalPELResourcesFn(drFedId, practitionerId, 1, 100),
		{ staleTime: Infinity }
	);

	const {
		data: {
			status = undefined,
			data: {
				resultCode = undefined,
				subresultCode = undefined,
				data: { result: clinicalPELResources = [] } = {},
			} = {},
		} = {},
		isLoading,
		isError,
	} = response;

	const [expanded, setExpanded] = useState(false);
	const handleChange = (isExpanded, panel) => {
		setExpanded(isExpanded ? panel : false);
	};

	const resourcesGroupedBySiteSection = {};
	clinicalPELResources.forEach(resource => {
		const { siteSection } = resource;
		if (!resourcesGroupedBySiteSection[siteSection]) {
			resourcesGroupedBySiteSection[siteSection] = [resource];
		} else {
			resourcesGroupedBySiteSection[siteSection].push(resource);
		}
	});

	const showNoResultsOverlay = isError || (status >= 400 && status <= 505);

	return (
		<Paper elevation={0} role='main'>
			<Container sx={{ py: 2, px: { md: 4, lg: 4 } }}>
				<Typography variant='h5' component='h1' mb={2}>
					{pageTitle}
				</Typography>
				<Divider sx={{ mb: 2 }} />
				<Typography fontWeight='fontWeightBold' mb={2}>
					{pageSummary}
				</Typography>
				<Box sx={{ mb: 7, mt: 4 }}>
					{isLoading && <CustomLoading />}
					{showNoResultsOverlay && (
						<CustomNoResultsOverlay
							resultCode={resultCode}
							statusCode={status}
						/>
					)}
					{!isLoading &&
						!showNoResultsOverlay &&
						siteSections.map(siteSection => (
							<Accordion
								key={siteSection.key}
								id={siteSection.key}
								expanded={expanded === siteSection.key}
								onChange={(e, isExpanded) =>
									handleChange(isExpanded, siteSection.key)
								}
								sx={{ mt: 2 }}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls={`${siteSection.key}-panel-content`}
								>
									<Stack>
										<Typography sx={{ fontWeight: 'bold' }}>
											{siteSection.label}
										</Typography>
										<Typography>
											{siteSection.summary}
										</Typography>
									</Stack>
								</AccordionSummary>
								<AccordionDetails>
									<EducationPagingTable
										resourceColumnTitle={siteSection.label}
										enableVideoSeriesColumns={
											siteSection.key === 'video-series'
										}
										resources={
											resourcesGroupedBySiteSection[
												siteSection.contentfulLabel
											] || []
										}
										resultCode={resultCode}
										statusCode={status}
										subresultCode={subresultCode}
									/>
								</AccordionDetails>
							</Accordion>
						))}
				</Box>
			</Container>
		</Paper>
	);
};

EducationLibrary.propTypes = {
	pageTitle: PropTypes.string.isRequired,
	pageSummary: PropTypes.string.isRequired,
	siteSections: PropTypes.array.isRequired,
};

export default EducationLibrary;
