import EducationLibrary from './shared/EducationLibrary';

const PractitionerEducationLibrary = () => {
	const siteSections = [
		{
			label: 'About ASH',
			contentfulLabel: 'About ASH',
			key: 'about-ash',
			summary:
				'The About ASH section presents an overview of the ASH organization, committees that affect our practitioner networks, and other general information valuable to practitioners.',
		},
		{
			label: 'Administrative Topics',
			contentfulLabel: 'Admin Topics',
			key: 'admin-topics',
			summary:
				"The Administrative Topics section provides details to ASH's administrative procedures with additional explanation of relevant topics.",
		},
		{
			label: 'Clinical Topics',
			contentfulLabel: 'Clinical Topics',
			key: 'clinical-topics',
			summary:
				'The Clinical Topics section includes links to guidelines and other relevant resource.',
		},
		{
			label: 'Evidence Evaluation Committee (EEC)',
			contentfulLabel: 'Evidence Evaluation Committee',
			key: 'eec',
			summary:
				'The EEC Section includes the clinical performance assessment process that evaluates diagnostic and therapeutic procedures, applicable to the specialty services represented by ASH against professionally recognized standards of practice, current scientific evidence, and consensus of appropriate experts.',
		},
		{
			label: 'Patient Education',
			contentfulLabel: 'Patient Education',
			key: 'patient-education',
			summary:
				'The Patient Education section provides evidence based handouts for ASH network practitioners to distribute to members.',
		},
		{
			label: 'Policies',
			contentfulLabel: 'Policies',
			key: 'policies',
			summary:
				"The Policies section presents ASH's clinical and administrative policies relevant to your practice and interactions with ASH.",
		},
		{
			label: 'Professional Affairs Healthcare Advisory Committee (PAHAC)',
			contentfulLabel:
				'Professional Affairs Healthcare Advisory Committee',
			key: 'pahac',
			summary:
				'The PAHAC section includes meeting agendas, minutes, and other pertinent information addressed by the committee.',
		},
		{
			label: 'Video Series',
			contentfulLabel: 'Video Series',
			key: 'video-series',
			summary:
				"The Video Series section provides audio/visual details of ASH's administrative procedures and other relevant topics.",
		},
	];
	const pageTitle = 'Practitioner Education Library';
	const pageSummary =
		'The Practitioner Education Library consists of a variety of administrative and clinical topics as well as other selected materials.';

	return (
		<EducationLibrary
			pageTitle={pageTitle}
			pageSummary={pageSummary}
			siteSections={siteSections}
		/>
	);
};

export default PractitionerEducationLibrary;
