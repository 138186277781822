import Specialty from './specialty';

export const GetEvaluationCodeList = specialtyCode => {
	switch (specialtyCode) {
		case Specialty.HPPTProvider:
			return ['97161', '97162', '97163'];
		case Specialty.HPOTProvider:
			return ['97165', '97166', '97167'];
		case Specialty.MedicalDoctor:
			return [
				'92521',
				'92522',
				'92523',
				'97161',
				'97162',
				'97163',
				'97165',
				'97166',
				'97167',
				'97169',
				'97170',
				'97171',
			];
		case Specialty.PTPractitioner:
			return ['97161', '97162', '97163'];
		case Specialty.OTPractitioner:
			return ['97165', '97166', '97167'];
		case Specialty.SLPPractitioner:
			return ['92521', '92522', '92523'];
		case Specialty.AthleticTrainer:
			return ['97169', '97170', '97171'];
		case Specialty.CignaPhysicalMedicine:
			return [
				'92521',
				'92522',
				'92523',
				'97161',
				'97162',
				'97163',
				'97165',
				'97166',
				'97167',
				'97169',
				'97170',
				'97171',
			];
		default:
			return [];
	}
};
export const GetReEvaluationCodeList = specialtyCode => {
	switch (specialtyCode) {
		case Specialty.HPPTProvider:
			return ['97164'];
		case Specialty.HPOTProvider:
			return ['97168'];
		case Specialty.MedicalDoctor:
			return ['97164', '97168', '97172'];
		case Specialty.PTPractitioner:
			return ['97164'];
		case Specialty.OTPractitioner:
			return ['97168'];
		case Specialty.SLPPractitioner:
			return ['N/A'];
		case Specialty.AthleticTrainer:
			return ['97172'];
		case Specialty.CignaPhysicalMedicine:
			return ['97164', '97168', '97172'];
		default:
			return [];
	}
};
