import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useQuery } from 'react-query';
import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getPractitioners } from '../../../../../../../data/promisApiCalls';
import useIsMobile from '../../../../../../../hooks/useIsMobile';
import { AuthSubmissionContext } from '../../AuthSubmissionContext';
import CustomLoading from '../../../../../../shared/CustomLoading';

const SelectPractitioner = ({ onSelect }) => {
	const isMobile = useIsMobile();
	const { setError, sessionProviderId, sessionPractitionerId } = useContext(
		AuthSubmissionContext
	);

	const { isLoading, isError, data } = useQuery(
		['getPractitioners'],
		async () => getPractitioners(sessionProviderId, sessionPractitionerId),
		{ staleTime: Infinity }
	);

	const { data: { resultCode, data: { practitioners = [] } = {} } = {} } =
		data ?? {};

	useEffect(() => {
		if (isError || (data && resultCode !== 0)) {
			setError('There was a problem getting practitioners');
		}
	}, [isError, data]);

	useEffect(() => {
		// If there is only one practitioner, then automatically select it
		if (practitioners.length === 1) {
			onSelect(practitioners[0]);
		}
	}, [practitioners]);

	if (isLoading) {
		return <CustomLoading />;
	}
	return (
		<>
			<Typography variant='h5' color='primary' component='h1'>
				Practitioner
			</Typography>
			<Autocomplete
				data-testid='select-practitioner'
				disabled={isError}
				disableClearable
				defaultValue={
					// If there is only one practitioner, then automatically show it
					practitioners?.length === 1 ? practitioners[0] : null
				}
				options={
					practitioners?.sort((a, b) =>
						a.lastName.localeCompare(b.lastName)
					) ?? []
				}
				onChange={(e, v) => {
					onSelect(v);
				}}
				getOptionLabel={o =>
					`${o.lastName}, ${o.firstName} - ${o.npiTypeI}${
						o.plTermDate ? ` (Termed ${o.plTermDate})` : ''
					}`
				}
				sx={{ m: 1, width: isMobile ? 'auto' : 400 }}
				renderInput={params => (
					<TextField
						{...params}
						label='Select the Practitioner'
						fullWidth
						helperText='Enter Practitioner First Name, Last Name or NPI 1'
					/>
				)}
				componentsProps={{
					popper: { style: { width: 'fit-content' } },
				}}
			/>
		</>
	);
};
SelectPractitioner.propTypes = {
	onSelect: PropTypes.func.isRequired,
};

export default SelectPractitioner;
