import { useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import SelectAuthType from './shared/SelectAuthType';
import SelectServiceLocation from './shared/SelectServiceLocation';
import ShowSpecialty from './shared/ShowSpecialty';
import SelectSubmissionType from './shared/SelectSubmissionType';
import SelectAuthForm from './shared/SelectAuthForm';
import SelectSubmitHealthStatus from './shared/SelectSubmitHealthStatus';
import { AuthSubmissionContext } from '../AuthSubmissionContext';
import {
	AuthTypes,
	GetAuthTherapyType,
	LegacyRehabAuthFormTypes,
} from '../../../../../../scripts/authFormHelpers';
import useSessionContext from '../../../../../../data/queryHooks/useSessionContext';

const SelectAuthFormWithoutPractitioner = ({ onContinueClick }) => {
	const { providerSpecialtyCode } = useSessionContext();
	const [submissionType, setSubmissionType] = useState();
	const [authType, setAuthType] = useState();
	const [authFormType, setAuthFormType] = useState();
	const [submitHealthStatusForm, setSubmitHealthStatusForm] = useState();
	const [serviceLocation, setServiceLocation] = useState();

	const {
		sessionProviderId,
		sessionProviderSpecialtyCode,
		sessionPractitionerSpecialtyCode,
		sessionProviderFirstName,
		sessionProviderLastName,
	} = useContext(AuthSubmissionContext);

	const isValid = () =>
		((submissionType === 'ASH Electronic MNR Form' &&
			authFormType &&
			submitHealthStatusForm) ||
			submissionType === 'Medical Records') &&
		authType &&
		submitHealthStatusForm &&
		serviceLocation;

	const handleContinueClick = () => {
		const authFormTypeValue =
			submissionType === 'Medical Records'
				? LegacyRehabAuthFormTypes.MedicalRecords
				: authFormType;
		const authTherapyType = GetAuthTherapyType(
			authFormTypeValue,
			sessionPractitionerSpecialtyCode,
			providerSpecialtyCode
		);
		onContinueClick({
			practitioner: {
				specialtyCode: sessionPractitionerSpecialtyCode,
			},
			practitionerNameNpi: `${sessionProviderFirstName} ${sessionProviderLastName}`,
			submissionType,
			authType,
			authFormType: authFormTypeValue,
			authTherapyType,
			submitHealthStatusForm,
			serviceLocation,
		});
	};

	return (
		<Paper elevation={1} variant='wrapper'>
			<Stack gap={2}>
				<>
					<ShowSpecialty
						specialtyCode={sessionProviderSpecialtyCode}
					/>
					<SelectSubmissionType
						onSelect={p => {
							setSubmissionType(p);
							setAuthFormType(null);
						}}
					/>
				</>

				{submissionType && (
					<>
						<Typography variant='h5' component='h1' color='primary'>
							E-MNR
						</Typography>
						<SelectAuthType
							onSelect={p => {
								setAuthType(p);
								setAuthFormType(null);
							}}
						/>
					</>
				)}
				{authType && (
					<>
						<SelectAuthForm
							value={authFormType}
							isNewCare={authType === AuthTypes.NewCondition}
							isMedicalRecords={
								submissionType === 'Medical Records'
							}
							onSelect={p => setAuthFormType(p)}
						/>
						<SelectSubmitHealthStatus
							onSelect={p => setSubmitHealthStatusForm(p)}
						/>
					</>
				)}
				{authType && submissionType && (
					<SelectServiceLocation
						docId={sessionProviderId}
						onSelect={p => setServiceLocation(p)}
					/>
				)}
				<Stack direction='row' justifyContent='center'>
					<Button
						disabled={!isValid()}
						onClick={handleContinueClick}
						variant='contained'
						sx={{ marginTop: 2 }}
					>
						Continue
					</Button>
				</Stack>
			</Stack>
		</Paper>
	);
};

SelectAuthFormWithoutPractitioner.propTypes = {
	onContinueClick: PropTypes.func.isRequired,
};

export default SelectAuthFormWithoutPractitioner;
