import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import Specialty from '../../../../../../scripts/specialty';
import { AuthSubmissionContext } from '../AuthSubmissionContext';
import SelectAuthFormAndPractitioner from './SelectAuthFormAndPractitioner';
import SelectAuthFormWithoutPractitioner from './SelectAuthFormWithoutPractitioner';
import CustomLoading from '../../../../../shared/CustomLoading';

const SelectAuth = ({ onContinueClick }) => {
	const { sessionProviderSpecialtyCode } = useContext(AuthSubmissionContext);
	switch (sessionProviderSpecialtyCode) {
		case Specialty.RehabProvider:
			return (
				<SelectAuthFormAndPractitioner
					onContinueClick={onContinueClick}
				/>
			);
		case Specialty.HPPTProvider:
		case Specialty.HPOTProvider:
		case Specialty.MedicalDoctor:
		case Specialty.CignaPhysicalMedicine:
			return (
				<SelectAuthFormWithoutPractitioner
					onContinueClick={onContinueClick}
				/>
			);
		case undefined:
			return <CustomLoading />;
		default:
			return <Box>This Specialty does not have an E-MNR form</Box>;
	}
};

SelectAuth.propTypes = {
	onContinueClick: PropTypes.func.isRequired,
};

export default SelectAuth;
