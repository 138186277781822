import { rest } from 'msw';

export const pages = {
	fail: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 32,
		subResultText: 'ContentManagementApiFail',
		errors: [
			{
				message:
					'An error occured while getting the ClinicalGeneralResources.',
			},
		],
		date: '2023-11-10T08:37:17.4001843-08:00',
	},
	result: {
		resultCode: 0,
		resultText: 'Success',
		data: {
			result: [
				{
					id: '76w7S6yOgBOSgTNEUwvTVf',
					displayName: 'CPS 2',
					url: '//assets.ctfassets.net/1g6by23er3py/2BP7nLP5i4dyePRQUWovJs/f42e368bd4828ae6d803ad39a9272fd1/M965-225A_20AF_20Language_20Support_tcm39-178694.pdf',
				},
				{
					id: '2tHJrnLwMI9fPpJWA6XH0Q',
					displayName: 'CPS',
					url: '//assets.ctfassets.net/1g6by23er3py/2BP7nLP5i4dyePRQUWovJs/f42e368bd4828ae6d803ad39a9272fd1/M965-225A_20AF_20Language_20Support_tcm39-178694.pdf',
				},
			],
		},
		date: '2023-11-10T16:32:01.1259012Z',
	},
	notFound: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 9001,
		subResultText: 'ContentNotFound',
		errors: [
			{
				message: 'No content returned from Contentful.',
			},
		],
		date: '2023-02-22T05:36:28.3714538Z',
	},
};

export const ClinicalGeneralResourcesSuccess = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalGeneralResources',
		(req, res, ctx) => res(ctx.status(200), ctx.json(pages.result))
	),
];

export const ClinicalGeneralResourcesLoading = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalGeneralResources',
		(req, res, ctx) => res(ctx.delay('infinite'))
	),
];

export const ClinicalGeneralResourcesFailWithSubResultCode32 = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalGeneralResources',
		(req, res, ctx) => res(ctx.status(200), ctx.json(pages.fail))
	),
];

export const ClinicalGeneralResourcesNoResults = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalGeneralResources',
		(req, res, ctx) => res(ctx.status(200), ctx.json(pages.notFound))
	),
];

export const ClinicalGeneralResourcesFail = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalGeneralResources',
		(req, res, ctx) => res(ctx.status(500), ctx.json(pages.fail))
	),
];

export const clinicalGeneralResources = [
	...ClinicalGeneralResourcesSuccess,
	...ClinicalGeneralResourcesLoading,
	...ClinicalGeneralResourcesNoResults,
	...ClinicalGeneralResourcesFail,
	...ClinicalGeneralResourcesFailWithSubResultCode32,
];
