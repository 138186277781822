// Settings used by the mocks to obtain different scenarios by Storybook and tests
const mockSettingsDefaults = {
	returnError: false,
	returnMessage: '',
	totalCount: 100,
	networkDelay: 500,
};
const mockSettings = { ...mockSettingsDefaults };

/**
 * Updates mock settings by first restoring the defaults and then applying only the
 * requested changes.
 * @param {Object} settings The settings to apply to the mock settings
 * @return {Null}
 */
export function updateMockSettings(settings) {
	Object.keys(mockSettingsDefaults).forEach(key => {
		mockSettings[key] =
			settings[key] === undefined
				? mockSettingsDefaults[key]
				: settings[key];
	});
}

/**
 * Array of mock handlers that service GraphQL requests from this project.
 */
export const handlers = [];
