import EveThemeProvider from '@ashn/eve-themes-react/EveThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

async function main() {
	if (process.env.NODE_ENV === 'development') {
		if (window.location.pathname === '/clinical') {
			window.location.pathname = '/clinical/';
			return;
		}
		// eslint-disable-next-line global-require
		const { worker } = require('./mocks/browser');
		await worker.start({
			serviceWorker: {
				url: '/clinical/mockServiceWorker.js',
			},
		});
	}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter basename='/clinical'>
		<QueryClientProvider client={new QueryClient()}>
			<React.StrictMode>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<EveThemeProvider themeKey='internal' themeMode='light'>
						<CssBaseline />
						<App />
					</EveThemeProvider>
				</LocalizationProvider>
			</React.StrictMode>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

main();
