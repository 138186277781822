import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import dayjs from 'dayjs';
import { GetClinicalResourcesFn } from '../../../data/apiCalls';
import CustomNoResultsOverlay from '../../shared/CustomNoResultsOverlay';
import CustomLoading from '../../shared/CustomLoading';
import useSessionContext from '../../../data/queryHooks/useSessionContext';

const ResourceTablePage = ({
	pageTitle,
	pageSummary,
	resourceColumnTitle,
	clinicalFileType,
	disableEffectiveDateColumn,
	disableReleaseDateColumn,
	showAgreementTypeColumn,
	hideSearch,
	autoRowHeight,
}) => {
	const { drFedId, practitionerId } = useSessionContext();

	const resources = useQuery(
		[clinicalFileType],
		() =>
			GetClinicalResourcesFn(
				drFedId,
				practitionerId,
				clinicalFileType,
				1,
				1000
			),
		{ staleTime: Infinity }
	);

	const [pageSize, setPageSize] = useState(10);

	const {
		data: {
			status,
			data: {
				resultCode,
				subResultCode,
				data: { result: { entries = [] } = {} } = {},
			} = {},
		} = {},
	} = resources;

	const noResultsOrRowsConfig = {
		resultCode,
		subResultCode,
		statusCode: status,
	};

	const columns = [
		{
			field: 'topic',
			sortable: false,
			flex: 1,
			minWidth: 150,
			hideable: false,
			renderHeader: () => (
				<Typography variant='body2' fontWeight='fontWeightBold'>
					{resourceColumnTitle}
				</Typography>
			),
			renderCell: params => (
				<Link
					href={params.row.url}
					sx={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
					target='_blank'
				>
					{params.row.displayName}
				</Link>
			),
			valueGetter: params => params.row.displayName,
			sortComparator: (a, b) =>
				a.localeCompare(b, 'en', { numeric: true }),
		},
	];
	if (!disableEffectiveDateColumn) {
		columns.push({
			field: 'effectiveDate',
			sortable: false,
			flex: 0.3,
			minWidth: 130,
			renderHeader: () => (
				<Typography variant='body2' fontWeight='fontWeightBold'>
					Effective Date
				</Typography>
			),
			valueFormatter: params =>
				dayjs(params.value.effectiveDate.concat('T00:00:00')).format(
					'MMMM YYYY'
				),
			hideable: false,
			valueGetter: params => params.row,
			sortComparator: (a, b) => {
				if (a.effectiveDate > b.effectiveDate) return -1;
				if (a.effectiveDate < b.effectiveDate) return 1;
				return a.displayName.localeCompare(b.displayName, 'en', {
					numeric: true,
				});
			},
		});
	}
	if (!disableReleaseDateColumn) {
		columns.push({
			field: 'releaseDate',
			sortable: false,
			flex: 0.3,
			minWidth: 130,
			renderHeader: () => (
				<Typography variant='body2' fontWeight='fontWeightBold'>
					Release Date
				</Typography>
			),
			valueFormatter: params =>
				dayjs(params.value.concat('T00:00:00')).format('MMMM YYYY'),
			hideable: false,
		});
	}
	if (showAgreementTypeColumn) {
		columns.push({
			field: 'agreementType',
			sortable: false,
			flex: 0.3,
			minWidth: 130,
			renderHeader: () => (
				<Typography variant='body2' fontWeight='fontWeightBold'>
					Agreement Type
				</Typography>
			),
			hideable: false,
		});
	}

	const VISIBLE_FIELDS = [
		'topic',
		'effectiveDate',
		'releaseDate',
		'agreementType',
	];
	const getApplyFilterTopic = value => params =>
		params.row.displayName.toLowerCase().includes(value.toLowerCase());

	const searchColumns = React.useMemo(
		() =>
			columns
				.filter(column => VISIBLE_FIELDS.includes(column.field))
				.map(column => {
					if (column.field === 'topic') {
						return {
							...column,
							getApplyQuickFilterFn: getApplyFilterTopic,
						};
					}
					return {
						...column,
						getApplyQuickFilterFn: undefined,
					};
				}),
		[columns]
	);

	return (
		<Paper elevation={0}>
			<Container sx={{ py: 2 }}>
				<Typography variant='h5' component='h1' mb={2}>
					{pageTitle}
				</Typography>
				<Divider sx={{ mb: 2 }} />
				{pageSummary}

				<Box
					sx={{
						mt: 6,
					}}
				>
					<Paper elevation={1}>
						<DataGrid
							columns={searchColumns}
							disableColumnMenu
							disableColumnFilter
							disableColumnSelector
							disableSelectionOnClick
							disableDensitySelector
							rows={entries}
							rowsPerPageOptions={[10, 20, 50]}
							pageSize={pageSize}
							onPageSizeChange={setPageSize}
							loading={resources.isLoading}
							autoHeight
							getRowHeight={() => (autoRowHeight ? 'auto' : 52)}
							initialState={{
								sorting: {
									sortModel: [
										{
											field: disableEffectiveDateColumn
												? 'topic'
												: 'effectiveDate',
										},
									],
								},
							}}
							components={{
								NoRowsOverlay: CustomNoResultsOverlay,
								NoResultsOverlay: CustomNoResultsOverlay,
								LoadingOverlay: CustomLoading,
								Toolbar: GridToolbar,
							}}
							componentsProps={{
								noRowsOverlay: {
									...noResultsOrRowsConfig,
								},
								noResultsOverlay: {
									...noResultsOrRowsConfig,
									customNoResultsVerbiage:
										'No results found. Please check your search and try again.',
								},
								toolbar: {
									showQuickFilter:
										entries.length > 0 && !hideSearch,
									printOptions: {
										disableToolbarButton: true,
									},
									csvOptions: { disableToolbarButton: true },
									quickFilterProps: { debounceMs: 0 },
								},
							}}
							sx={
								autoRowHeight
									? {
											'.MuiDataGrid-cell': {
												py: '15px',
											},
									  }
									: {}
							}
						/>
					</Paper>
				</Box>
			</Container>
		</Paper>
	);
};

ResourceTablePage.propTypes = {
	pageTitle: PropTypes.string.isRequired,
	pageSummary: PropTypes.node.isRequired,
	resourceColumnTitle: PropTypes.string.isRequired,
	clinicalFileType: PropTypes.number.isRequired,
	disableEffectiveDateColumn: PropTypes.bool,
	disableReleaseDateColumn: PropTypes.bool,
	hideSearch: PropTypes.bool,
	showAgreementTypeColumn: PropTypes.bool,
	autoRowHeight: PropTypes.bool,
};

ResourceTablePage.defaultProps = {
	disableEffectiveDateColumn: false,
	disableReleaseDateColumn: false,
	hideSearch: false,
	showAgreementTypeColumn: false,
	autoRowHeight: false,
};

export default ResourceTablePage;
