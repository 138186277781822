import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import MedicalRecordsTreatmentServicesReview from './TreatmentServicesReview/MedicalRecordsTreatmentServicesReview';

const MedicalRecordsReview = ({ onEditClick, onSubmitClick }) => (
	<Paper elevation={0} sx={{ p: 2 }}>
		<Stack>
			<MedicalRecordsTreatmentServicesReview />
			<Stack direction='row' spacing={2} justifyContent='center'>
				<Button
					variant='outlined'
					color='secondary'
					size='large'
					onClick={onEditClick}
				>
					Edit
				</Button>
				<Button
					variant='contained'
					size='large'
					onClick={onSubmitClick}
				>
					Submit
				</Button>
			</Stack>
		</Stack>
	</Paper>
);

MedicalRecordsReview.propTypes = {
	onEditClick: PropTypes.func.isRequired,
	onSubmitClick: PropTypes.func.isRequired,
};

export default MedicalRecordsReview;
