import { rest } from 'msw';

export const sessionDataSuccess = [
	rest.post('/clinical/api/legacy/sessiondata', (req, res, ctx) =>
		res(
			ctx.delay(2000),
			ctx.status(200),
			ctx.json({
				resultCode: 0,
				resultText: 'Success',
				data: {
					name: 'MockedNameValue',
				},
				date: '2024-05-09T18:23:56.6450298Z',
			})
		)
	),
	rest.get('/clinical/api/legacy/sessiondata', (req, res, ctx) => {
		if (req.url.searchParams.get('name') === 'SharedPatient') {
			return res(
				ctx.status(200),
				ctx.json({
					resultCode: 0,
					resultText: 'Success',
					data: {
						name: req.url.searchParams.get('name'),
						jsonData:
							'{"MemFirst": "Tom","MemLast": "Cruise","DOB": "01-01-1990","MemId": 123456789,"Suffix": "00", "Seq": 1,"EnrollSeq": 1, "Gender": "F","Address": "123 Main St.", "City": "San Diego", "State": "CA", "Zip": "92122", "Phone": "555-555-5555","PlanId": 1234,"HealthPlan": "Mock Health Plan","ContractId": "Test1234","ContractSuffix": "00","GroupNo": "TestGroup1234","AccountMasterId": 12345678,"AccountId": 12345678}',
					},
					date: '2024-05-09T18:23:56.6450298Z',
				})
			);
		}
		return res(
			ctx.status(200),
			ctx.json({
				resultCode: 0,
				resultText: 'Success',
				data: {
					name: req.url.searchParams.get('name'),
				},
				date: '2024-05-09T18:23:56.6450298Z',
			})
		);
	}),
];
