export const TOP_LEVEL_ROUTES = {
	public: 'public',
	resources: 'resources',
	compliance: 'compliance',
	help: 'help',
	account: 'account',
};

export const CLINICAL_PAGE_ROUTES = {
	pageNotFound: 'pagenotfound',
	// Resources
	clientSummaries: 'clientsummaries',
	notifications: 'notifications',
	providerServiceAgreements: 'providerservicesagreements',
	valueAddedProgram: 'valueAddedProgram',
	operationsManual: 'operationsmanual',
	newsletters: 'newsletters',
	providerEducationLibrary: 'providerEducationLibrary',
	practitionerServiceAgreements: 'practitionerservicesagreements',
	practitionerEducationLibrary: 'practitionerEducationLibrary',
	providerPractitionerVirtualServicesAgreement:
		'providerPractitionerVirtualServicesAgreement',
	forms: 'forms',
	// Help
	faq: 'faq',
	// Account
	editSubAccounts: 'editSubAccounts',
};

export const PAGE_PATHS = {
	pageNotFound: `/${TOP_LEVEL_ROUTES.public}/${CLINICAL_PAGE_ROUTES.pageNotFound}`,
};
