import Typography from '@mui/material/Typography';

import ClinicalFileType from '../../scripts/clinicalFileType';
import ResourceTablePage from './shared/ResourceTablePage';

const clinicalFileType =
	ClinicalFileType.ashlinkClinicalNewsletterResource.value;

const Newsletters = () => {
	const pageTitle = 'Newsletters';
	const pageSummary = (
		<Typography fontWeight='fontWeightBold' mb={2}>
			{`The "insideASH" newsletter provides updates and helpful information
	about ASH developments, training, education opportunities, guidelines
	and policy updates to your specialty.`}
		</Typography>
	);
	const resourceColumnTitle = 'Newsletter Issue';

	return (
		<ResourceTablePage
			pageTitle={pageTitle}
			resourceColumnTitle={resourceColumnTitle}
			pageSummary={pageSummary}
			clinicalFileType={clinicalFileType}
			disableEffectiveDateColumn
			disableReleaseDateColumn
		/>
	);
};

export default Newsletters;
