import PageLayout from '../shared/PageLayout/PageLayout';
import useSessionContext from '../../data/queryHooks/useSessionContext';
import { getHelpNavs } from '../../scripts/navigationHelpers';

const Help = () => {
	const { permissions, redirectionLinks } = useSessionContext();

	return (
		<PageLayout
			pageTitle='Help'
			navItems={getHelpNavs(permissions, redirectionLinks)}
		/>
	);
};

export default Help;
