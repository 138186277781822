import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { AuthSubmissionContext } from '../AuthSubmissionContext';
import MedicalRecordsReview from './MedicalRecordsReview/MedicalRecordsReview';
import { LegacyRehabAuthFormTypes } from '../../../../../../scripts/authFormHelpers';

const ReviewAuth = ({ onEditClick, onSubmitClick }) => {
	const {
		selectionData: { authFormType },
	} = useContext(AuthSubmissionContext);
	switch (authFormType) {
		case LegacyRehabAuthFormTypes.MedicalRecords:
			return (
				<MedicalRecordsReview
					onEditClick={onEditClick}
					onSubmitClick={onSubmitClick}
				/>
			);
		default:
			return <Typography> This form is not supported</Typography>;
	}
};

ReviewAuth.propTypes = {
	onEditClick: PropTypes.func.isRequired,
	onSubmitClick: PropTypes.func.isRequired,
};

export default ReviewAuth;
