import { CLINICAL_PAGE_ROUTES, TOP_LEVEL_ROUTES } from './routes';

export const pageKey = {
	clientSummary: 'client-summary',
	operationsManual: 'operations-manual',
	notification: 'notification',
	providerServiceAgreement: 'provider-service-agreement',
	practitionerServiceAgreement: 'practitioner-service-agreement',
	valueAddedProgram: 'value-added-program',
	newsletter: 'newsletter',
	frequentlyAskedQuestion: 'frequently-asked-question',
	CPSAnnualReview: 'cps-annual-review',
	resourcesForm: 'resources-form',
	home: 'home',
	search: 'search',
	memberReferral: 'member-referral',
	batchClaimsUpload: 'batch-claim-upload',
	batchClaimsSummary: 'batch-claim-summary',
	ASHClearinghouse: 'ash-clearinghouse',
	compliance: 'compliance',
	ASHLinkWebsiteTip: 'ashlink-website-tip',
	contactUs: 'contact-us',
	submitAnOnlineQuestionOrComment: 'submit-question-or-comment',
	providerEducationLibrary: 'provider-education-library',
	practitionerEducationLibrary: 'practitioner-education-library',
	accountManagement: 'account-management',
	messageCenter: 'message-center',
	transactionSummary: 'transaction-summary',
	favoriteCPTCode: 'favorite-cpt-code',
	confirmUpdateInformation: 'confirm-update-information',
	privacyStatement: 'privacy-statement',
	termsAndCondition: 'terms-and-condition',
	newPatientSearch: 'new-patient-search',
	existingPatientSearch: 'existing-patient-search',
	paymentsSearch: 'payment-search',
	claimsSearch: 'claim-search',
	MNRFormSearch: 'mnr-search',
	reopenModificationsSearch: 'reopen-modifications-search',
	remittanceAdviceSearch: 'remittance-advice-search',
	ashcare: 'ashcare',
	providerPractitionerVirtualServicesAgreement:
		'provider-practitioner-virtual-services-agreement',
	manageSubAccounts: 'manage-sub-accounts',
	favoriteCptCodes: 'favorite-cpt-codes',
	updateInfo: 'update-info',
};

export const permissionName = {
	general: 'General',
	existingPatient: 'ExistingPatient',
	batchClaim: 'BatchClaim',
	memberReferral: 'MemberReferral',
	clientSummary: 'ClientSummary',
	valueAddedProgram: 'ValueAddedProgram',
	favoriteCPTCode: 'FavoriteCPTCode',
	activitySummary: 'ActivitySummary',
	operationManual: 'OperationManual',
	form: 'Form',
	CPSLetter: 'CPSLetter',
	notification: 'Notification',
	newsletter: 'Newsletter',
	clearinghouse: 'Clearinghouse',
	compliance: 'Compliance',
	messageCenter: 'MessageCenter',
	generalUserInformation: 'GeneralUserInformation',
	newPatient: 'NewPatient',
	MNR: 'MNR',
	claim: 'Claim',
	payment: 'Payment',
	NPI: 'NPI',
	providerEducationLibraryAboutASH: 'ProviderEducationLibraryAboutASH',
	providerEducationLibraryAdminTopics: 'ProviderEducationLibraryAdminTopics',
	providerEducationLibraryClinicalTopics:
		'ProviderEducationLibraryClinicalTopics',
	providerEducationLibraryEvidenceEvaluationCommittee:
		'ProviderEducationLibraryEvidenceEvaluationCommittee',
	providerEducationLibraryPatientEducation:
		'ProviderEducationLibraryPatientEducation',
	providerEducationLibraryPolicy: 'ProviderEducationLibraryPolicy',
	providerEducationLibraryProfessionalAffairsHealthcareAdvisoryCommittee:
		'ProviderEducationLibraryProfessionalAffairsHealthcareAdvisoryCommittee',
	providerEducationLibraryVideoSeries: 'ProviderEducationLibraryVideoSeries',
	providerPractitionerVirtualServicesAgreement:
		'ProviderPractitionerVirtualServicesAgreement',
	providerValidation: 'ProviderValidation',
	providerServiceAgreement: 'ProviderServiceAgreement',
	providerStatusChangeRequest: 'ProviderStatusChangeRequest',
	practitionerValidation: 'PractitionerValidation',
	practitionerServiceAgreement: 'PractitionerServiceAgreement',
	practitionerStatusChangeRequest: 'PractitionerStatusChangeRequest',
	practitionerEducationLibraryAboutASH:
		'PractitionerEducationLibraryAboutASH',
	practitionerEducationLibraryAdminTopics:
		'PractitionerEducationLibraryAdminTopics',
	practitionerEducationLibraryClinicalTopics:
		'PractitionerEducationLibraryClinicalTopics',
	practitionerEducationLibraryEvidenceEvaluationCommittee:
		'PractitionerEducationLibraryEvidenceEvaluationCommittee',
	practitionerEducationLibraryPatientEducation:
		'PractitionerEducationLibraryPatientEducation',
	practitionerEducationLibraryPolicy: 'PractitionerEducationLibraryPolicy',
	practitionerEducationLibraryProfessionalAffairsHealthcareAdvisoryCommittee:
		'PractitionerEducationLibraryProfessionalAffairsHealthcareAdvisoryCommittee',
	practitionerEducationLibraryVideoSeries:
		'PractitionerEducationLibraryVideoSeries',
	linkedUser: 'LinkedUser',
	ASHCareSSO: 'ASHCareSSO',
};

export const permissionOperation = {
	read: 'Read',
	createUpdate: 'CreateUpdate',
	delete: 'Delete',
};

export const retrievePermissionWithKey = {
	[`${pageKey.clientSummary}`]: `${permissionName.clientSummary}:${permissionOperation.read}`,
	[`${pageKey.valueAddedProgram}`]: `${permissionName.valueAddedProgram}:${permissionOperation.read}`,
	[`${pageKey.home}`]: `${permissionName.general}:${permissionOperation.read}`,
	[`${pageKey.contactUs}`]: `${permissionName.general}:${permissionOperation.read}`,
	[`${pageKey.termsAndCondition}`]: `${permissionName.general}:${permissionOperation.read}`,
	[`${pageKey.privacyStatement}`]: `${permissionName.general}:${permissionOperation.read}`,
	[`${pageKey.frequentlyAskedQuestion}`]: `${permissionName.general}:${permissionOperation.read}`,
	[`${pageKey.ASHLinkWebsiteTip}`]: `${permissionName.general}:${permissionOperation.read}`,
	[`${pageKey.batchClaimsSummary}`]: `${permissionName.batchClaim}:${permissionOperation.read}`,
	[`${pageKey.batchClaimsUpload}`]: `${permissionName.batchClaim}:${permissionOperation.createUpdate}`, // BatchClaim:CreateUpdate
	[`${pageKey.memberReferral}`]: `${permissionName.memberReferral}:${permissionOperation.read}`,
	[`${pageKey.favoriteCPTCode}`]: `${permissionName.favoriteCPTCode}:${permissionOperation.read}`,
	[`${pageKey.transactionSummary}`]: `${permissionName.activitySummary}:${permissionOperation.read}`,
	[`${pageKey.providerServiceAgreement}`]: `${permissionName.providerServiceAgreement}:${permissionOperation.read}`,
	[`${pageKey.practitionerServiceAgreement}`]: `${permissionName.practitionerServiceAgreement}:${permissionOperation.read}`,
	[`${pageKey.providerPractitionerVirtualServicesAgreement}`]: `${permissionName.providerPractitionerVirtualServicesAgreement}:${permissionOperation.read}`,
	[`${pageKey.operationsManual}`]: `${permissionName.operationManual}:${permissionOperation.read}`,
	[`${pageKey.resourcesForm}`]: `${permissionName.form}:${permissionOperation.read}`,
	[`${pageKey.CPSAnnualReview}`]: `${permissionName.CPSLetter}:${permissionOperation.read}`,
	[`${pageKey.notification}`]: `${permissionName.notification}:${permissionOperation.read}`,
	[`${pageKey.newsletter}`]: `${permissionName.newsletter}:${permissionOperation.read}`,
	[`${pageKey.ASHClearinghouse}`]: `${permissionName.clearinghouse}:${permissionOperation.read}`,
	[`${pageKey.compliance}`]: `${permissionName.compliance}:${permissionOperation.read}`,
	[`${pageKey.messageCenter}`]: `${permissionName.messageCenter}:${permissionOperation.read}`,
	[`${pageKey.submitAnOnlineQuestionOrComment}`]: `${permissionName.messageCenter}:${permissionOperation.createUpdate}`, // CreateUpdate
	[`${pageKey.confirmUpdateInformation}`]: [
		`${permissionName.practitionerValidation}:${permissionOperation.createUpdate}`,
		`${permissionName.providerValidation}:${permissionOperation.createUpdate}`,
	], // CreateUpdate
	[`${pageKey.newPatientSearch}`]: `${permissionName.newPatient}:${permissionOperation.read}`,
	[`${pageKey.existingPatientSearch}`]: `${permissionName.existingPatient}:${permissionOperation.read}`,
	[`${pageKey.claimsSearch}`]: `${permissionName.claim}:${permissionOperation.read}`,
	[`${pageKey.MNRFormSearch}`]: `${permissionName.MNR}:${permissionOperation.read}`,
	[`${pageKey.paymentsSearch}`]: `${permissionName.payment}:${permissionOperation.read}`,
	[`${pageKey.accountManagement}`]: [
		`${permissionName.NPI}:${permissionOperation.read}`,
		`${permissionName.practitionerStatusChangeRequest}:${permissionOperation.read}`,
		`${permissionName.linkedUser}:${permissionOperation.read}`,
		`${permissionName.generalUserInformation}:${permissionOperation.read}`,
		`${permissionName.providerStatusChangeRequest}:${permissionOperation.read}`,
	],
	[`${pageKey.practitionerEducationLibrary}`]: [
		`${permissionName.practitionerEducationLibraryAboutASH}:${permissionOperation.read}`,
		`${permissionName.practitionerEducationLibraryAdminTopics}:${permissionOperation.read}`,
		`${permissionName.practitionerEducationLibraryClinicalTopics}:${permissionOperation.read}`,
		`${permissionName.practitionerEducationLibraryEvidenceEvaluationCommittee}:${permissionOperation.read}`,
		`${permissionName.practitionerEducationLibraryPatientEducation}:${permissionOperation.read}`,
		`${permissionName.practitionerEducationLibraryPolicy}:${permissionOperation.read}`,
		`${permissionName.practitionerEducationLibraryProfessionalAffairsHealthcareAdvisoryCommittee}:${permissionOperation.read}`,
		`${permissionName.practitionerEducationLibraryVideoSeries}:${permissionOperation.read}`,
	],
	[`${pageKey.providerEducationLibrary}`]: [
		`${permissionName.providerEducationLibraryAboutASH}:${permissionOperation.read}`,
		`${permissionName.providerEducationLibraryAdminTopics}:${permissionOperation.read}`,
		`${permissionName.providerEducationLibraryClinicalTopics}:${permissionOperation.read}`,
		`${permissionName.providerEducationLibraryEvidenceEvaluationCommittee}:${permissionOperation.read}`,
		`${permissionName.providerEducationLibraryPatientEducation}:${permissionOperation.read}`,
		`${permissionName.providerEducationLibraryPolicy}:${permissionOperation.read}`,
		`${permissionName.providerEducationLibraryProfessionalAffairsHealthcareAdvisoryCommittee}:${permissionOperation.read}`,
		`${permissionName.providerEducationLibraryVideoSeries}:${permissionOperation.read}`,
	],
	[`${pageKey.reopenModificationsSearch}`]: `${permissionName.MNR}:${permissionOperation.read}`,
	[`${pageKey.remittanceAdviceSearch}`]: `${permissionName.payment}:${permissionOperation.read}`,
	[`${pageKey.ashcare}`]: `${permissionName.ASHCareSSO}:${permissionOperation.read}`,
	[`${pageKey.manageSubAccounts}`]: `${permissionName.ASHCareSSO}:${permissionOperation.read}`, // TODO
	[`${pageKey.favoriteCptCodes}`]: `${permissionName.ASHCareSSO}:${permissionOperation.read}`, // TODO
	[`${pageKey.updateInfo}`]: `${permissionName.ASHCareSSO}:${permissionOperation.read}`, // TODO
};

export const retrievePageKeyWithRoute = {
	[`/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.clientSummaries}`]:
		pageKey.clientSummary,
	[`/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.providerServiceAgreements}`]:
		pageKey.providerServiceAgreement,
	[`/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.practitionerServiceAgreements}`]:
		pageKey.practitionerServiceAgreement,
	[`/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.operationsManual}`]:
		pageKey.operationsManual,
	[`/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.newsletters}`]:
		pageKey.newsletter,
	[`/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.notifications}`]:
		pageKey.notification,
	[`/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.valueAddedProgram}`]:
		pageKey.valueAddedProgram,
	[`/${TOP_LEVEL_ROUTES.help}/${CLINICAL_PAGE_ROUTES.faq}`]:
		pageKey.frequentlyAskedQuestion,
	[`/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.providerEducationLibrary}`]:
		pageKey.providerEducationLibrary,
	[`/${TOP_LEVEL_ROUTES.resources}/${CLINICAL_PAGE_ROUTES.practitionerEducationLibrary}`]:
		pageKey.practitionerEducationLibrary,
};
