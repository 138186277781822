import Typography from '@mui/material/Typography';
import ClinicalFileType from '../../scripts/clinicalFileType';
import ResourceTablePage from './shared/ResourceTablePage';

const clinicalFileType =
	ClinicalFileType.ashlinkClinicalClientSummaryResource.value;

const ClientSummaries = () => {
	const pageTitle = 'Client Summaries';
	const resourceColumnTitle = 'Client Summary Resource';
	const pageSummary = (
		<>
			<Typography fontWeight='fontWeightBold' mb={2}>
				Client Summaries outline eligible health plan clients contracted
				with ASH and contain specific guidelines relevant to that plan.
				This includes patient benefit and eligibility information,
				Clinical Services Program guidelines, allowable CPT codes,
				reimbursement rates and other pertinent information.
			</Typography>
			<Typography mb={2}>
				For any covered condition (ICD-10 Codes), all covered services
				(CPT Codes) under an applicable client summary are reimbursable
				when verified as medically necessary or when delivered under any
				applicable clinical performance system.
			</Typography>
			<Typography mb={2}>
				This is in effect for those client summaries where ASH directly
				pays claims. For all other client summaries, the policies
				employed by ASH&apos;s health plan client as described in the
				applicable client summary will continue to govern these matters.
			</Typography>
			<Typography mb={2}>
				American Specialty Health Affiliates (ASHA) uses industry
				standard coding sets including the International Classification
				of Diseases (ICD-10), Current Procedure Terminology (CPT), and
				Healthcare Common Procedure Coding System (HCPCS), for reporting
				diagnoses, procedures, and other services. The use of such
				coding sets does not imply endorsement by the World Health
				Organization (WHO), the American Medical Association (AMA), or
				any other professional society.
			</Typography>
		</>
	);

	return (
		<ResourceTablePage
			pageTitle={pageTitle}
			resourceColumnTitle={resourceColumnTitle}
			pageSummary={pageSummary}
			clinicalFileType={clinicalFileType}
			disableReleaseDateColumn
		/>
	);
};

export default ClientSummaries;
