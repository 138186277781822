import { rest } from 'msw';
import {
	permissionName,
	permissionOperation,
	pageKey,
} from '../../scripts/pageConstants';

const getSpecialtyDescription = specialtyCode => {
	switch (specialtyCode) {
		// Clinical
		case 1:
			return 'Chiropractic';
		case 2:
			return 'Acupuncture';
		case 7:
			return 'Massage Therapy';
		case 11:
			return 'Naturopathy';
		case 12:
			return 'Dietitian';
		case 37:
			return 'Podiatry';
		// Rehab
		case 26:
			return 'PT/OT';
		// Fitness
		case 24:
			return 'Fitness Club';
		case 29:
			return 'Fitness Instructor';
		case 31:
			return 'Fitness Studio';
		default:
			return '';
	}
};

export const mockPermissions = [
	`${permissionName.general}:${permissionOperation.read}`,
	`${permissionName.memberReferral}:${permissionOperation.read}`,
	`${permissionName.clientSummary}:${permissionOperation.read}`,
	`${permissionName.operationManual}:${permissionOperation.read}`,
	`${permissionName.form}:${permissionOperation.read}`,
	`${permissionName.CPSLetter}:${permissionOperation.read}`,
	`${permissionName.notification}:${permissionOperation.read}`,
	`${permissionName.newsletter}:${permissionOperation.read}`,
	`${permissionName.valueAddedProgram}:${permissionOperation.read}`,
	`${permissionName.batchClaim}:${permissionOperation.read}`,
	`${permissionName.batchClaim}:${permissionOperation.createUpdate}`,
	`${permissionName.clearinghouse}:${permissionOperation.read}`,
	`${permissionName.compliance}:${permissionOperation.read}`,
	`${permissionName.practitionerServiceAgreement}:${permissionOperation.read}`,
	`${permissionName.messageCenter}:${permissionOperation.read}`,
	`${permissionName.messageCenter}:${permissionOperation.createUpdate}`,
	`${permissionName.generalUserInformation}:${permissionOperation.read}`,
	`${permissionName.generalUserInformation}:${permissionOperation.createUpdate}`,
	`${permissionName.newPatient}:${permissionOperation.read}`,
	`${permissionName.existingPatient}:${permissionOperation.read}`,
	`${permissionName.MNR}:${permissionOperation.read}`,
	`${permissionName.claim}:${permissionOperation.read}`,
	`${permissionName.payment}:${permissionOperation.read}`,
	`${permissionName.NPI}:${permissionOperation.read}`,
	`${permissionName.statusChangeRequest}:${permissionOperation.read}`,
	`${permissionName.practitionerEducationLibraryAboutASH}:${permissionOperation.read}`,
	`${permissionName.favoriteCPTCode}:${permissionOperation.read}`,
	`${permissionName.activitySummary}:${permissionOperation.read}`,
	`${permissionName.practitionerValidation}:${permissionOperation.createUpdate}`,
	`${permissionName.ASHCareSSO}:${permissionOperation.read}`,
];

const mockRedirectionLinks = [
	{ pageKey: pageKey.home, redirectUrl: '//dev.ashlink.com' },
	{
		pageKey: pageKey.messageCenter,
		redirectUrl:
			'//dev.ashlink.com/ASH/account/messages/messagecenter.aspx',
	},
	{
		pageKey: pageKey.transactionSummary,
		redirectUrl: '//dev.ashlink.com/ASH/account/summary/summary.aspx',
	},
	{
		pageKey: pageKey.favoriteCPTCode,
		redirectUrl: '//dev.ashlink.com/ASH/account/services/services.aspx',
	},
	{
		pageKey: pageKey.confirmUpdateInformation,
		redirectUrl:
			'//dev.ashlink.com/ASH/account/accountValidation.aspx?qs=corrections',
	},
	{
		pageKey: pageKey.contactUs,
		redirectUrl: '//dev.ashlink.com/ASH/help/contactus.aspx',
	},
	{
		pageKey: pageKey.privacyStatement,
		redirectUrl: '//dev.ashlink.com/ASH/site/legal/privacy.aspx',
	},
	{
		pageKey: pageKey.termsAndCondition,
		redirectUrl: '//dev.ashlink.com/ASH/site/legal/UserAgreement.aspx',
	},
	{
		pageKey: pageKey.ASHLinkWebsiteTip,
		redirectUrl: '//dev.ashlink.com/ASH/help/tips.aspx',
	},
	{
		pageKey: pageKey.submitAnOnlineQuestionOrComment,
		redirectUrl: '//dev.ashlink.com/ASH/help/feedback.aspx',
	},
	{
		pageKey: pageKey.CPSAnnualReview,
		redirectUrl: '//dev.ashlink.com/ASH/resources/forms/letters.aspx',
	},
	{
		pageKey: pageKey.batchClaimsUpload,
		redirectUrl: '//dev.ashlink.com/ASH/tools/uploads.aspx',
	},
	{
		pageKey: pageKey.batchClaimsSummary,
		redirectUrl: '//dev.ashlink.com/ASH/tools/batcheclaim.aspx',
	},
	{
		pageKey: pageKey.ASHClearinghouse,
		redirectUrl: '//dev.ashlink.com/ASH/tools/clearinghouse.aspx',
	},
	{
		pageKey: pageKey.existingPatientSearch,
		redirectUrl: '//dev.ashlink.com/ASH/Inquiry/default.aspx?tab=1',
	},
	{
		pageKey: pageKey.newPatientSearch,
		redirectUrl: '//dev.ashlink.com/ASH/Inquiry/default.aspx?tab=0',
	},
	{
		pageKey: pageKey.MNRFormSearch,
		redirectUrl: '//dev.ashlink.com/ASH/Inquiry/default.aspx?tab=2',
	},
	{
		pageKey: pageKey.claimsSearch,
		redirectUrl: '//dev.ashlink.com/ASH/Inquiry/default.aspx?tab=2',
	},
	{
		pageKey: pageKey.paymentsSearch,
		redirectUrl: '//dev.ashlink.com/ASH/Inquiry/default.aspx?tab=2',
	},
	{
		pageKey: pageKey.reopenModificationsSearch,
		redirectUrl: '//dev.ashlink.com/ASH/Inquiry/default.aspx?tab=2',
	},
	{
		pageKey: pageKey.remittanceAdviceSearch,
		redirectUrl: '//dev.ashlink.com/ASH/Inquiry/default.aspx?tab=3',
	},
	{
		pageKey: pageKey.memberReferral,
		redirectUrl: '//dev.ashlink.com/ASH/Inquiry/displayReferral.aspx',
	},
];

export const mockSessionContextResult = (
	providerSpecialtyCode,
	practitionerSpecialtyCode,
	providerNetCode
) => ({
	resultCode: 0,
	resultText: 'Success',
	data: {
		ashlinkUserId: 123456,
		ashlinkUserType: 1,
		ashlinkUserLevel: 1,
		ashlinkUserEntityType: 1,
		providerId: 12345,
		drFedId: 123456,
		ashlinkUserRedirections: [...mockRedirectionLinks],
		permissions: [...mockPermissions],
		providerSpecialtyCode,
		providerNetCode,
		practitionerId: 0,
		practitionerNetCode: 0,
		practitionerSpecialtyCode,
		providerSpecialtyDescription: getSpecialtyDescription(
			providerSpecialtyCode
		),
		practitionerSpecialtyDescription: getSpecialtyDescription(
			practitionerSpecialtyCode
		),
		userInfo: {
			firstName: 'John',
			lastName: 'Smith',
			address1: '9433 FM 2244 Rd',
			city: 'Austin',
			state: 'TX',
			zipCode: '78733',
			specialtyDescription: getSpecialtyDescription(
				providerSpecialtyCode
			),
			tierLevel: 6,
		},
	},
	date: '2023-02-15T23:25:39.313711Z',
});

export const pages = (
	providerSpecialtyCode,
	practitionerSpecialtyCode,
	providerNetCode
) => ({
	fail: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 32,
		subResultText: 'ContentManagementApiFail',
		errors: [
			{
				message:
					'Expected exception. (TransactionId: 1b62f160-92ba-4999-b3f1-e2a45c0d99af)',
			},
		],
		date: '2023-02-22T05:16:50.4312862Z',
	},
	result: {
		...mockSessionContextResult(
			providerSpecialtyCode,
			practitionerSpecialtyCode,
			providerNetCode
		),
	},
	notFound: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 9001,
		subResultText: 'ContentNotFound',
		errors: [
			{
				message: 'No content returned from Contentful.',
			},
		],
		date: '2023-02-22T05:36:28.3714538Z',
	},
});

export const getSessionContextSuccess = [
	rest.get('/clinical/auth/userparams', (req, res, ctx) =>
		res(ctx.status(200), ctx.json(pages(1, 0, 1).result))
	),
];

export const getSessionContextSuccessWithOneLegacyLink = [
	rest.get('/auth/userparams', (req, res, ctx) => {
		const response = pages(1, 0, 1).result;
		response.data.ashlinkUserRedirections = [
			{
				pageKey: 'operations-manual',
				redirectUrl: 'https://dev.ashlink.com',
			},
		];
		return res(ctx.status(200), ctx.json(response));
	}),
];

export const getSessionContextSuccessWithProviderEducationLibraryPermission = [
	rest.get('/auth/userparams', (req, res, ctx) => {
		const response = pages(1, 0, 1).result;
		response.data.permissions = [
			`${permissionName.providerEducationLibraryAboutASH}:${permissionOperation.read}`,
		];
		return res(ctx.status(200), ctx.json(response));
	}),
];

export const getSessionContextSuccessWithPractitionerEducationLibraryPermission =
	[
		rest.get('/auth/userparams', (req, res, ctx) => {
			const response = pages(1, 0, 1).result;
			response.data.permissions = [
				`${permissionName.practitionerEducationLibraryAboutASH}:${permissionOperation.read}`,
			];

			return res(ctx.status(200), ctx.json(response));
		}),
	];

export const getSessionContextFail = [
	rest.get('/clinical/auth/userparams', (req, res, ctx) =>
		res(ctx.status(500), ctx.json(pages.fail))
	),
];

export const getCustomSpecialtySessionContextSuccess = (
	providerSpecialtyCode,
	practitionerSpecialtyCode,
	providerNetCode
) => [
	rest.get('/clinical/auth/userparams', (req, res, ctx) =>
		res(
			ctx.status(200),
			ctx.json(
				pages(
					providerSpecialtyCode,
					practitionerSpecialtyCode,
					providerNetCode
				).result
			)
		)
	),
];
