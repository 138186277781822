import restDataService from './restDataService';

const ihisApiBasePath = 'api/ashlinknextapigateway/ihis';

// Endpoints will return 404 if a resources is not found.  ValidateStatus is used to prevent a 404 from throwing an exception

export const GetServiceCode = async (serviceCodeType, serviceCode) =>
	restDataService.get(`${ihisApiBasePath}/ServiceCode`, {
		params: {
			serviceCodeType,
			serviceCode,
		},
	});

export const GetServiceCodes = async (
	serviceCodeType,
	codeSearch,
	descriptionSearch,
	specialtyCode
) =>
	restDataService.get(`${ihisApiBasePath}/ServiceCodes`, {
		params: {
			serviceCodeType,
			codeSearch,
			descriptionSearch,
			specialtyCode,
		},
	});
