import { useLocation, useNavigate } from 'react-router-dom';
import VideoPlayer from '@ashn/component-library-react/VideoPlayer';
import Button from '@mui/material/Button';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const Video = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { state } = location;
	const { url, name } = state;

	let videoSrc = '';
	let videoType = '';

	function supportsHLS() {
		const video = document.createElement('video');
		return Boolean(
			video.canPlayType('application/vnd.apple.mpegURL') ||
				video.canPlayType('audio/mpegurl')
		);
	}

	// Get the file extenstion
	const ext = url.split('.').pop().toLowerCase();

	if (ext === 'mp4') {
		// Video not processed for streaming
		videoSrc = `${url}`;
	} // Video that's processed for streaming through MideaKind or similar platforms
	else {
		const extn = supportsHLS()
			? 'your-hls-url.m3u8'
			: 'format=mpd-time-cmaf';
		videoSrc = `${url}(${extn})`;
		videoType = supportsHLS()
			? 'application/vnd.apple.mpegURL'
			: 'application/dash+xml';
	}

	return (
		<Container>
			<Button
				size='small'
				startIcon={<ArrowLeftIcon />}
				onClick={() => {
					navigate(-1);
				}}
			>
				Back to Library
			</Button>
			<Typography variant='h5' component='h1' mb={2} pt={3}>
				{name}
			</Typography>

			<VideoPlayer
				options={{
					autoplay: true,
					sources: [
						{
							src: videoSrc,
							type: videoType,
						},
					],
				}}
			/>
		</Container>
	);
};

export default Video;
