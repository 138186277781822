import { rest } from 'msw';

export const results = {
	fail: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 12,
		subResultText: 'RequestValidationFail',
		errors: [
			{
				message: 'Please provide a valid DocId.',
			},
		],
		date: '2024-01-22T20:47:10.2094046Z',
	},
	InComplete: {
		resultCode: 0,
		resultText: 'Success',
		data: {
			complianceStatuses: [
				{
					complianceType: 'Fraud, Waste, Abuse',
					complete: false,
				},
				{
					complianceType: 'General Compliance',
					complete: false,
				},
			],
		},
		date: '2024-02-20T18:23:56.6450298Z',
	},
	Complete: {
		resultCode: 0,
		resultText: 'Success',
		data: {
			complianceStatuses: [
				{
					complianceType: 'Fraud, Waste, Abuse',
					complete: true,
				},
				{
					complianceType: 'General Compliance',
					complete: true,
				},
			],
		},
		date: '2024-01-22T06:52:35.9450797Z',
	},
};

export const complianceStatusInComplete = [
	rest.get(
		'/clinical/api/Confirmation/Compliance/ComplianceStatuses',
		(req, res, ctx) => res(ctx.status(200), ctx.json(results.InComplete))
	),
];

export const complianceStatusComplete = [
	rest.get(
		'/clinical/api/Confirmation/Compliance/ComplianceStatuses',
		(req, res, ctx) => res(ctx.status(200), ctx.json(results.Complete))
	),
];

export const complianceStatusLoading = [
	rest.get(
		'/clinical/api/Confirmation/Compliance/ComplianceStatuses',
		(req, res, ctx) => res(ctx.delay('infinite'))
	),
];

export const complianceStatusFail = [
	rest.get(
		'/clinical/api/Confirmation/Compliance/ComplianceStatuses',
		(req, res, ctx) => res(ctx.status(500), ctx.json(results.fail))
	),
];

export const complianceStatus = [
	...complianceStatusInComplete,
	...complianceStatusComplete,
	...complianceStatusLoading,
	...complianceStatusFail,
];
