import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';

import CustomNoResultsOverlay from '../shared/CustomNoResultsOverlay';
import CustomLoading from '../shared/CustomLoading';

const ValueAddedProgramList = ({
	valueAddedVendors,
	valueAddedVendorsIsLoading,
	onVendorClick,
}) => {
	const {
		data: { data: { entries = [] } = {}, resultCode = 0 } = {},
		statusCode,
	} = valueAddedVendors;
	const columns = [
		{
			field: 'displayName',
			sortable: false,
			flex: 2,
			minWidth: 200,
			hideable: false,
			renderHeader: () => (
				<Typography variant='body2'>Value Added Vendor</Typography>
			),
			renderCell: params => (
				<Button
					onClick={() => onVendorClick(params.row.id)}
					sx={{ textTransform: 'none' }}
				>
					<Stack direction='column'>
						<img
							src={params.row.imageUrl}
							alt={`${params.row.displayName} logo`}
							width='180'
						/>
						{params.row.displayName}
					</Stack>
				</Button>
			),
		},
		{
			field: 'description',
			sortable: false,
			renderHeader: () => (
				<Typography variant='body2'>Products and Services</Typography>
			),
			renderCell: params => (
				<Typography variant='body2'>
					{params.row.description}
				</Typography>
			),
			flex: 3,
			minWidth: 175,
			hideable: false,
		},
		{
			field: 'discount',
			sortable: false,
			renderHeader: () => (
				<Typography variant='body2'>
					ASH Practitioner Discount
				</Typography>
			),
			renderCell: params => (
				<Box my={3}>
					<Typography variant='body2' gutterBottom>
						{params.row.providerDiscount}
					</Typography>
					{params.row.howToRedeemDiscount && (
						<Typography variant='body2'>
							<Box component='span' fontWeight='fontWeightBold'>
								How To Redeem:
							</Box>{' '}
							{params.row.howToRedeemDiscount}
						</Typography>
					)}
				</Box>
			),
			flex: 3,
			minWidth: 200,
			hideable: false,
		},
	];

	return (
		<>
			<Typography fontWeight='fontWeightBold' gutterBottom>
				American Specialty Health (ASH) is pleased to introduce the
				Value Added Products Program. By selecting the vendor of
				interest below, you can link directly to their Web site, to
				learn more about their product offerings, as well as place
				orders. Practitioners can take advantage of discounts of 15% or
				more in the areas of malpractice insurance, X-ray equipment and
				supplies, durable medical equipment, continuing education
				(including CEU for license renewal), and much more.
			</Typography>
			<Box mb={2}>
				<DataGrid
					columns={columns}
					disableColumnMenu
					disableColumnFilter
					disableColumnSelector
					disableSelectionOnClick
					rowSelection={false}
					hideFooter
					autoHeight
					initialState={{
						sorting: {
							sortModel: [
								{
									field: 'displayName',
								},
							],
						},
					}}
					getRowHeight={() => 'auto'}
					rows={entries}
					loading={valueAddedVendorsIsLoading}
					components={{
						NoRowsOverlay: CustomNoResultsOverlay,
						LoadingOverlay: CustomLoading,
					}}
					componentsProps={{
						noRowsOverlay: {
							resultCode,
							statusCode,
						},
					}}
				/>
			</Box>
			<Typography gutterBottom>
				ASH is not and will not solicit any fees, rebates or commissions
				from external value-added vendors. This program was created with
				the goal of assisting ASH practitioners in reducing their
				monthly costs and overhead by taking advantage of the discounts
				offered through participating external value-added vendors. ASH
				does not warrant, represent, or guarantee the quality and/or
				performance of the products or services offered by its
				Value-Added vendors. Buyers assume all risk associated with the
				use and purchase of all products and services.
			</Typography>
		</>
	);
};

export default ValueAddedProgramList;

ValueAddedProgramList.propTypes = {
	valueAddedVendors: PropTypes.object,
	valueAddedVendorsIsLoading: PropTypes.bool.isRequired,
	onVendorClick: PropTypes.func.isRequired,
};

ValueAddedProgramList.defaultProps = {
	valueAddedVendors: {},
};
