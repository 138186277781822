import Typography from '@mui/material/Typography';

import ClinicalFileType from '../../scripts/clinicalFileType';
import ResourceTablePage from './shared/ResourceTablePage';

const clinicalFileType = ClinicalFileType.ashlinkClinicalFormResource.value;

const Forms = () => {
	const pageTitle = 'Forms';
	const pageSummary = (
		<Typography fontWeight='fontWeightBold' mb={2}>
			The Forms section is a complete alphabetized list of ASH forms for
			viewing and printing.
		</Typography>
	);
	const resourceColumnTitle = 'Form';

	return (
		<ResourceTablePage
			pageTitle={pageTitle}
			resourceColumnTitle={resourceColumnTitle}
			pageSummary={pageSummary}
			clinicalFileType={clinicalFileType}
			disableEffectiveDateColumn
			disableReleaseDateColumn
		/>
	);
};

export default Forms;
