import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import CustomNoResultsOverlay from '../../shared/CustomNoResultsOverlay';
import CustomLoading from '../../shared/CustomLoading';

const EducationPagingTable = ({
	enableVideoSeriesColumns,
	resourceColumnTitle,
	resources,
	resultCode,
	statusCode,
	subresultCode,
}) => {
	const [pageSize, setPageSize] = useState(10);

	const noResultsOrRowsConfig = {
		resultCode,
		statusCode,
		subresultCode,
	};

	// const { pathname } = useLocation(); // When trying to test this I get useLocation cannot be used outside of a router context even though it's wrapped in a router in the test
	const { href } = window.location;

	const columns = [
		{
			field: 'topic',
			sortable: false,
			flex: 0.5,
			minWidth: 150,
			hideable: false,
			renderHeader: () => (
				<Typography variant='body2' fontWeight='fontWeightBold'>
					{resourceColumnTitle}
				</Typography>
			),
			renderCell: params => {
				if (params.row.videoUrl !== undefined) {
					return (
						// TODO/FIXME: If the video pages need to be able to be sent/bookmarked, this
						// is not going to work since it is sending the url from this link. The video
						// page will need hit an endpoint to fetch the corresponding video itself.
						<Link
							data-testid={`link-${params.row.id}`}
							component={RouterLink}
							to={`${href}/${params.row.id}`}
							state={{
								url: params.row.videoUrl,
								name: params.row.displayName,
							}}
							sx={{
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
							rel='noreferrer'
						>
							{params.row.displayName}
						</Link>
					);
				}
				return (
					<Link
						data-testid={`link-${params.row.id}`}
						href={params.row.url}
						sx={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
						target='_blank'
						rel='noreferrer'
					>
						{params.row.displayName}
					</Link>
				);
			},
			getApplyQuickFilterFn: value => params =>
				params.row.displayName
					.toLowerCase()
					.includes(value.toLowerCase()),
			valueGetter: params => params.row.displayName,
			sortComparator: (a, b) =>
				a.localeCompare(b, 'en', { numeric: true }),
		},
	];
	if (enableVideoSeriesColumns) {
		columns.push({
			field: 'videoDuration',
			sortable: false,
			flex: 0.3,
			minWidth: 290,
			renderHeader: () => (
				<Typography variant='body2' fontWeight='fontWeightBold'>
					Length
				</Typography>
			),
		});
	}

	return (
		<Paper elevation={0}>
			<DataGrid
				columns={columns}
				disableColumnMenu
				disableColumnFilter
				disableColumnSelector
				disableSelectionOnClick
				disableDensitySelector
				rows={resources}
				rowsPerPageOptions={[10, 20, 50]}
				pageSize={pageSize}
				onPageSizeChange={setPageSize}
				autoHeight
				initialState={{
					sorting: {
						sortModel: [
							{
								field: 'topic',
							},
						],
					},
				}}
				components={{
					NoRowsOverlay: CustomNoResultsOverlay,
					NoResultsOverlay: CustomNoResultsOverlay,
					LoadingOverlay: CustomLoading,
					Toolbar: GridToolbar,
				}}
				componentsProps={{
					noRowsOverlay: {
						...noResultsOrRowsConfig,
						customNoResultsVerbiage: 'No Resources Available',
					},
					noResultsOverlay: {
						...noResultsOrRowsConfig,
						customNoResultsVerbiage:
							'No results found. Please check your search and try again.',
					},
					toolbar: {
						showQuickFilter: resources.length > 0,
						printOptions: { disableToolbarButton: true },
						csvOptions: { disableToolbarButton: true },
						quickFilterProps: { debounceMs: 0 },
					},
				}}
			/>
		</Paper>
	);
};

EducationPagingTable.propTypes = {
	enableVideoSeriesColumns: PropTypes.bool,
	resourceColumnTitle: PropTypes.string.isRequired,
	resources: PropTypes.array,
	resultCode: PropTypes.number,
	statusCode: PropTypes.number,
	subresultCode: PropTypes.number,
};

EducationPagingTable.defaultProps = {
	enableVideoSeriesColumns: false,
	resources: [],
	resultCode: 0,
	statusCode: 0,
	subresultCode: 0,
};

export default EducationPagingTable;
