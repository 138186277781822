import {
	DataGrid,
	GridFooter,
	GridToolbar,
	gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import { useContext, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { getServiceLocations } from '../../../../../../../data/promisApiCalls';
import CustomLoading from '../../../../../../shared/CustomLoading';
import { AuthSubmissionContext } from '../../AuthSubmissionContext';
import { formatAddress } from '../../../../../../../scripts/addressHelper';

const SelectServiceLocation = ({ practitionerId, onSelect }) => {
	const { sessionProviderId, sessionPractitionerId } = useContext(
		AuthSubmissionContext
	);

	const [selectionModel, setSelectionModel] = useState([]);
	const [pageSize, setPageSize] = useState(10);

	const { setError } = useContext(AuthSubmissionContext);

	const columns = [
		{
			field: 'id',
			headerName: 'ID',
			flex: 1,
		},
		{
			field: 'selected',
			headerName: 'Select',
			sortable: false,
			renderCell: e => (
				<Radio
					checked={selectionModel?.id === e.id ?? false}
					value={e.id}
					inputProps={{
						'aria-label': `${e.row.clinicName} at ${e.row.address1}`,
					}}
				/>
			),
		},
		{
			field: 'name',
			headerName: 'Clinic',
			flex: 2,
			minWidth: 300,
			valueGetter: p => ({
				name: p.row.name,
				address: formatAddress(
					p.row.address1,
					p.row.address2,
					p.row.city,
					p.row.state,
					p.row.zipCode
				),
			}),
			valueFormatter: p => p.value.name,
			sortComparator: (v1, v2, p1, p2) =>
				gridStringOrNumberComparator(
					`${v1.name} ${v1.address}`,
					`${v2.name} ${v2.address}`,
					p1,
					p2
				),
			getApplyQuickFilterFn: value => p =>
				p.row.name.toLowerCase().includes(value.toLowerCase()),
		},
		{
			field: 'address',
			headerName: 'Address',
			flex: 4,
			minWidth: 300,
			valueGetter: p => ({
				name: p.row.name,
				address: formatAddress(
					p.row.address1,
					p.row.address2,
					p.row.city,
					p.row.state,
					p.row.zipCode
				),
			}),
			valueFormatter: p => p.value.address,
			sortComparator: (v1, v2, p1, p2) =>
				gridStringOrNumberComparator(
					`${v1.address} ${v1.name}`,
					`${v2.address} ${v2.name}`,
					p1,
					p2
				),
			getApplyQuickFilterFn: value => p =>
				p.value.address.toLowerCase().includes(value.toLowerCase()),
		},
		{
			field: 'effectiveDate',
			headerName: 'Effective',
			flex: 1,
			minWidth: 100,
		},
		{ field: 'termDate', headerName: 'Termed', flex: 1, minWidth: 100 },
	];

	const { isLoading, isError, data } = useQuery(
		['getServiceLocations', practitionerId],
		async () =>
			getServiceLocations(
				sessionProviderId,
				practitionerId ?? sessionPractitionerId
			),
		{ staleTime: Infinity }
	);

	const { data: { resultCode, data: { serviceLocations = [] } = {} } = {} } =
		data ?? {};

	// https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#defining-on-demand-messages
	useEffect(() => {
		if (isError || (data && resultCode !== 0)) {
			setError('There was a problem getting service locations');
			window.scrollTo({
				top: 0,
				left: 0,
			});
		}
	}, [isError, data]);

	if (isLoading) {
		return <CustomLoading />;
	}
	if (isError || (data && resultCode !== 0)) {
		// https://medium.com/@davidkelley87/stop-using-return-null-in-react-a2ebf08fc9cd
		return false;
	}

	return (
		<Box>
			<Typography fontWeight='bold'>
				Select Service Location for this E-MNR:
			</Typography>
			<DataGrid
				autoHeight
				rows={serviceLocations ?? []}
				loading={isLoading}
				columns={columns}
				columnVisibilityModel={{
					id: false,
				}}
				components={{
					LoadingOverlay: CustomLoading,
					Footer: GridFooter,
					Toolbar: GridToolbar,
				}}
				componentsProps={{
					toolbar: {
						showQuickFilter: serviceLocations.length > 0,
						printOptions: {
							disableToolbarButton: true,
						},
						csvOptions: { disableToolbarButton: true },
						quickFilterProps: { debounceMs: 1000 },
					},
					footer: {},
				}}
				pageSize={pageSize}
				onPageSizeChange={newPageSize => setPageSize(newPageSize)}
				rowsPerPageOptions={[10, 20, 50]}
				hideFooterSelectedRowCount
				disableMultipleSelection
				disableColumnMenu
				disableColumnFilter
				disableColumnSelector
				disableDensitySelector
				selectionModel={selectionModel}
				onCellKeyDown={(p, e) => {
					if (e.code === 'Space') {
						e.preventDefault();
						e.stopPropagation();
						setSelectionModel(
							serviceLocations.find(x => x.id === p.id)
						);
					}
				}}
				onCellClick={p => {
					const newSelectionModel = serviceLocations.find(
						x => x.id === p.id
					);
					setSelectionModel(newSelectionModel);
					onSelect(newSelectionModel);
				}}
				initialState={{
					sorting: {
						sortModel: [{ field: 'clinicName', sort: 'asc' }],
					},
				}}
				sortingOrder={['asc', 'desc']}
			/>
			{selectionModel?.length !== 0 && (
				<Typography>{`Selected: ${
					selectionModel.name
				} at ${formatAddress(
					selectionModel.address1,
					selectionModel.address2,
					selectionModel.city,
					selectionModel.state,
					selectionModel.zipCode
				)}`}</Typography>
			)}
		</Box>
	);
};

SelectServiceLocation.propTypes = {
	practitionerId: PropTypes.number,
	onSelect: PropTypes.func.isRequired,
};

SelectServiceLocation.defaultProps = {
	practitionerId: null,
};

export default SelectServiceLocation;
