import { rest } from 'msw';

export const pages = {
	fail: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 32,
		subResultText: 'ContentManagementApiFail',
		errors: [
			{
				message:
					'Expected exception. (TransactionId: 1b62f160-92ba-4999-b3f1-e2a45c0d99af)',
			},
		],
		date: '2023-02-22T05:16:50.4312862Z',
	},
	result: {
		resultCode: 0,
		resultText: 'Success',
		data: {
			inactiveTimeLeft: 300000,
		},
		date: '2023-02-15T23:25:39.313711Z',
	},
	notFound: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 9001,
		subResultText: 'ContentNotFound',
		errors: [
			{
				message: 'No content returned from Contentful.',
			},
		],
		date: '2023-02-22T05:36:28.3714538Z',
	},
};

export const getStatusSuccess = [
	rest.get('/clinical/auth/sessionstatus', (req, res, ctx) =>
		res(ctx.status(200), ctx.json(pages.result))
	),
];

export const getStatusFail = [
	rest.get('/clinical/auth/sessionstatus', (req, res, ctx) =>
		res(ctx.status(500), ctx.json(pages.fail))
	),
];

export const getStatus = [...getStatusSuccess, ...getStatusFail];
