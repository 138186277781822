import { rest } from 'msw';

export const PutAuthManagementAuthFormSuccess = [
	rest.put('/clinical/api/authmanagement/auth/EAuth', (req, res, ctx) =>
		res(
			ctx.status(200),
			ctx.json({
				resultCode: 0,
				resultText: 'Success',
				data: {
					eAuthNo: 12345678,
				},
				date: '2023-11-10T16:32:01.1259012Z',
			})
		)
	),
];

export const PostAuthManagementCreateAuthSuccess = [
	rest.post(
		'/clinical/api/ashlinknextapigateway/Auth/EAuth',
		(req, res, ctx) =>
			res(
				ctx.status(200),
				ctx.json({
					resultCode: 0,
					resultText: 'Success',
					data: {
						eAuthNo: 12345678,
						SubmittedDate: '2024-05-14T17:09:28.0000000-07:00',
					},
					date: '2024-05-14T17:09:28.3567000-07:00',
				})
			)
	),
];

export const PostAuthManagementCreateAuthFail = [
	rest.post(
		'/clinical/api/ashlinknextapigateway/Auth/EAuth',
		(req, res, ctx) =>
			res(
				ctx.status(200),
				ctx.json({
					resultCode: 1,
					resultText: 'Fail',
					data: {
						eAuthNo: 12345678,
						SubmittedDate: '2024-05-14T17:09:28.0000000-07:00',
					},
					date: '2024-05-14T17:09:28.3567000-07:00',
				})
			)
	),
];
