import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { useContext } from 'react';
import Specialty from '../../../../../../../../scripts/specialty';
import {
	dateToShortString,
	stringtoNAIfNullOrEmpty,
} from '../../../../../../../../scripts/dataConversions';
import { AuthSubmissionContext } from '../../../AuthSubmissionContext';

const MedicalRecordsTreatmentServicesReview = () => {
	const { selectionData, authFormData } = useContext(AuthSubmissionContext);

	return (
		<Stack direction='column' spacing={3}>
			<Box>
				<Typography fontWeight='bold'>
					Are services for Habilitative care?
				</Typography>
				<Typography data-testid='review-habilitative-care'>
					{stringtoNAIfNullOrEmpty(
						authFormData.servicesForHabilitativeCare
					)}
				</Typography>
			</Box>
			<Box>
				<Typography fontWeight='bold'>
					Initial Start of care for this condition:
				</Typography>
				<Typography data-testid='review-start-date'>
					{stringtoNAIfNullOrEmpty(
						dateToShortString(authFormData.initialStartOfCareDate)
					)}
				</Typography>
			</Box>
			<Box>
				<Typography fontWeight='bold'>
					Date of Evaluation or Re-evaluation Findings Attached:
				</Typography>
				<Typography data-testid='review-findings-date'>
					{stringtoNAIfNullOrEmpty(
						dateToShortString(authFormData.dateOfFindingsAttached)
					)}
				</Typography>
			</Box>
			<Box>
				<Typography fontWeight='bold'>
					Number of Office Visits already rendered:
				</Typography>
				<Typography data-testid='review-office-visits'>
					{stringtoNAIfNullOrEmpty(authFormData.numberOfOfficeVisits)}
				</Typography>
			</Box>
			<Box>
				<Typography fontWeight='bold'>
					ICD-10 / Diagnoses (Highest level of specificity-Primary
					condition and Pathology codes):
				</Typography>
				<Stack direction='row' gap={3}>
					<Stack direction='row' gap={1}>
						<Typography fontWeight='bold'>1.</Typography>
						<Typography data-testid='review-dx-1'>
							{stringtoNAIfNullOrEmpty(authFormData.dxCode1)}
						</Typography>
					</Stack>
					<Stack direction='row' gap={1}>
						<Typography fontWeight='bold'>2.</Typography>
						<Typography data-testid='review-dx-2'>
							{stringtoNAIfNullOrEmpty(authFormData.dxCode2)}
						</Typography>
					</Stack>
					<Stack direction='row' gap={1}>
						<Typography fontWeight='bold'>3.</Typography>
						<Typography data-testid='review-dx-3'>
							{stringtoNAIfNullOrEmpty(authFormData.dxCode3)}
						</Typography>
					</Stack>
					<Stack direction='row' gap={1}>
						<Typography fontWeight='bold'>4.</Typography>
						<Typography data-testid='review-dx-4'>
							{stringtoNAIfNullOrEmpty(authFormData.dxCode4)}
						</Typography>
					</Stack>
				</Stack>
			</Box>
			<Box>
				<Typography fontWeight='bold'>Date Range for Care:</Typography>
				<Stack direction='row' gap={3}>
					<Stack direction='row' gap={1}>
						<Typography fontWeight='bold'>From:</Typography>
						<Typography data-testid='review-from-date'>
							{stringtoNAIfNullOrEmpty(
								dateToShortString(authFormData.fromDateForCare)
							)}
						</Typography>
					</Stack>
					<Stack direction='row' gap={1}>
						<Typography fontWeight='bold'>Through:</Typography>
						<Typography data-testid='review-through-date'>
							{stringtoNAIfNullOrEmpty(
								dateToShortString(
									authFormData.throughDateForCare
								)
							)}
						</Typography>
					</Stack>
				</Stack>
			</Box>
			<Box>
				<Typography fontWeight='bold'>
					Services requested within above date range, please indicate:
				</Typography>
				<Stack direction='row' gap={3}>
					<Stack direction='row' gap={1}>
						<Typography fontWeight='bold'>Evaluation:</Typography>
						<Typography data-testid='review-evaluation'>
							{stringtoNAIfNullOrEmpty(
								authFormData.evaluationCode
							)}
						</Typography>
					</Stack>
					<Stack direction='row' gap={1}>
						<Typography fontWeight='bold'>
							Re-evaluation:
						</Typography>
						<Typography data-testid='review-reevaluation'>
							{stringtoNAIfNullOrEmpty(
								authFormData.reevaluationCode
							)}
						</Typography>
					</Stack>
				</Stack>
			</Box>
			<Box>
				<Typography fontWeight='bold'>
					Total number of Rehab Service Visits during this duration:
				</Typography>
				<Typography data-testid='review-service-visits'>
					{stringtoNAIfNullOrEmpty(
						authFormData.totalRehabServiceVisits
					)}
				</Typography>
			</Box>
			{[
				Specialty.PTPractitioner,
				Specialty.OTPractitioner,
				Specialty.SLPPractitioner,
				Specialty.AthleticTrainer,
			].includes(selectionData.practitioner.specialtyCode) && (
				<Box>
					<Typography fontWeight='bold'>
						EMG/NCV/FCE/Other Studies by CPT Code(s):
					</Typography>
					<Stack direction='row' gap={3}>
						<Stack direction='row' gap={1}>
							<Typography fontWeight='bold'>1.</Typography>
							<Typography data-testid='review-cpt-1'>
								{stringtoNAIfNullOrEmpty(authFormData.cptCode1)}
							</Typography>
						</Stack>
						<Stack direction='row' gap={1}>
							<Typography fontWeight='bold'>2.</Typography>
							<Typography data-testid='review-cpt-2'>
								{stringtoNAIfNullOrEmpty(authFormData.cptCode2)}
							</Typography>
						</Stack>
						<Stack direction='row' gap={1}>
							<Typography fontWeight='bold'>3.</Typography>
							<Typography data-testid='review-cpt-3'>
								{stringtoNAIfNullOrEmpty(authFormData.cptCode3)}
							</Typography>
						</Stack>
						<Stack direction='row' gap={1}>
							<Typography fontWeight='bold'>4.</Typography>
							<Typography data-testid='review-cpt-4'>
								{stringtoNAIfNullOrEmpty(authFormData.cptCode4)}
							</Typography>
						</Stack>
					</Stack>
				</Box>
			)}
			{[
				Specialty.PTPractitioner,
				Specialty.OTPractitioner,
				Specialty.SLPPractitioner,
				Specialty.AthleticTrainer,
			].includes(selectionData.practitioner.specialtyCode) && (
				<Box>
					<Typography fontWeight='bold'>
						Durable Medical Equipment by HCPCS Code(s):
					</Typography>
					<Stack direction='row' gap={3}>
						<Stack direction='row' gap={1}>
							<Typography fontWeight='bold'>1.</Typography>
							<Typography data-testid='review-hcpcs-1'>
								{stringtoNAIfNullOrEmpty(
									authFormData.hcpcsCode1
								)}
							</Typography>
						</Stack>
						<Stack direction='row' gap={1}>
							<Typography fontWeight='bold'>2.</Typography>
							<Typography data-testid='review-hcpcs-2'>
								{stringtoNAIfNullOrEmpty(
									authFormData.hcpcsCode2
								)}
							</Typography>
						</Stack>
						<Stack direction='row' gap={1}>
							<Typography fontWeight='bold'>3.</Typography>
							<Typography data-testid='review-hcpcs-3'>
								{stringtoNAIfNullOrEmpty(
									authFormData.hcpcsCode3
								)}
							</Typography>
						</Stack>
						<Stack direction='row' gap={1}>
							<Typography fontWeight='bold'>4.</Typography>
							<Typography data-testid='review-hcpcs-4'>
								{stringtoNAIfNullOrEmpty(
									authFormData.hcpcsCode4
								)}
							</Typography>
						</Stack>
					</Stack>
				</Box>
			)}
			<Box>
				<Typography fontWeight='bold'>
					Additional/Updated Clinical Information not included in
					attached records:
				</Typography>
				<Typography data-testid='review-additional-info'>
					{stringtoNAIfNullOrEmpty(
						authFormData.additionalClinicalInformation
					)}
				</Typography>
			</Box>
			<Box>
				<Typography fontWeight='bold'>
					If you are treating this member for an Autism Spectrum
					Disorder (ASD), please attest to the following by checking
					the box below:
				</Typography>
				<FormControlLabel
					control={
						<Checkbox
							data-testid='review-autism-checkbox'
							checked={
								authFormData.treatingforAnAutismSpectrumDisorder
							}
							disabled
						/>
					}
					name='treatingforAnAutismSpectrumDisorder'
					label='I am following state-specific rules and regulations of the state mandate of Autism Spectrum Disorder (ASD).'
				/>
			</Box>
			<Box>
				<Typography fontWeight='bold'>
					Attach Medical Records
				</Typography>
				<Stack direction='row' gap={1}>
					<Typography>
						<PictureAsPdfOutlinedIcon />
					</Typography>
					<Typography data-testid='review-file-name'>
						{stringtoNAIfNullOrEmpty(
							authFormData.medicalRecordsFile?.name
						)}
					</Typography>
				</Stack>
			</Box>
		</Stack>
	);
};

MedicalRecordsTreatmentServicesReview.propTypes = {};

export default MedicalRecordsTreatmentServicesReview;
