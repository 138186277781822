import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ConditionAndTreatmentCodeSearch from './ConditionAndTreatmentCodeSearch';
import { GetServiceCode } from '../../../../../../../data/ihisApiCalls';

const ConditionAndTreatmentCodeTextField = ({
	codeType,
	isRequired,
	labelText,
	textFieldId,
	textFieldName,
	value,
	onTextFieldChange,
	onValidation,
	onBlur,
	error,
	helperText,
}) => {
	const [showSearch, setShowSearch] = useState(false);

	const [codeDescription, setCodeDescription] = useState('');
	const [searchText, setSearchText] = useState('');

	const { isLoading, isError, data } = useQuery(
		['GetServiceCode', codeType, searchText],
		() => GetServiceCode(codeType, searchText),
		{
			staleTime: Infinity,
			cacheTime: Infinity,
			refetchOnWindowFocus: false,
			enabled: searchText !== '',
		}
	);

	const {
		data: { data: { description, isValid, isCorrectCategory } = {} } = {},
	} = data ?? {};

	useEffect(() => {
		if (searchText === '') {
			setCodeDescription('');
			onValidation(null);
			return;
		}
		if (isLoading) {
			// An API call is pending
			setCodeDescription('');
			return;
		}
		if (isError) {
			// An API returned an error
			setCodeDescription('');
			onValidation('Something went wrong');
			return;
		}

		if (!isValid) {
			// The code not valid
			setCodeDescription('');
			onValidation(`Enter a valid ${codeType} code`);
			return;
		}

		if (['CPT', 'HCPCS'].includes(codeType) && !isCorrectCategory) {
			// The cpt or hcpcs code does not belong in this section
			setCodeDescription('');
			onValidation(`Wrong ${codeType} category for this section`);
			return;
		}

		// The code is valid
		setCodeDescription(description);
		onValidation(null);
	}, [searchText, isLoading, data]);

	const handleTextFieldBlur = event => {
		setSearchText(event.target.value);
		onBlur();
	};

	return (
		<>
			<Stack>
				<Stack direction='row' alignItems='center' spacing={2}>
					<InputLabel
						htmlFor={textFieldId}
						sx={{
							fontWeight: 'bold',
							color: 'text.primary',
						}}
					>
						{isRequired && (
							<>
								<Typography color='error' component='span'>
									*
								</Typography>{' '}
							</>
						)}
						{labelText}
					</InputLabel>
					<Stack direction='row' alignItems='center'>
						<TextField
							id={textFieldId}
							name={textFieldName}
							value={value}
							onChange={e => onTextFieldChange(e.target.value)}
							onBlur={e => handleTextFieldBlur(e)}
							error={error}
							helperText={helperText}
							inputProps={{
								maxLength: 10,
							}}
							sx={{ width: 175 }}
						/>
						<Button onClick={() => setShowSearch(true)}>
							{codeType}
						</Button>
					</Stack>
				</Stack>
				{isLoading ? (
					<Skeleton width={275} />
				) : (
					<Typography noWrap maxWidth={275}>
						{codeDescription}
					</Typography>
				)}
			</Stack>
			<Dialog
				open={showSearch}
				scroll='paper'
				aria-labelledby='code-search-dialog-title'
			>
				<DialogContent sx={{ width: 600 }}>
					<ConditionAndTreatmentCodeSearch
						codeType={codeType}
						onCodeSelected={(selectedCode, selectedDescription) => {
							setCodeDescription(selectedDescription);
							onTextFieldChange(selectedCode);
							setSearchText(selectedCode);
							onBlur();
							setShowSearch(false);
						}}
						onCancelClick={() => setShowSearch(false)}
						titleId='code-search-dialog-title'
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};

ConditionAndTreatmentCodeTextField.propTypes = {
	labelText: PropTypes.string.isRequired,
	isRequired: PropTypes.bool,
	codeType: PropTypes.oneOf(['Dx', 'CPT', 'HCPCS']).isRequired,
	textFieldId: PropTypes.string.isRequired,
	textFieldName: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onTextFieldChange: PropTypes.func.isRequired,
	onValidation: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired,
	error: PropTypes.bool,
	helperText: PropTypes.string,
};

ConditionAndTreatmentCodeTextField.defaultProps = {
	isRequired: false,
	error: false,
	helperText: '',
};

export default ConditionAndTreatmentCodeTextField;
