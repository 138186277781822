import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const SelectSubmitHealthStatus = ({ onSelect }) => (
	<Box>
		<Typography fontWeight='bold'>
			Do you want to submit a Health Status Form?
		</Typography>
		<RadioGroup
			data-testid='select-submit-health-status'
			onChange={(e, v) => {
				onSelect(v);
			}}
		>
			<FormControlLabel value='True' control={<Radio />} label='Yes' />
			<FormControlLabel value='False' control={<Radio />} label='No' />
		</RadioGroup>
	</Box>
);

SelectSubmitHealthStatus.propTypes = {
	onSelect: PropTypes.func.isRequired,
};

export default SelectSubmitHealthStatus;
