import { useQuery } from 'react-query';
import { GetSessionContext } from '../apiCalls';
import { QueryKey } from './QueryKeys';

// Query for the session info.
const useSessionContext = () => {
	const { isLoading, isError, data, error } = useQuery(
		[QueryKey.GET_SESSION_CONTEXT],
		GetSessionContext,
		{ refetchOnWindowFocus: false }
	);

	const isLoggedIn = data?.resultCode === 0 && !!data?.data?.ashlinkUserId;

	const currentPermissions = data?.data?.permissions || [];
	const userInfoData = data?.data?.userInfo || {};
	const drFedId = data?.data?.drFedId || undefined;
	const currentLinks = data?.data?.ashlinkUserRedirections || [];
	const providerNetCode = data?.data?.providerNetCode || undefined;
	const practitionerId = data?.data?.practitionerId || undefined;
	const providerId = data?.data?.providerId || undefined;
	const providerSpecialtyCode =
		data?.data?.providerSpecialtyCode || undefined;
	const practitionerSpecialtyCode =
		data?.data?.practitionerSpecialtyCode || undefined;

	return {
		isLoading,
		isError,
		error,
		isLoggedIn,
		permissions: currentPermissions,
		redirectionLinks: currentLinks,
		userInfo: userInfoData,
		drFedId,
		providerNetCode,
		providerSpecialtyCode,
		practitionerId,
		practitionerSpecialtyCode,
		providerId,
	};
};

export default useSessionContext;
