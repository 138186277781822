import { setupWorker } from 'msw';
import { fileBySpecialtyAndRegionSuccess } from './ContentManagement/Clinical/PostLogin/FileBySpecialtyAndRegion';
import { FAQBySpecialty } from './ASHLinkNextGateway/FAQBySpecialty';
import { handlers } from './handlers';
import { valueAddedVendorBySpecialty } from './ASHLinkNextGateway/ValueAddedVendorBySpecialty';
import { getCustomSpecialtySessionContextSuccess } from './Auth/SessionContext';
import { clinicalResources } from './ASHLinkNextGateway/ClinicalResource';
import { compliance } from './ContentManagement/Clinical/PostLogin/Compliance';
import { clinicalGeneralResources } from './ASHLinkNextGateway/ClinicalGeneralResource';
import { clinicalPELResources } from './ASHLinkNextGateway/ClinicalPELResource';
import { getStatus } from './Auth/SessionStatus';
import { complianceStatus } from './Confirmation/ComplianceStatus';
import { sessionDataSuccess } from './Legacy/SessionData';
import {
	GenerateRandomPracitioners,
	GetPractitionersSuccess,
	GetServiceLocationsSuccessRandom,
} from './Forms/Auth/SelectAuth';
import {
	PutAuthManagementAuthFormSuccess,
	PostAuthManagementCreateAuthSuccess,
	PostAuthManagementCreateAuthFail,
} from './ASHLinkNextGateway/AuthManagement';
import {
	GetServiceCodeSuccess,
	GetServiceCodesSuccess,
} from './ASHLinkNextGateway/ServiceCodes';

// This configures a Service Worker with the given request handlers.
export const worker = setupWorker(
	...handlers,
	...fileBySpecialtyAndRegionSuccess,
	...clinicalResources,
	...clinicalGeneralResources,
	...clinicalPELResources,
	...compliance,
	...complianceStatus,
	...valueAddedVendorBySpecialty,
	...FAQBySpecialty,
	...getCustomSpecialtySessionContextSuccess(26, 0, 44),
	...getStatus,
	...sessionDataSuccess,
	...GetPractitionersSuccess(GenerateRandomPracitioners(20)),
	...GetServiceLocationsSuccessRandom(20),
	...PutAuthManagementAuthFormSuccess,
	...PostAuthManagementCreateAuthSuccess,
	...PostAuthManagementCreateAuthFail,
	...GetServiceCodeSuccess,
	...GetServiceCodesSuccess
);
