import { rest } from 'msw';

export const pages = {
	fail: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 32,
		subResultText: 'ContentManagementApiFail',
		errors: [
			{
				message:
					'Expected exception. (TransactionId: 1b62f160-92ba-4999-b3f1-e2a45c0d99af)',
			},
		],
		date: '2023-02-22T05:16:50.4312862Z',
	},
	result: {
		resultCode: 0,
		resultText: 'Success',
		data: {
			entries: [
				{
					id: '1',
					questionOrderNumber: '2',
					question:
						'What is the airspeed velocity of an unladen swallow?',
					answer: 'What do you mean? An African or European swallow?',
					category: 'Second Category',
					categoryOrderNumber: '2',
				},
				{
					id: '2',
					questionOrderNumber: '3',
					question:
						'What about ASHLink website links? Where do they go?',
					answer: 'They should navigate to the requested page, but in the current browser tab.  \n[Click here to find out!](https://www.ashlink.com)',
					category: 'First Category',
					categoryOrderNumber: '1',
				},
				{
					id: '3',
					questionOrderNumber: '4',
					question: 'How many questions do you need?',
					answer: 'Questions are key to testing and learning. Without questions would there be answers?',
					category: 'Second Category',
					categoryOrderNumber: '2',
				},
				{
					id: '4',
					questionOrderNumber: '5',
					question: 'Is this the final question?',
					answer: 'Of this category? Yes. Of this page? No! Please keep enjoying our wonderful test content!',
					category: 'First Category',
					categoryOrderNumber: '1',
				},
				{
					id: '5',
					questionOrderNumber: '4',
					question: 'What about external links? Where do they go?',
					answer: 'They should navigate to the requested page, but in a new browser tab.  \n[Click here to find out!](https://www.google.com)',
					category: 'First Category',
					categoryOrderNumber: '1',
				},
				{
					id: '6',
					questionOrderNumber: '2',
					question: 'Does the FAQ page support markdown content?',
					answer: "It better! But let's find out, shall we?  \nIs this content on a different line? How about some _fun_ **text**?  \nHow about some lists?  \n- One\n- Two\n- Three  \n1. A\n2. B\n3. C",
					category: 'First Category',
					categoryOrderNumber: '1',
				},
				{
					id: '7',
					questionOrderNumber: '1',
					question: 'Or is this the first question?',
					answer: 'This is the first question, but for the second category.',
					category: 'Second Category',
					categoryOrderNumber: '2',
				},
				{
					id: '8',
					questionOrderNumber: '1',
					question: 'Is this the first question?',
					answer: 'This is the first question and answer. This question and answer is located within the first category at the top of the page.',
					category: 'First Category',
					categoryOrderNumber: '1',
				},
				{
					id: '9',
					questionOrderNumber: '3',
					question:
						'What happens when two items have the same order number?',
					answer: 'They simply display in whatever order they are in within the array.',
					category: 'Second Category',
					categoryOrderNumber: '2',
				},
				{
					id: '10',
					questionOrderNumber: '3',
					question: 'Why did you change the test data?',
					answer: 'It was changed in an attempt to make it appear more realistic and to better differentiate between content and context of the content.',
					category: 'Second Category',
					categoryOrderNumber: '2',
				},
			],
		},
		date: '2023-02-15T23:25:39.313711Z',
	},
	notFound: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 9001,
		subResultText: 'ContentNotFound',
		errors: [
			{
				message: 'No content returned from Contentful.',
			},
		],
		date: '2023-02-22T05:36:28.3714538Z',
	},
};

export const FAQBySpecialtySuccess = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/FAQBySpecialty',
		(req, res, ctx) => res(ctx.status(200), ctx.json(pages.result))
	),
];

export const FAQBySpecialtyLoading = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/FAQBySpecialty',
		(req, res, ctx) => res(ctx.delay('infinite'))
	),
];

export const FAQBySpecialtyFailWithSubResultCode32 = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/FAQBySpecialty',
		(req, res, ctx) => res(ctx.status(200), ctx.json(pages.fail))
	),
];

export const FAQBySpecialtyNoResults = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/FAQBySpecialty',
		(req, res, ctx) => res(ctx.status(200), ctx.json(pages.notFound))
	),
];

export const FAQBySpecialtyFail = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/FAQBySpecialty',
		(req, res, ctx) => res(ctx.status(500), ctx.json(pages.fail))
	),
];

export const FAQBySpecialty = [
	...FAQBySpecialtySuccess,
	...FAQBySpecialtyLoading,
	...FAQBySpecialtyNoResults,
	...FAQBySpecialtyFail,
	...FAQBySpecialtyFailWithSubResultCode32,
];
