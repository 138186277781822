import restDataService from './restDataService';

const promisApiBasePath = 'api/ashlinknextapigateway/Provider';

export const getPractitioners = async (providerId, practitionerId) =>
	// https://dev.azure.com/ashcompanies/VIP/_workitems/edit/389010
	restDataService.get(`${promisApiBasePath}/Practitioners`, {
		params: {
			providerId,
			practitionerId,
		},
	});

export const getServiceLocations = async (providerId, practitionerId) =>
	// https://dev.azure.com/ashcompanies/VIP/_workitems/edit/390003
	restDataService.get(`${promisApiBasePath}/ServiceLocations`, {
		params: {
			providerId,
			practitionerId,
		},
	});
