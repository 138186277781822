import { rest } from 'msw';

export const results = {
	fail: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 32,
		subResultText: 'ContentManagementApiFail',
		errors: [
			{
				message:
					'Expected exception. (TransactionId: 1b62f160-92ba-4999-b3f1-e2a45c0d99af)',
			},
		],
		date: '2023-02-22T05:16:50.4312862Z',
	},
	success: {
		resultCode: 0,
		resultText: 'Success',
		data: {
			compliancePage: {
				pageSummary:
					'__American Specialty Health (ASH)__ contracts with Medicare Advantage and Medicaid Managed Care plans to provide benefits to their members. Because these programs are paid for with federal and/or state tax dollars there are specific compliance requirements that the Medicare Advantage and/or Medicaid Managed Care plans require ASH and its contracted practitioners meet.\n',
				requirements: [
					{
						id: '6VVQGff27IiCuz2SnOLwMC',
						title: 'General Compliance and Fraud, Waste, and Abuse Training',
						number: '1',
						summary:
							'Required annually and within 30 days of hire for owners, managers, and key employees',
						links: [
							{
								id: 'pQfgDCkLQoynEzERiXHgc',
								prefix: 'Review',
								text: 'General Compliance And Fraud, Waste, and Abuse Training',
								fileUrl:
									'//assets.ctfassets.net/1g6by23er3py/77yk7wQqQFEQAWnKFwE0Jk/e25487e04e2283c1ef65999f4516c774/M965-227A_Active___Fit_Stay_Fit_Kit_Flier.pdf',
								attestationLinkType: 'General Compliance',
							},
							{
								id: '6GYGwU9SQKnPdRxp5QmI67',
								prefix: 'Complete',
								text: 'Attestation',
								url: 'https://tst.ashlink.com/ASH/MedicareCompliance/FraudWasteAttestation.aspx',
								tooltip:
									'You, as a contracted practitioner, must run all employees that provide health or administrative services to Medicare and/or Medicaid beneficiaries through the List of Excluded Individuals/Entities (“LEIE”) maintained by the Office of the Inspector General (OIG), the System for Award Management (“SAM”) maintained by the General Services Administration (GSA) and any state published Medicaid exclusion lists (collectively “Exclusions Lists”) prior to hire or contracting and monthly thereafter. For more information on how to access the Exclusion Lists mentioned above, please refer to the Exclusion Checks Guide. If you hire an individual or entity on any of the Exclusion Lists, you may be subjecting ASH and its contracted Medicare Advantage or Medicaid Plan clients to civil monetary penalties (CMP). As such, it is important to run these checks prior to hiring or contracting with a new individual. It is also important to run the subsequent monthly checks in a timely manner. If you find an employee on any of the Exclusion Lists while running a check, please notify ASH immediately via ASH’s Anti-Fraud Hotline at 1.877.427.4722.',
								attestationLinkType: 'Fraud, Waste, Abuse',
							},
							{
								id: '8IPo8TCemFM2v02HZRpJGI',
								prefix: 'Complete',
								text: 'Attestation1',
								url: 'https://tst.ashlink.com/ASH/MedicareCompliance/FraudWasteAttestation.aspx',
								tooltip:
									'You, as a contracted practitioner, must use this mock.',
								attestationLinkType: 'Fraud, Waste, Abuse',
							},
						],
					},
					{
						id: '2pHyGSoP8Jxkr3XT9tws5V',
						title: 'Exclusion from Medicare and Medicaid Checks',
						number: '2',
						summary:
							'Required for owners and managers monthly and porior ro hire of new key employees',
						links: [
							{
								id: '6ctkWq3ENQe7eltnMBItNT',
								prefix: 'Review',
								text: 'Exclusion Checks Guide',
								fileUrl:
									'//images.ctfassets.net/1g6by23er3py/6BfApPhi2aCDGziZuVlWCX/cfad3a933da3f7f63a916357b88ff212/new-beginner-yoga-mat.jpg',
								attestationLinkType: 'General Compliance',
							},
							{
								id: '2CZt7Rs56mJ10TIix2xcPq',
								prefix: 'Complete',
								text: 'Exclusion from Government Program Checks',
								fileUrl:
									'//images.ctfassets.net/1g6by23er3py/4kYWiaMRyfqQhDhAIGt4DS/cfadc3ca5a3f0c6051c86ac9dc1f5f69/swimkit-beginner.jpg',
								attestationLinkType: 'General Compliance',
							},
						],
					},
					{
						id: '5MfHPhL6xMoQjJEQRiODH4',
						title: 'ASH Policies',
						number: '3',
						summary:
							'Required annually and within 30 days of hire for owners, managers, and key employees',
						links: [
							{
								id: '3pfSLaiCEqWoKgA5FP2Dbw',
								prefix: 'Review',
								text: 'Code of Conduct & Ethics Program',
								fileUrl:
									'//assets.ctfassets.net/1g6by23er3py/45CINkj0FXUfp3UONpXn5g/938605342a53a3db3c77d7e9ac516289/M950-1190A_Stay_Fit_Kit_Info_Flier.pdf',
								attestationLinkType: 'none',
							},
							{
								id: 'nSGxNMUM1RUmzXWKLnWH7',
								prefix: '',
								text: 'Compliance Issue Reporting Form',
								fileUrl:
									'//assets.ctfassets.net/1g6by23er3py/45CINkj0FXUfp3UONpXn5g/938605342a53a3db3c77d7e9ac516289/M950-1190A_Stay_Fit_Kit_Info_Flier.pdf',
								attestationLinkType: 'none',
							},
						],
					},
					{
						id: 'uQIUO8f2X4y9X1rjAN9Gd',
						title: 'Consolidated Attestation',
						number: '4',
						summary: 'Required annually for owners and managers',
						links: [
							{
								id: '3I08yiP0Cd0hd7opCs21Ij',
								prefix: 'Print & Sign',
								text: 'Consolidated Attestation',
								fileUrl:
									'//assets.ctfassets.net/1g6by23er3py/45CINkj0FXUfp3UONpXn5g/938605342a53a3db3c77d7e9ac516289/M950-1190A_Stay_Fit_Kit_Info_Flier.pdf',
								tooltip:
									'Annually, all contracted practitioners are required to complete attestations regarding the following compliance areas: General Compliance and Fraud, Waste and Abuse Training, Exclusion from Medicare and Medicaid Checks, Code of Conduct and Ethics Program (inclusive of conflict of interest issues) and Record Retention requirements. ASH has provided a consolidated attestation for all areas of compliance. Please complete this attestation and save it in your records as ASH may request a copy of the attestation at any time.',
								attestationLinkType: 'none',
							},
						],
					},
					{
						id: 'j4BhMFeWrk3cAa8HEie8p',
						title: 'Retain Records',
						number: '5',
						summary: '   ',
						links: [
							{
								id: '3Yp2IzFCMF74LZ9sYYAjjS',
								prefix: 'You are required to maintain a copy of the Employee Roster for General Compliance and Fraud, Waste & Abuse Training, Exclusion from Medicare & Medicaid Checks Monthly Log and Roster, and the signed Consolidated Attestation for a period of 10 years.',
								text: 'Retain Records',
								attestationLinkType: 'none',
							},
						],
					},
				],
				timelines: [
					{
						id: '30HDqq0Y4Axcsspp6IaGrC',
						title: 'Owner/Manager Requirements',
						milestones: [
							{
								id: '6FpYeQBEmW61rLc4zjIj0R',
								title: 'Prior to Hire of New Employee',
								requirementNumbers: ['2'],
							},
							{
								id: '7yYjgoeJn3e6D3DUh3c6KH',
								title: 'Annually',
								requirementNumbers: ['1', '3', '4'],
							},
							{
								id: '70sp5SXaO1JQ001ljekaAa',
								title: 'Monthly',
								requirementNumbers: ['2'],
							},
						],
					},
					{
						id: '1w7jZllDOy4PoxyiS7XGcU',
						title: 'All Key Employee Requirements',
						milestones: [
							{
								id: '51EARWcVCXPz5pMJjJu9tG',
								title: 'Within 30 days of hire',
								requirementNumbers: ['1', '3'],
							},
							{
								id: '772JvQk7Vdcnx30oZN1A2q',
								title: 'Annually',
								requirementNumbers: ['1', '3'],
							},
						],
					},
				],
			},
		},
		date: '2023-02-15T23:25:39.313711Z',
	},
	notFound: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 9001,
		subResultText: 'ContentNotFound',
		errors: [
			{
				message: 'No content returned from Contentful.',
			},
		],
		date: '2023-02-22T05:36:28.3714538Z',
	},
};

export const complianceSuccess = [
	rest.get(
		'/clinical/api/ContentManagement/Clinical/PostLogin/Compliance',
		(req, res, ctx) => res(ctx.status(200), ctx.json(results.success))
	),
];

export const complianceLoading = [
	rest.get(
		'/clinical/api/ContentManagement/Clinical/PostLogin/Compliance',
		(req, res, ctx) => res(ctx.delay('infinite'))
	),
];

export const complianceFail = [
	rest.get(
		'/clinical/api/ContentManagement/Clinical/PostLogin/Compliance',
		(req, res, ctx) => res(ctx.status(500), ctx.json(results.fail))
	),
];

export const compliance = [
	...complianceSuccess,
	...complianceLoading,
	...complianceFail,
];
