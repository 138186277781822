import Typography from '@mui/material/Typography';
import ClinicalFileType from '../../scripts/clinicalFileType';
import ResourceTablePage from './shared/ResourceTablePage';

const clinicalFileType =
	ClinicalFileType.ashlinkClinicalNotificationResource.value;

const Notifications = () => {
	const pageTitle = 'Notifications';
	const pageSummary = (
		<Typography fontWeight='fontWeightBold' mb={2}>
			Notifications are announcements or bulletins sent to ASH Contracted
			Practitioners containing important updates and/or revisions specific
			to your specialty.
		</Typography>
	);
	const resourceColumnTitle = 'Topic';

	return (
		<ResourceTablePage
			pageTitle={pageTitle}
			resourceColumnTitle={resourceColumnTitle}
			pageSummary={pageSummary}
			clinicalFileType={clinicalFileType}
		/>
	);
};

export default Notifications;
