import { rest } from 'msw';

export const pages = {
	fail: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 32,
		subResultText: 'ContentManagementApiFail',
		errors: [
			{
				message:
					'Expected exception. (TransactionId: 1b62f160-92ba-4999-b3f1-e2a45c0d99af)',
			},
		],
		date: '2023-02-22T05:16:50.4312862Z',
	},
	page1: {
		resultCode: 0,
		resultText: 'Success',
		data: {
			result: {
				totalPage: 2,
				currentPage: 1,
				entries: [
					{
						id: '3mDoe2VVXU6YbzUpEugUCe',
						displayName:
							'all regions and cal 2 rehab both distinctions',
						effectiveDate: '2023-01-01',
						releaseDate: '2023-01-01',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__1_.png',
					},
					{
						id: '3mDoe2VVXU6YbzUpEugUCf',
						displayName: 'all and cal 2',
						effectiveDate: '2023-02-01',
						releaseDate: '2023-01-01',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__2_.png',
					},
					{
						id: '3mDoe2VVXU6YbzUpEugUCg',
						displayName: 'all and cal 2',
						effectiveDate: '2023-03-01',
						releaseDate: '2023-01-01',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__3_.png',
					},
					{
						id: '3mDoe2VVXU6YbzUpEugUCh',
						displayName: 'all and cal 4',
						effectiveDate: '2023-04-09',
						releaseDate: '2023-01-01',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__4_.png',
					},
					{
						id: '3mDoe2VVXU6YbzUpEugUCi',
						displayName: 'all and cal 5',
						effectiveDate: '2023-05-09',
						releaseDate: '2023-01-01',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__5_.png',
					},
					{
						id: '3mDoe2VVXU6YbzUpEugUCj',
						displayName: 'all and cal 6',
						effectiveDate: '2023-06-09',
						releaseDate: '2023-01-01',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__6_.png',
					},
					{
						id: '3mDoe2VVXU6YbzUpEugUCk',
						displayName: 'all and cal 7',
						effectiveDate: '2023-07-09',
						releaseDate: '2023-01-01',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__7_.png',
					},
					{
						id: '3mDoe2VVXU6YbzUpEugUCl',
						displayName: 'all and cal 8',
						effectiveDate: '2023-08-09',
						releaseDate: '2023-01-01',
						agreementType: 'Virtual Health',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__8_.png',
					},
					{
						id: '3mDoe2VVXU6YbzUpEugUCm',
						displayName: 'all and cal 9',
						effectiveDate: '2023-09-09',
						releaseDate: '2023-01-01',
						agreementType: 'Virtual Health / Virtual Services',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__9_.png',
					},
					{
						id: '3mDoe2VVXU6YbzUpEugUCn',
						displayName: 'all and cal 10',
						effectiveDate: '2023-10-09',
						releaseDate: '2023-01-01',
						agreementType: 'Virtual Services',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__10_.png',
					},
					{
						id: '3mDoe2VVXU6YbzUpEugUCo',
						displayName: 'resource from page 2',
						effectiveDate: '2023-02-09',
						releaseDate: '2023-01-01',
						agreementType:
							'Virtual Health / Virtual Services / Virtually a Really Long Line Here Placeholder Text It Will Never Be This Long But Just In Case',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__6_.png',
					},
					{
						id: '3mDoe2VVXU6YbzUpEugUCo2',
						displayName: 'resource from page 3',
						effectiveDate: '2023-02-09',
						releaseDate: '2023-01-01',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__6_.png',
					},
					{
						id: '3mDoe2VVXU6YbzUpEugUCo3',
						displayName: 'resource from page 4',
						effectiveDate: '2023-02-09',
						releaseDate: '2023-01-01',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__6_.png',
					},
					{
						id: '3mDoe2VVXU6YbzUpEugUCo4',
						displayName: 'resource from page 5',
						effectiveDate: '2023-02-09',
						releaseDate: '2023-01-01',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__6_.png',
					},
				],
			},
		},
		date: '2023-02-15T23:25:39.313711Z',
	},
	page2: {
		resultCode: 0,
		resultText: 'Success',
		data: {
			result: {
				totalPage: 2,
				currentPage: 2,
				entries: [
					{
						id: '3mDoe2VVXU6YbzUpEugUCo',
						displayName: 'resource from page 2',
						effectiveDate: '2023-02-09',
						releaseDate: '2023-01-01',
						url: '//images.ctfassets.net/1g6by23er3py/4JtEFjTyxvwfkasPdpYCpN/25039d6c32bfa16f33119b3b34df899b/GettyImages-1314231734__6_.png',
					},
				],
			},
		},
		date: '2023-02-15T23:25:39.313711Z',
	},
	notFound: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 9001,
		subResultText: 'ContentNotFound',
		errors: [
			{
				message: 'No content returned from Contentful.',
			},
		],
		date: '2023-02-22T05:36:28.3714538Z',
	},
};

export const clinicalResourcesSuccess = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalResources',
		(req, res, ctx) => {
			const page = req.url.searchParams.get('Page');
			return res(
				ctx.status(200),
				ctx.json(page === '1' ? pages.page1 : pages.page2)
			);
		}
	),
];

export const clinicalResourcesLoading = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalResources',
		(req, res, ctx) => res(ctx.delay('infinite'))
	),
];

export const clinicalResourcesFailWithSubResultCode32 = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalResources',
		(req, res, ctx) => res(ctx.status(200), ctx.json(pages.fail))
	),
];

export const clinicalResourcesNoResults = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalResources',
		(req, res, ctx) => res(ctx.status(200), ctx.json(pages.notFound))
	),
];

export const clinicalResourcesFail = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalResources',
		(req, res, ctx) => res(ctx.status(500), ctx.json(pages.fail))
	),
];

export const clinicalResources = [
	...clinicalResourcesSuccess,
	...clinicalResourcesLoading,
	...clinicalResourcesNoResults,
	...clinicalResourcesFail,
	...clinicalResourcesFailWithSubResultCode32,
];
