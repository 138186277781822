import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MedicalRecordsTreatmentServicesSection from './TreatmentServicesSection/MedicalRecordsTreatmentServicesSection';

const MedicalRecordsForm = ({ onFinalContinueClick }) => {
	const [
		treatmentServicesSectionExpanded,
		setTreatmentServicesSectionExpanded,
	] = useState(true);
	const handleAccordionChange = () => {
		setTreatmentServicesSectionExpanded(!treatmentServicesSectionExpanded);
	};
	return (
		<Paper elevation={0}>
			<Stack>
				<MedicalRecordsTreatmentServicesSection
					expanded={treatmentServicesSectionExpanded}
					onAccordionChange={handleAccordionChange}
					onContinueClick={onFinalContinueClick}
				/>
			</Stack>
		</Paper>
	);
};

MedicalRecordsForm.propTypes = {
	onFinalContinueClick: PropTypes.func.isRequired,
};

export default MedicalRecordsForm;
