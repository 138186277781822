import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useContext, useRef } from 'react';
import * as Yup from 'yup';
import {
	GetEvaluationCodeList,
	GetReEvaluationCodeList,
} from '../../../../../../../../../scripts/medicalRecordsTreatmentServicesEvaluationCodeHelper';
import { NetCodes } from '../../../../../../../../../scripts/netCode';
import Specialty from '../../../../../../../../../scripts/specialty';
import { AuthSubmissionContext } from '../../../../AuthSubmissionContext';
import ConditionAndTreatmentCodeTextField from '../../ConditionAndTreatmentCodeTextField';

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

const MedicalRecordsTreatmentServicesSection = ({
	expanded,
	onAccordionChange,
	onContinueClick,
}) => {
	const {
		selectionData,
		sessionProviderSpecialtyCode,
		sessionProviderNetCode,
		authFormData,
		setAuthFormData,
	} = useContext(AuthSubmissionContext);

	const fileRef = useRef(null);

	const resetUploadFileInput = () => {
		if (fileRef.current) {
			fileRef.current.value = '';
		}
	};

	const handleContinueClick = formValues => {
		setAuthFormData({ ...authFormData, ...formValues });
		onContinueClick();
	};

	const evaluationCodes = GetEvaluationCodeList(
		selectionData.practitioner?.specialtyCode ??
			sessionProviderSpecialtyCode
	);
	const reevaluationCodes = GetReEvaluationCodeList(
		selectionData.practitioner?.specialtyCode ??
			sessionProviderSpecialtyCode
	);

	const getFormValues = () => {
		if (authFormData) {
			return authFormData;
		}

		return {
			servicesForHabilitativeCare: null,
			initialStartOfCareDate: null,
			dateOfFindingsAttached: null,
			numberOfOfficeVisits: '',
			dxCode1: '',
			dxCode2: '',
			dxCode3: '',
			dxCode4: '',
			dxCode1InvalidMessage: null,
			dxCode2InvalidMessage: null,
			dxCode3InvalidMessage: null,
			dxCode4InvalidMessage: null,
			fromDateForCare: null,
			throughDateForCare: null,
			evaluationCode: '',
			reevaluationCode: '',
			totalRehabServiceVisits: '',
			cptCode1: '',
			cptCode2: '',
			cptCode3: '',
			cptCode4: '',
			cptCode1InvalidMessage: null,
			cptCode2InvalidMessage: null,
			cptCode3InvalidMessage: null,
			cptCode4InvalidMessage: null,
			hcpcsCode1: '',
			hcpcsCode2: '',
			hcpcsCode3: '',
			hcpcsCode4: '',
			hcpcsCode1InvalidMessage: null,
			hcpcsCode2InvalidMessage: null,
			hcpcsCode3InvalidMessage: null,
			hcpcsCode4InvalidMessage: null,
			additionalClinicalInformation: '',
			treatingforAnAutismSpectrumDisorder: false,
			medicalRecordsFile: null,
		};
	};

	const SUPPORTED_FORMATS = ['application/pdf'];
	const codeValidatorTest = (errorMessage, ctx) => {
		if (errorMessage) {
			return ctx.createError({ message: errorMessage });
		}
		return true;
	};

	// We can use the yup library to set up a model containing all of our form state.
	// This will be used to handle validation of our fields. See the yup documentation online for more configuration information.
	const validationSchema = Yup.object({
		servicesForHabilitativeCare: Yup.string().nullable(),
		initialStartOfCareDate: Yup.date()
			.typeError('Enter a valid date')
			.min(
				'01/01/1900',
				'Enter a valid year. It must be greater than 1900'
			),

		dateOfFindingsAttached: Yup.date()
			.typeError('Enter a valid date')
			.min(
				'01/01/2000',
				'Enter a valid year. It must be greater than 2000'
			),
		numberOfOfficeVisits: Yup.number()
			.typeError('Enter a value between 1-999')
			.min(1, 'Enter a value between 1-999')
			.max(999, 'Enter a value between 1-999')
			.integer('Enter a value between 1-999'),

		dxCode1: Yup.string()
			.required('Enter a valid Dx code')
			.test('dxCode1Valid', '', (value, ctx) =>
				codeValidatorTest(ctx.parent.dxCode1InvalidMessage, ctx)
			),
		dxCode2: Yup.string().test('dxCode2Valid', '', (value, ctx) =>
			codeValidatorTest(ctx.parent.dxCode2InvalidMessage, ctx)
		),
		dxCode3: Yup.string().test('dxCode3Valid', '', (value, ctx) =>
			codeValidatorTest(ctx.parent.dxCode3InvalidMessage, ctx)
		),
		dxCode4: Yup.string().test('dxCode4Valid', '', (value, ctx) =>
			codeValidatorTest(ctx.parent.dxCode4InvalidMessage, ctx)
		),
		fromDateForCare: Yup.date()
			.typeError('Enter a valid date')
			.min(
				'01/01/2000',
				'Enter a valid year. It must be greater than 2000'
			),
		throughDateForCare: Yup.date()
			.typeError('Enter a valid date')
			.min(
				'01/01/2000',
				'Enter a valid year. It must be greater than 2000'
			)
			.test({
				message: 'Date cannot be prior to the “From” date',
				test: (value, context) =>
					value >= context.parent.fromDateForCare,
			}),
		evaluationCode: Yup.string(),
		reevaluationCode: Yup.string(),
		totalRehabServiceVisits: Yup.number()
			.required('Enter a value between 1-99')
			.typeError('Enter a value between 1-99')
			.min(1, 'Enter a value between 1-99')
			.max(99, 'Enter a value between 1-99')
			.integer('Enter a value between 1-99')
			.required('Field is required'),
		cptCode1: Yup.string().test('cptCode1Valid', '', (value, ctx) =>
			codeValidatorTest(ctx.parent.cptCode1InvalidMessage, ctx)
		),
		cptCode2: Yup.string().test('cptCode2Valid', '', (value, ctx) =>
			codeValidatorTest(ctx.parent.cptCode2InvalidMessage, ctx)
		),
		cptCode3: Yup.string().test('cptCode3Valid', '', (value, ctx) =>
			codeValidatorTest(ctx.parent.cptCode3InvalidMessage, ctx)
		),
		cptCode4: Yup.string().test('cptCode4Valid', '', (value, ctx) =>
			codeValidatorTest(ctx.parent.cptCode4InvalidMessage, ctx)
		),
		hcpcsCode1: Yup.string().test('hcpcsCode1Valid', '', (value, ctx) =>
			codeValidatorTest(ctx.parent.hcpcsCode1InvalidMessage, ctx)
		),
		hcpcsCode2: Yup.string().test('hcpcsCode2Valid', '', (value, ctx) =>
			codeValidatorTest(ctx.parent.hcpcsCode2InvalidMessage, ctx)
		),
		hcpcsCode3: Yup.string().test('hcpcsCode3Valid', '', (value, ctx) =>
			codeValidatorTest(ctx.parent.hcpcsCode3InvalidMessage, ctx)
		),
		hcpcsCode4: Yup.string().test('hcpcsCode4Valid', '', (value, ctx) =>
			codeValidatorTest(ctx.parent.hcpcsCode4InvalidMessage, ctx)
		),
		additionalClinicalInformation: Yup.string().max(2500),
		treatingforAnAutismSpectrumDisorder: Yup.bool(),
		medicalRecordsFile: Yup.mixed()
			.nullable()
			.required('Please upload a PDF file')
			.test(
				'FILE_SIZE',
				'The maximum size for file uploads is 3MB',
				value => !value || (value && value.size <= 3000000)
			)
			.test(
				'FILE_FORMAT',
				'Invalid file type. Please upload a PDF file',
				value =>
					!value || (value && SUPPORTED_FORMATS.includes(value?.type))
			),
	});

	return (
		<Formik
			validateOnChange={false}
			initialValues={getFormValues()}
			validationSchema={validationSchema}
			onSubmit={values => {
				handleContinueClick(values);
			}}
		>
			{({
				values,
				touched,
				errors,
				setFieldValue,
				setFieldError,
				setFieldTouched,
				handleSubmit,
				handleChange,
				handleBlur,
			}) => (
				<Accordion expanded={expanded} onChange={onAccordionChange}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Stack
							direction='row'
							justifyContent='space-between'
							alignItems='center'
							width='100%'
						>
							<Typography variant='h6'>
								Treatment / Services Submitting for Review
							</Typography>
							<Stack
								direction='row'
								alignItems='center'
								spacing={1}
							>
								<Typography>(6) Required</Typography>
								<CheckCircleOutlineIcon />
							</Stack>
						</Stack>
					</AccordionSummary>
					<AccordionDetails>
						<Stack spacing={3}>
							<FormControl>
								<FormLabel
									id='habilitative-care-radio-buttons-group-label'
									sx={{
										fontWeight: 'bold',
										color: 'text.primary',
									}}
								>
									Are services for Habilitative care?
								</FormLabel>
								<RadioGroup
									aria-labelledby='habilitative-care-radio-buttons-group-label'
									name='habilitativeCareRadioButtonsGroup'
									row
									value={values.servicesForHabilitativeCare}
									onChange={event => {
										setFieldValue(
											'servicesForHabilitativeCare',
											event.currentTarget.value,
											true
										);
									}}
								>
									<FormControlLabel
										value='Yes'
										control={<Radio />}
										label='Yes'
									/>
									<FormControlLabel
										value='No'
										control={<Radio />}
										label='No'
									/>
								</RadioGroup>
							</FormControl>
							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								alignItems={{ xs: 'left', sm: 'center' }}
								spacing={2}
							>
								<InputLabel
									htmlFor='intial-start-of-care-date'
									sx={{
										fontWeight: 'bold',
										color: 'text.primary',
										textWrap: 'wrap',
									}}
								>
									<Typography color='error' component='span'>
										*
									</Typography>{' '}
									Initial Start of care for this condition:
								</InputLabel>
								<Box>
									<DatePicker
										value={values.initialStartOfCareDate}
										onChange={value => {
											setFieldValue(
												'initialStartOfCareDate',
												value,
												true
											);
										}}
										minDate={dayjs('01/01/1900')}
										slotProps={{
											textField: {
												error:
													touched.initialStartOfCareDate &&
													Boolean(
														errors.initialStartOfCareDate
													),
												helperText:
													touched.initialStartOfCareDate &&
													errors.initialStartOfCareDate,
												onBlur: handleBlur,
												id: 'intial-start-of-care-date',
												name: 'initialStartOfCareDate',
											},
										}}
									/>
								</Box>
							</Stack>
							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								alignItems={{ xs: 'left', sm: 'center' }}
								spacing={2}
							>
								<InputLabel
									htmlFor='date-of-findings-attached'
									sx={{
										fontWeight: 'bold',
										color: 'text.primary',
										textWrap: 'wrap',
									}}
								>
									<Typography color='error' component='span'>
										*
									</Typography>{' '}
									Date of Evaluation or Re-evaluation Findings
									Attached:
								</InputLabel>
								<Box>
									<DatePicker
										value={values.dateOfFindingsAttached}
										onChange={value => {
											setFieldValue(
												'dateOfFindingsAttached',
												value,
												true
											);
										}}
										minDate={dayjs('01/01/2000')}
										slotProps={{
											textField: {
												error:
													touched.dateOfFindingsAttached &&
													Boolean(
														errors.dateOfFindingsAttached
													),
												helperText:
													touched.dateOfFindingsAttached &&
													errors.dateOfFindingsAttached,
												onBlur: handleBlur,
												id: 'date-of-findings-attached',
												name: 'dateOfFindingsAttached',
											},
										}}
									/>
								</Box>
							</Stack>
							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								alignItems={{ xs: 'left', sm: 'center' }}
								spacing={2}
							>
								<InputLabel
									htmlFor='number-of-office-visits'
									sx={{
										fontWeight: 'bold',
										color: 'text.primary',
										textWrap: 'wrap',
									}}
								>
									Number of Office Visits already rendered:
								</InputLabel>
								<Box>
									<TextField
										placeholder='1-999'
										id='number-of-office-visits'
										name='numberOfOfficeVisits'
										value={values.numberOfOfficeVisits}
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.numberOfOfficeVisits &&
											Boolean(errors.numberOfOfficeVisits)
										}
										helperText={
											touched.numberOfOfficeVisits &&
											errors.numberOfOfficeVisits
										}
										InputProps={{
											inputProps: {
												maxLength: 3,
											},
										}}
									/>
								</Box>
							</Stack>
							<Stack spacing={3}>
								<Typography fontWeight='bold'>
									ICD-10 / Diagnoses (Highest level of
									specificity-Primary condition and Pathology
									codes):
								</Typography>
								<Grid container spacing={1}>
									<Grid item>
										<ConditionAndTreatmentCodeTextField
											codeType='Dx'
											isRequired
											labelText='1.'
											textFieldId='dx-code-1'
											textFieldName='dxCode1'
											value={values.dxCode1}
											onTextFieldChange={newValue =>
												setFieldValue(
													'dxCode1',
													newValue
												)
											}
											onValidation={message => {
												setFieldValue(
													'dxCode1InvalidMessage',
													message,
													true
												);
											}}
											onBlur={() =>
												setFieldTouched(
													'dxCode1',
													true,
													true
												)
											}
											error={
												touched.dxCode1 &&
												Boolean(errors.dxCode1)
											}
											helperText={
												touched.dxCode1 &&
												errors.dxCode1
											}
										/>
									</Grid>
									<Grid item>
										<ConditionAndTreatmentCodeTextField
											codeType='Dx'
											labelText='2.'
											textFieldId='dx-code-2'
											textFieldName='dxCode2'
											value={values.dxCode2}
											onTextFieldChange={newValue =>
												setFieldValue(
													'dxCode2',
													newValue
												)
											}
											onValidation={message => {
												setFieldValue(
													'dxCode2InvalidMessage',
													message,
													true
												);
											}}
											onBlur={() =>
												setFieldTouched(
													'dxCode2',
													true,
													true
												)
											}
											error={
												touched.dxCode2 &&
												Boolean(errors.dxCode2)
											}
											helperText={
												touched.dxCode2 &&
												errors.dxCode2
											}
										/>
									</Grid>
									<Grid item>
										<ConditionAndTreatmentCodeTextField
											codeType='Dx'
											labelText='3.'
											textFieldId='dx-code-3'
											textFieldName='dxCode3'
											value={values.dxCode3}
											onTextFieldChange={newValue =>
												setFieldValue(
													'dxCode3',
													newValue
												)
											}
											onValidation={message => {
												setFieldValue(
													'dxCode3InvalidMessage',
													message,
													true
												);
											}}
											onBlur={() =>
												setFieldTouched(
													'dxCode3',
													true,
													true
												)
											}
											error={
												touched.dxCode3 &&
												Boolean(errors.dxCode3)
											}
											helperText={
												touched.dxCode3 &&
												errors.dxCode3
											}
										/>
									</Grid>
									<Grid item>
										<ConditionAndTreatmentCodeTextField
											codeType='Dx'
											labelText='4.'
											textFieldId='dx-code-4'
											textFieldName='dxCode4'
											value={values.dxCode4}
											onTextFieldChange={newValue =>
												setFieldValue(
													'dxCode4',
													newValue
												)
											}
											onValidation={message => {
												setFieldValue(
													'dxCode4InvalidMessage',
													message,
													true
												);
											}}
											onBlur={() =>
												setFieldTouched(
													'dxCode4',
													true,
													true
												)
											}
											error={
												touched.dxCode4 &&
												Boolean(errors.dxCode4)
											}
											helperText={
												touched.dxCode4 &&
												errors.dxCode4
											}
										/>
									</Grid>
								</Grid>
							</Stack>
							<Typography fontWeight='bold'>
								Date Range for Care:
							</Typography>
							<Stack
								direction={{ xs: 'column', md: 'row' }}
								alignItems={{ xs: 'flex-start', md: 'center' }}
								spacing={2}
							>
								<Stack
									direction={{ xs: 'column', sm: 'row' }}
									alignItems={{
										xs: 'flex-start',
										sm: 'center',
									}}
									spacing={2}
								>
									<InputLabel
										htmlFor='from-date-for-care'
										sx={{
											fontWeight: 'bold',
											color: 'text.primary',
										}}
									>
										<Typography
											color='error'
											component='span'
										>
											*
										</Typography>{' '}
										From:
									</InputLabel>
									<Box>
										<DatePicker
											value={values.fromDateForCare}
											onChange={value => {
												setFieldValue(
													'fromDateForCare',
													value,
													true
												);
											}}
											minDate={dayjs('01/01/2000')}
											slotProps={{
												textField: {
													error:
														touched.fromDateForCare &&
														Boolean(
															errors.fromDateForCare
														),
													helperText:
														touched.fromDateForCare &&
														errors.fromDateForCare,
													onBlur: handleBlur,
													id: 'from-date-for-care',
													name: 'fromDateForCare',
												},
											}}
											sx={{ width: 200 }}
										/>
									</Box>
								</Stack>

								<Stack
									direction={{ xs: 'column', sm: 'row' }}
									alignItems={{
										xs: 'flex-start',
										sm: 'center',
									}}
									spacing={2}
								>
									<InputLabel
										htmlFor='through-date-for-care'
										sx={{
											fontWeight: 'bold',
											color: 'text.primary',
										}}
									>
										<Typography
											color='error'
											component='span'
										>
											*
										</Typography>{' '}
										Through:
									</InputLabel>
									<Box>
										<DatePicker
											value={values.throughDateForCare}
											onChange={value => {
												setFieldValue(
													'throughDateForCare',
													value,
													true
												);
											}}
											minDate={dayjs('01/01/2000')}
											slotProps={{
												textField: {
													error:
														touched.throughDateForCare &&
														Boolean(
															errors.throughDateForCare
														),
													helperText:
														touched.throughDateForCare &&
														errors.throughDateForCare,
													onBlur: handleBlur,
													id: 'through-date-for-care',
													name: 'throughDateForCare',
												},
											}}
										/>
									</Box>
								</Stack>
							</Stack>
							<Typography fontWeight='bold'>
								Services requested within above date range,
								please indicate:
							</Typography>
							<Stack
								direction={{ xs: 'column', md: 'row' }}
								alignItems={{ xs: 'flex-start', md: 'center' }}
								spacing={2}
							>
								<Stack
									direction={{ xs: 'column', sm: 'row' }}
									alignItems={{
										xs: 'flex-start',
										sm: 'center',
									}}
									spacing={2}
								>
									<InputLabel
										id='evaluation-code-select-label'
										sx={{
											fontWeight: 'bold',
											color: 'text.primary',
										}}
									>
										Evaluation Code:
									</InputLabel>
									<Select
										labelId='evaluation-code-select-label'
										id='evaluation-code-select'
										displayEmpty
										value={values.evaluationCode}
										onChange={handleChange}
										onBlur={handleBlur}
										inputProps={{
											name: 'evaluationCode',
										}}
										sx={{ width: 300 }}
									>
										<MenuItem value='' key='default'>
											Select the Evaluation Code
										</MenuItem>
										{evaluationCodes.map(code => (
											<MenuItem value={code} key={code}>
												{code}
											</MenuItem>
										))}
									</Select>
								</Stack>
								<Stack
									direction={{ xs: 'column', sm: 'row' }}
									alignItems={{
										xs: 'flex-start',
										sm: 'center',
									}}
									spacing={2}
								>
									<InputLabel
										id='reevaluation-code-select-label'
										sx={{
											fontWeight: 'bold',
											color: 'text.primary',
										}}
									>
										Re-evaluation Code
									</InputLabel>
									<Select
										labelId='reevaluation-code-select-label'
										id='reevaluation-code-select'
										displayEmpty
										value={values.reevaluationCode}
										onChange={handleChange}
										onBlur={handleBlur}
										inputProps={{
											name: 'reevaluationCode',
										}}
										sx={{ width: 300 }}
									>
										<MenuItem value='' key='default'>
											Select the Re-evaluation Code
										</MenuItem>
										{reevaluationCodes.map(code => (
											<MenuItem value={code} key={code}>
												{code}
											</MenuItem>
										))}
									</Select>
								</Stack>
							</Stack>
							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								alignItems={{ xs: 'left', sm: 'center' }}
								spacing={2}
							>
								<InputLabel
									htmlFor='total-rehab-service-visits'
									sx={{
										fontWeight: 'bold',
										color: 'text.primary',
										textWrap: 'wrap',
									}}
								>
									<Typography color='error' component='span'>
										*
									</Typography>{' '}
									Total number of Rehab Service Visits during
									this duration:
								</InputLabel>
								<Box>
									<TextField
										placeholder='1-99'
										id='total-rehab-service-visits'
										name='totalRehabServiceVisits'
										value={values.totalRehabServiceVisits}
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.totalRehabServiceVisits &&
											Boolean(
												errors.totalRehabServiceVisits
											)
										}
										helperText={
											touched.totalRehabServiceVisits &&
											errors.totalRehabServiceVisits
										}
										InputProps={{
											inputProps: {
												maxLength: 2,
											},
										}}
									/>
								</Box>
							</Stack>
							{sessionProviderSpecialtyCode ===
								Specialty.RehabProvider &&
								sessionProviderNetCode ===
									NetCodes.AshRehabProviderNetwork && (
									<Stack spacing={3}>
										<Typography fontWeight='bold'>
											EMG/NCV/FCE/Other Studies by CPT
											Code(s):
										</Typography>
										<Grid container spacing={1}>
											<Grid item>
												<ConditionAndTreatmentCodeTextField
													codeType='CPT'
													labelText='1.'
													textFieldId='cpt-code-1'
													textFieldName='cptCode1'
													value={values.cptCode1}
													onTextFieldChange={newValue => {
														setFieldValue(
															'cptCode1',
															newValue
														);
													}}
													onValidation={message => {
														setFieldValue(
															'cptCode1InvalidMessage',
															message,
															true
														);
													}}
													onBlur={() =>
														setFieldTouched(
															'cptCode1',
															true,
															true
														)
													}
													error={
														touched.cptCode1 &&
														Boolean(errors.cptCode1)
													}
													helperText={
														touched.cptCode1 &&
														errors.cptCode1
													}
												/>
											</Grid>
											<Grid item>
												<ConditionAndTreatmentCodeTextField
													codeType='CPT'
													labelText='2.'
													textFieldId='cpt-code-2'
													textFieldName='cptCode2'
													value={values.cptCode2}
													onTextFieldChange={newValue =>
														setFieldValue(
															'cptCode2',
															newValue
														)
													}
													onValidation={message =>
														setFieldValue(
															'cptCode2InvalidMessage',
															message,
															true
														)
													}
													onBlur={() =>
														setFieldTouched(
															'cptCode2',
															true,
															true
														)
													}
													error={
														touched.cptCode2 &&
														Boolean(errors.cptCode2)
													}
													helperText={
														touched.cptCode2 &&
														errors.cptCode2
													}
												/>
											</Grid>
											<Grid item>
												<ConditionAndTreatmentCodeTextField
													codeType='CPT'
													labelText='3.'
													textFieldId='cpt-code-3'
													textFieldName='cptCode3'
													value={values.cptCode3}
													onTextFieldChange={newValue =>
														setFieldValue(
															'cptCode3',
															newValue
														)
													}
													onValidation={message =>
														setFieldValue(
															'cptCode3InvalidMessage',
															message,
															true
														)
													}
													onBlur={() =>
														setFieldTouched(
															'cptCode3',
															true,
															true
														)
													}
													error={
														touched.cptCode3 &&
														Boolean(errors.cptCode3)
													}
													helperText={
														touched.cptCode3 &&
														errors.cptCode3
													}
												/>
											</Grid>
											<Grid item>
												<ConditionAndTreatmentCodeTextField
													codeType='CPT'
													labelText='4.'
													textFieldId='cpt-code-4'
													textFieldName='cptCode4'
													value={values.cptCode4}
													onTextFieldChange={newValue => {
														setFieldValue(
															'cptCode4',
															newValue
														);
														setFieldError(
															'cptCode4',
															null
														);
													}}
													onValidation={message =>
														setFieldValue(
															'cptCode4InvalidMessage',
															message,
															true
														)
													}
													onBlur={() =>
														setFieldTouched(
															'cptCode4',
															true,
															true
														)
													}
													error={
														touched.cptCode4 &&
														Boolean(errors.cptCode4)
													}
													helperText={
														touched.cptCode4 &&
														errors.cptCode4
													}
												/>
											</Grid>
										</Grid>
									</Stack>
								)}

							{sessionProviderSpecialtyCode ===
								Specialty.RehabProvider &&
								sessionProviderNetCode ===
									NetCodes.AshRehabProviderNetwork && (
									<Stack spacing={3}>
										<Typography fontWeight='bold'>
											Durable Medical Equipment by HCPCS
											Code(s):
										</Typography>
										<Grid container spacing={1}>
											<Grid item>
												<ConditionAndTreatmentCodeTextField
													codeType='HCPCS'
													labelText='1.'
													textFieldId='hcpcs-code-1'
													textFieldName='hcpcsCode1'
													value={values.hcpcsCode1}
													onTextFieldChange={newValue =>
														setFieldValue(
															'hcpcsCode1',
															newValue
														)
													}
													onValidation={message =>
														setFieldValue(
															'hcpcsCode1InvalidMessage',
															message,
															true
														)
													}
													onBlur={() =>
														setFieldTouched(
															'hcpcsCode1',
															true,
															true
														)
													}
													error={
														touched.hcpcsCode1 &&
														Boolean(
															errors.hcpcsCode1
														)
													}
													helperText={
														touched.hcpcsCode1 &&
														errors.hcpcsCode1
													}
												/>
											</Grid>
											<Grid item>
												<ConditionAndTreatmentCodeTextField
													codeType='HCPCS'
													labelText='2.'
													textFieldId='hcpcs-code-2'
													textFieldName='hcpcsCode2'
													value={values.hcpcsCode2}
													onTextFieldChange={newValue =>
														setFieldValue(
															'hcpcsCode2',
															newValue
														)
													}
													onValidation={message =>
														setFieldValue(
															'hcpcsCode2InvalidMessage',
															message,
															true
														)
													}
													onBlur={() =>
														setFieldTouched(
															'hcpcsCode2',
															true,
															true
														)
													}
													error={
														touched.hcpcsCode2 &&
														Boolean(
															errors.hcpcsCode2
														)
													}
													helperText={
														touched.hcpcsCode2 &&
														errors.hcpcsCode2
													}
												/>
											</Grid>
											<Grid item>
												<ConditionAndTreatmentCodeTextField
													codeType='HCPCS'
													labelText='3.'
													textFieldId='hcpcs-code-3'
													textFieldName='hcpcsCode3'
													value={values.hcpcsCode3}
													onTextFieldChange={newValue =>
														setFieldValue(
															'hcpcsCode3',
															newValue
														)
													}
													onValidation={message =>
														setFieldValue(
															'hcpcsCode3InvalidMessage',
															message,
															true
														)
													}
													onBlur={() =>
														setFieldTouched(
															'hcpcsCode3',
															true,
															true
														)
													}
													error={
														touched.hcpcsCode3 &&
														Boolean(
															errors.hcpcsCode3
														)
													}
													helperText={
														touched.hcpcsCode3 &&
														errors.hcpcsCode3
													}
												/>
											</Grid>
											<Grid item>
												<ConditionAndTreatmentCodeTextField
													codeType='HCPCS'
													labelText='4.'
													textFieldId='hcpcs-code-4'
													textFieldName='hcpcsCode4'
													value={values.hcpcsCode4}
													onTextFieldChange={newValue =>
														setFieldValue(
															'hcpcsCode4',
															newValue
														)
													}
													onValidation={message =>
														setFieldValue(
															'hcpcsCode4InvalidMessage',
															message,
															true
														)
													}
													onBlur={() =>
														setFieldTouched(
															'hcpcsCode4',
															true,
															true
														)
													}
													error={
														touched.hcpcsCode4 &&
														Boolean(
															errors.hcpcsCode4
														)
													}
													helperText={
														touched.hcpcsCode4 &&
														errors.hcpcsCode4
													}
												/>
											</Grid>
										</Grid>
									</Stack>
								)}
							<InputLabel
								htmlFor='additionalClinicalInformation'
								sx={{
									fontWeight: 'bold',
									color: 'text.primary',
									textWrap: 'wrap',
								}}
							>
								Additional/Updated Clinical Information not
								included in attached records:
							</InputLabel>
							<TextField
								fullWidth
								multiline
								minRows={4}
								id='additionalClinicalInformation'
								value={values.additionalClinicalInformation}
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched.additionalClinicalInformation &&
									Boolean(
										errors.additionalClinicalInformation
									)
								}
								helperText={
									values.additionalClinicalInformation
										? `Remaining: ${
												2500 -
												values
													.additionalClinicalInformation
													.length
										  }`
										: 'Remaining: 2500'
								}
								InputProps={{
									inputProps: { maxLength: 2500 },
								}}
							/>
							<Typography fontWeight='bold'>
								If you are treating this member for an Autism
								Spectrum Disorder (ASD), please attest to the
								following by checking the box below:
							</Typography>
							<FormControlLabel
								control={
									<Checkbox
										checked={
											values.treatingforAnAutismSpectrumDisorder
										}
									/>
								}
								onChange={handleChange}
								name='treatingforAnAutismSpectrumDisorder'
								label='I am following state-specific rules and regulations of the state mandate of Autism Spectrum Disorder (ASD).'
							/>
							<Box>
								<Typography fontWeight='bold' gutterBottom>
									<Typography color='error' component='span'>
										*
									</Typography>{' '}
									Attach Medical Records
								</Typography>
								<Typography gutterBottom>
									Please attach the Clinical Information
									Summary Sheet or all relevant evaluation
									forms, progress notes or summary reports
									that support the medical necessity of the
									submitted rehabilitation services.
								</Typography>
								<Typography
									variant='body3'
									color='warning.main'
									fontWeight='bold'
									fontStyle='italic'
									gutterBottom
								>
									Do not submit daily notes without a summary
									of progress. Do not upload password
									protected documents.
								</Typography>
								<Stack direction='row' alignItems='center'>
									<VisuallyHiddenInput
										type='file'
										onChange={event => {
											setFieldValue(
												'medicalRecordsFile',
												event.currentTarget.files[0]
											);
										}}
										ref={fileRef}
										id='upload-medical-records-file-input'
									/>

									<Button
										component='label'
										role={undefined}
										variant='contained'
										tabIndex={-1}
										startIcon={<CloudUploadIcon />}
										htmlFor='upload-medical-records-file-input'
									>
										Upload file
									</Button>
									{values.medicalRecordsFile?.name && (
										<Typography color='secondary'>
											{values.medicalRecordsFile.name}
											<IconButton
												aria-label='clear'
												color='error'
												onClick={() => {
													setFieldValue(
														'medicalRecordsFile',
														null
													);
													setFieldError(
														'medicalRecordsFile',
														''
													);
													resetUploadFileInput();
												}}
											>
												<ClearIcon />
											</IconButton>
										</Typography>
									)}
								</Stack>
								{touched.medicalRecordsFile &&
									errors.medicalRecordsFile && (
										<Typography
											variant='subtitle2'
											color='error'
										>
											{errors.medicalRecordsFile}
										</Typography>
									)}
								<Typography variant='subtitle2'>
									PDF (max. 3MB)
								</Typography>
							</Box>
							<Typography>
								<Typography color='error' component='span'>
									*
								</Typography>{' '}
								- Required Fields
							</Typography>
							<Stack direction='row' justifyContent='center'>
								<Button
									type='submit'
									variant='contained'
									onClick={() => {
										handleSubmit();
									}}
								>
									Continue
								</Button>
							</Stack>
						</Stack>
					</AccordionDetails>
				</Accordion>
			)}
		</Formik>
	);
};

MedicalRecordsTreatmentServicesSection.propTypes = {
	expanded: PropTypes.bool.isRequired,
	onAccordionChange: PropTypes.func.isRequired,
	onContinueClick: PropTypes.func.isRequired,
};

export default MedicalRecordsTreatmentServicesSection;
