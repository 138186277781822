import PropTypes from 'prop-types';

const MarkdownLinkRenderer = ({ href, children }) => {
	const target = href.includes('ashlink.com') ? '_self' : '_blank';

	return (
		<a href={href} target={target} rel='noreferrer'>
			{children}
		</a>
	);
};

MarkdownLinkRenderer.propTypes = {
	href: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export default MarkdownLinkRenderer;
