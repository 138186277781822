import dayjs from 'dayjs';
import {
	LegacyRehabAuthFormTypes,
	LegacyRehabSubSectionIds,
	LegacySpeechSubSectionIds,
	EAuthFormFieldSubSectionIds,
} from '../scripts/authFormHelpers';
import restDataService from './restDataService';

const contentManagementApiBasePath = 'api/ContentManagement/Clinical/PostLogin';
const confirmationApiBasePath = 'api/Confirmation/Compliance';
const ashlinkNextGatewayApiBasePath =
	'api/ashlinknextapigateway/Clinical/PostLogin';
const gatewayAuthManagementApiBasePath = 'api/ashlinknextapigateway';
const authManagementApiBasePath = 'api/authmanagement';

export const GetFileBySpecialtyAndRegionFn = async (
	clinicalFileContentType,
	clinicalSpecialtyAndDistinction,
	regions,
	pageNumber,
	pageSize
) =>
	restDataService
		.get(`${clinicalFileContentType}/FileBySpecialtyAndRegion`, {
			params: {
				ClinicalFileContentType: clinicalFileContentType,
				ClinicalSpecialtyAndDistinction:
					clinicalSpecialtyAndDistinction,
				Regions: regions,
				Page: pageNumber,
				PageSize: pageSize,
			},
		})
		.catch(error => error.response);

export const GetClinicalResourcesFn = async (
	drFedId,
	practitionerId,
	clinicalFileType,
	pageNumber,
	pageSize
) =>
	restDataService
		.get(`${ashlinkNextGatewayApiBasePath}/ClinicalResources`, {
			params: {
				DrFedID: drFedId,
				PractitionerId: practitionerId,
				ClinicalFileType: clinicalFileType,
				Page: pageNumber,
				PageSize: pageSize,
			},
		})
		.catch(error => error.response);

export const GetClinicalComplianceFn = async () =>
	restDataService
		.get(`${contentManagementApiBasePath}/Compliance`)
		.catch(error => error.response);

export const GetClinicalComplianceStatusFn = async providerId =>
	restDataService
		.get(`${confirmationApiBasePath}/ComplianceStatuses`, {
			params: {
				DocId: providerId,
			},
		})
		.catch(error => error.response);

export const GetValueAddedVendorsBySpecialtyFn = async drFedId =>
	restDataService
		.get(`${ashlinkNextGatewayApiBasePath}/ValueAddedVendorBySpecialty`, {
			params: {
				DrFedID: drFedId,
			},
		})
		.catch(error => error.response);

export const GetFAQBySpecialtyFn = async drFedId =>
	restDataService
		.get(`${ashlinkNextGatewayApiBasePath}/FAQBySpecialty`, {
			params: {
				DrFedID: drFedId,
			},
		})
		.catch(error => error.response);

export const GetClinicalGeneralResourcesFn = async (
	drFedId,
	clinicalGeneralFileType
) =>
	restDataService
		.get(`${ashlinkNextGatewayApiBasePath}/ClinicalGeneralResources`, {
			params: {
				DrFedID: drFedId,
				ClinicalGeneralFileType: clinicalGeneralFileType,
			},
		})
		.catch(error => error.response);

export const GetClinicalPELResourcesFn = async (
	drFedId,
	practitionerId,
	pageNumber,
	pageSize
) =>
	restDataService
		.get(`${ashlinkNextGatewayApiBasePath}/ClinicalPELResources`, {
			params: {
				DrFedID: drFedId,
				PractitionerId: practitionerId,
				Page: pageNumber,
				PageSize: pageSize,
			},
		})
		.catch(error => error.response);

export const GetClinicInfoFn = async (
	apiUrl,
	page,
	pageSize,
	sortModel,
	filterModel,
	rowCount,
	selectAll
) => {
	if (apiUrl !== '') {
		return restDataService
			.get(apiUrl, {
				params: {
					Page: page,
					PageSize: pageSize,
					SortModel: sortModel,
					FilterModel: filterModel,
				},
			})
			.catch(error => error.response);
	}
	const testData = {
		name: 'Big Sky Pediatric Therapy',
		address: '123 Elm St. San Diego, CA 92125',
		phone: '8587502427',
		fax: '8587502427',
		taxId: 1002030,
		effective: '09/01/2021',
		termed: '09/01/2021',
	};

	const rows = [];
	const startIndex = page * pageSize;
	for (
		let i = startIndex;
		i < Math.min(startIndex + pageSize, rowCount);
		i += 1
	) {
		rows.push({ id: i, ...testData });
	}
	const selectedRows = selectAll ? [...Array(rowCount).keys()] : [];

	return {
		data: {
			rows,
			rowCount,
			selectedRows,
		},
	};
};

export const GetSessionContext = async () => {
	try {
		return (await restDataService.get('auth/userparams')).data;
	} catch (error) {
		return error;
	}
};

export const GetStatus = async () => {
	try {
		return (await restDataService.get('auth/sessionstatus')).data;
	} catch (error) {
		return error;
	}
};

export const GetPractitioner = async drFedId =>
	restDataService
		.get(`${ashlinkNextGatewayApiBasePath}/ValueAddedVendorBySpecialty`, {
			params: {
				DrFedID: drFedId,
			},
		})
		.catch(error => error.response);

export const GetSessionData = async ({ queryKey }) => {
	const [, name] = queryKey;

	try {
		return (
			await restDataService.get('api/legacy/sessiondata', {
				params: {
					name,
				},
			})
		).data;
	} catch (error) {
		return error;
	}
};

export const PostLegacySessionDataFn = async ({ name, jsonData }) =>
	restDataService.post('api/legacy/sessiondata', {
		Name: name,
		JsonData: jsonData,
	});

export const PutPartialAuthFormFn = async params => {
	const eAuthFieldValues = [];
	// These two speech forms use different subsection ids and store data in a different table
	// so they need their own block of logic
	if (
		params.formSelectionData.authFormType ===
			LegacyRehabAuthFormTypes.Cognitive ||
		params.formSelectionData.authFormType ===
			LegacyRehabAuthFormTypes.ContinuingCare
	) {
		if (params.formSelectionData.authTherapyType) {
			eAuthFieldValues.push({
				SubSectionId: LegacySpeechSubSectionIds.AuthTherapyType,
				DataValue: params.formSelectionData.authTherapyType,
			});
		}
		if (params.formSelectionData.authFormType) {
			eAuthFieldValues.push({
				SubSectionId: LegacySpeechSubSectionIds.AuthFormType,
				DataValue: params.formSelectionData.authFormType,
			});
		}
		if (params.formSelectionData.practitionerNameNpi) {
			eAuthFieldValues.push({
				SubSectionId: LegacySpeechSubSectionIds.ProviderTherapist,
				DataValue: params.formSelectionData.practitionerNameNpi,
			});
		}
		if (params.formSelectionData.serviceLocation.name) {
			eAuthFieldValues.push({
				SubSectionId: LegacySpeechSubSectionIds.ProviderClinicName,
				DataValue: params.formSelectionData.serviceLocation.name,
			});
		}
		if (params.formSelectionData.submitHealthStatusForm) {
			eAuthFieldValues.push({
				SubSectionId: LegacySpeechSubSectionIds.SubmitHealthStatusForm,
				DataValue: params.formSelectionData.submitHealthStatusForm,
			});
		}
		if (params.formSelectionData.serviceLocation.address1) {
			eAuthFieldValues.push({
				SubSectionId: LegacySpeechSubSectionIds.ProviderAddress,
				DataValue: params.formSelectionData.serviceLocation.address1,
			});
		}
		if (params.formSelectionData.serviceLocation.city) {
			eAuthFieldValues.push({
				SubSectionId: LegacySpeechSubSectionIds.ProviderCity,
				DataValue: params.formSelectionData.serviceLocation.city,
			});
		}
		if (params.formSelectionData.serviceLocation.state) {
			eAuthFieldValues.push({
				SubSectionId: LegacySpeechSubSectionIds.ProviderState,
				DataValue: params.formSelectionData.serviceLocation.state,
			});
		}
		if (params.formSelectionData.serviceLocation.zipCode) {
			eAuthFieldValues.push({
				SubSectionId: LegacySpeechSubSectionIds.ProviderZip,
				DataValue: params.formSelectionData.serviceLocation.zipCode,
			});
		}
		if (params.formSelectionData.practitionerNameNpi) {
			eAuthFieldValues.push({
				SubSectionId: LegacySpeechSubSectionIds.PractitionerNpi,
				DataValue: params.formSelectionData.practitionerNameNpi,
			});
		}
	} else {
		if (params.formSelectionData.practitionerNameNpi) {
			eAuthFieldValues.push({
				SubSectionId: LegacyRehabSubSectionIds.PractitionerNpi,
				DataValue: params.formSelectionData.practitionerNameNpi,
			});
		}
		if (params.formSelectionData.authTherapyType) {
			eAuthFieldValues.push({
				SubSectionId: LegacyRehabSubSectionIds.AuthTherapyType,
				DataValue: params.formSelectionData.authTherapyType,
			});
		}
		if (params.formSelectionData.authFormType) {
			eAuthFieldValues.push({
				SubSectionId: LegacyRehabSubSectionIds.AuthFormType,
				DataValue: params.formSelectionData.authFormType,
			});
		}
		if (params.formSelectionData.submitHealthStatusForm) {
			eAuthFieldValues.push({
				SubSectionId: LegacyRehabSubSectionIds.SubmitHealthStatusForm,
				DataValue: params.formSelectionData.submitHealthStatusForm,
			});
		}
	}
	return restDataService.put(`${authManagementApiBasePath}/auth/EAuth`, {
		// data from the logged in session
		DocId: params.sessionProviderId,
		SpecialtyCode: params.sessionProviderSpecialtyCode,
		// data from the member selection
		MemId: params.memberSessionInfo.MemId,
		Suffix: params.memberSessionInfo.Suffix,
		Sequence: params.memberSessionInfo.Seq,
		EnrollSeq: params.memberSessionInfo.EnrollSeq,
		MemLast: params.memberSessionInfo.MemLast,
		MemFirst: params.memberSessionInfo.MemFirst,
		DOB: params.memberSessionInfo.DOB,
		Sex: params.memberSessionInfo.Gender,
		MemAddress1: params.memberSessionInfo.Address,
		MemCity: params.memberSessionInfo.City,
		MemState: params.memberSessionInfo.State,
		MemZip: params.memberSessionInfo.Zip,
		MemPhone: params.memberSessionInfo.Phone,
		PlanId: params.memberSessionInfo.PlanId,
		HealthPlan: params.memberSessionInfo.HealthPlan,
		ContractId: params.memberSessionInfo.ContractId,
		ContractSuffix: params.memberSessionInfo.ContractSuffix,
		GroupId: params.memberSessionInfo.GroupNo,
		AccountMasterId: params.memberSessionInfo.AccountMasterId,
		AccountId: params.memberSessionInfo.AccountId,
		// data from the form selection page
		ECTFType: params.formSelectionData.authType,
		EAuthType: params.formSelectionData.authFormType,
		DocSuffix: params.formSelectionData.serviceLocation.providerDocSuffix,
		TaxId: params.formSelectionData.serviceLocation.taxId,
		ClinicId: params.formSelectionData.serviceLocation.id,
		EAuthFieldValues: eAuthFieldValues,
	});
};

export const PostCreateAuthfn = async params => {
	let formType = '';
	const eAuthFieldValues = [];
	let attachment = null;
	if (
		params.selectionData.authFormType ===
		LegacyRehabAuthFormTypes.Neuro_Peds
	) {
		formType = 'H';
	}
	if (
		params.selectionData.authFormType ===
		LegacyRehabAuthFormTypes.Orthopedic
	) {
		formType = 'O';
	}
	if (
		params.selectionData.authFormType ===
		LegacyRehabAuthFormTypes.MedicalRecords
	) {
		formType = 'K';
	}
	if (params.selectionData.practitionerNameNpi) {
		eAuthFieldValues.push({
			SubSectionId: LegacyRehabSubSectionIds.PractitionerNpi,
			DataValue: params.selectionData.practitionerNameNpi,
		});
	}
	if (params.selectionData.authTherapyType) {
		eAuthFieldValues.push({
			SubSectionId: LegacyRehabSubSectionIds.AuthTherapyType,
			DataValue: params.selectionData.authTherapyType,
		});
	}
	if (params.selectionData.authFormType) {
		eAuthFieldValues.push({
			SubSectionId: LegacyRehabSubSectionIds.AuthFormType,
			DataValue: params.selectionData.authFormType,
		});
	}
	if (params.selectionData.submitHealthStatusForm) {
		eAuthFieldValues.push({
			SubSectionId: LegacyRehabSubSectionIds.SubmitHealthStatusForm,
			DataValue: params.selectionData.submitHealthStatusForm,
		});
	}
	if (
		params.selectionData.authFormType ===
		LegacyRehabAuthFormTypes.MedicalRecords
	) {
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_AreServicesForHabilitativeCare,
			DataValue: params.authFormData.servicesForHabilitativeCare,
		});
		if (params.authFormData.initialStartOfCareDate) {
			eAuthFieldValues.push({
				SubSectionId:
					EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_InitialStartOfCareForThisCondition,
				DataValue: dayjs(
					params.authFormData.initialStartOfCareDate
				).format('MM/DD/YYYY'),
			});
		}
		if (params.authFormData.dateOfFindingsAttached) {
			eAuthFieldValues.push({
				SubSectionId:
					EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_DateOfEvaluationOrReevaluationFindingsAttached,
				DataValue: dayjs(
					params.authFormData.dateOfFindingsAttached
				).format('MM/DD/YYYY'),
			});
		}
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_NumberOfOfficeVisitsAlreadyRendered,
			DataValue: params.authFormData.numberOfOfficeVisits,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_ICD10DiagnosesHighestLevelOfSpecificityPrimaryConditionAndPathologyCodesOne,
			DataValue: params.authFormData.dxCode1,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_ICD10DiagnosesHighestLevelOfSpecificityPrimaryConditionAndPathologyCodesTwo,
			DataValue: params.authFormData.dxCode2,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_ICD10DiagnosesHighestLevelOfSpecificityPrimaryConditionAndPathologyCodesThree,
			DataValue: params.authFormData.dxCode3,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_ICD10DiagnosesHighestLevelOfSpecificityPrimaryConditionAndPathologyCodesFour,
			DataValue: params.authFormData.dxCode4,
		});
		if (params.authFormData.fromDateForCare) {
			eAuthFieldValues.push({
				SubSectionId:
					EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_DateRangeForCareFrom,
				DataValue: dayjs(params.authFormData.fromDateForCare).format(
					'MM/DD/YYYY'
				),
			});
		}
		if (params.authFormData.throughDateForCare) {
			eAuthFieldValues.push({
				SubSectionId:
					EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_DateRangeForCareThrough,
				DataValue: dayjs(params.authFormData.throughDateForCare).format(
					'MM/DD/YYYY'
				),
			});
		}
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_ServicesRequestedWithinAboveDateRangePleaseIndicateEvaluation,
			DataValue: params.authFormData.evaluationCode,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_ServicesRequestedWithinAboveDateRangePleaseIndicateReevaluation,
			DataValue: params.authFormData.reevaluationCode,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_TotalNumberOfRehabServiceVisitsDuringThisDuration,
			DataValue: params.authFormData.totalRehabServiceVisits,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_EMGNVCFCEOtherStudiesByCPTCodesOne,
			DataValue: params.authFormData.cptCode1,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_EMGNVCFCEOtherStudiesByCPTCodesTwo,
			DataValue: params.authFormData.cptCode2,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_EMGNVCFCEOtherStudiesByCPTCodesThree,
			DataValue: params.authFormData.cptCode3,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_EMGNVCFCEOtherStudiesByCPTCodesFour,
			DataValue: params.authFormData.cptCode4,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_DurableMedicalEquipmentByHCPCSCodesOne,
			DataValue: params.authFormData.hcpcsCode1,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_DurableMedicalEquipmentByHCPCSCodesTwo,
			DataValue: params.authFormData.hcpcsCode2,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_DurableMedicalEquipmentByHCPCSCodesThree,
			DataValue: params.authFormData.hcpcsCode3,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_DurableMedicalEquipmentByHCPCSCodesFour,
			DataValue: params.authFormData.hcpcsCode4,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_AdditionalUpdatedClinicalInformationNotIncludedInAttachedRecords,
			DataValue: params.authFormData.additionalClinicalInformation,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_IfYouAreTreatingThisMemberForAnAutismSpectrumDisorderASDPleaseAttestToTheFollowingByCheckingTheBoxBelow,
			DataValue: params.authFormData.treatingforAnAutismSpectrumDisorder,
		});
		eAuthFieldValues.push({
			SubSectionId:
				EAuthFormFieldSubSectionIds.MedicalRecord_TreatmentServicesSubmittingForReview_AttachMedicalRecord,
			DataValue: params.authFormData?.medicalRecordsFile?.name,
		});
		if (params.authFormData.medicalRecordsFile?.name !== null) {
			attachment = params.authFormData.medicalRecordsFile;
		}
	}
	const formData = new FormData();
	formData.append('EAuthNo', params.eAuthNo);
	formData.append('EAuthType', params.selectionData.authFormType);
	if (
		params.authFormData.evaluationCode &&
		params.authFormData.evaluationCode !== 'N/A'
	) {
		formData.append('ExamReq', 1);
	} else {
		formData.append('ExamReq', 0);
	}
	if (
		params.authFormData.reevaluationCode &&
		params.authFormData.reevaluationCode !== 'N/A'
	) {
		formData.append('ReexamReq', 1);
	} else {
		formData.append('ReexamReq', 0);
	}
	formData.append('DMEReq', 0);
	formData.append('ManipReq', 0);
	if (params.authFormData.totalRehabServiceVisits) {
		formData.append(
			'TherapyReq',
			params.authFormData.totalRehabServiceVisits
		);
	} else {
		formData.append('TherapyReq', 0);
	}
	formData.append('LabReq', 0);
	formData.append('CervReq', 0);
	formData.append('LumbarReq', 0);
	formData.append('ThorReq', 0);
	formData.append('OtherReq', 0);
	formData.append('HerbConsultReq', 0);
	formData.append('TchsReq', 0);
	formData.append('FormType', formType);
	if (params.authFormData.fromDateForCare) {
		formData.append(
			'ReqFrom',
			dayjs(params.authFormData.fromDateForCare).format('MM/DD/YYYY')
		);
	}
	if (params.authFormData.throughDateForCare) {
		formData.append(
			'ReqThru',
			dayjs(params.authFormData.throughDateForCare).format('MM/DD/YYYY')
		);
	}
	formData.append('ResubCode', 0);
	formData.append('ResubLevel', 0);
	if (params.authFormData.additionalClinicalInformation) {
		formData.append(
			'Notes',
			params.authFormData.additionalClinicalInformation
		);
	}
	formData.append('AuthStatus', '');
	formData.append('WorkComp', 0);
	formData.append('ReferralNo', 0);
	formData.append('ResubCode2', 0);
	formData.append('ResubCode3', 0);
	formData.append('ResubCode4', 0);
	formData.append('ResubCode5', 0);
	formData.append('ResubCode6', 0);
	formData.append('InjectReq', 0);
	formData.append('SurgeryReq', 0);
	formData.append('ProlongSVSReq', 0);

	if (params?.selectionData?.serviceLocation?.address1) {
		formData.append(
			'ClinicAddress1',
			params?.selectionData?.serviceLocation?.address1
		);
	}
	if (params?.selectionData?.serviceLocation?.address2) {
		formData.append(
			'ClinicAddress2',
			params?.selectionData?.serviceLocation?.address2
		);
	}
	if (params?.selectionData?.serviceLocation?.city) {
		formData.append(
			'ClinicCity',
			params?.selectionData?.serviceLocation?.city
		);
	}
	if (params?.selectionData?.serviceLocation?.state) {
		formData.append(
			'ClinicState',
			params?.selectionData?.serviceLocation?.state
		);
	}
	if (params?.selectionData?.serviceLocation?.zipCode) {
		formData.append(
			'ClinicZipCode',
			params?.selectionData?.serviceLocation?.zipCode
		);
	}
	if (params?.selectionData?.serviceLocation?.phone) {
		formData.append(
			'DrFedPhone',
			params?.selectionData?.serviceLocation?.phone
		);
	}

	let fieldValuesIndex = 0;
	eAuthFieldValues.forEach(value => {
		// Allows values that are 0 and false to be sent in the request
		if (
			value.DataValue !== undefined &&
			value.DataValue !== null &&
			value.DataValue !== ''
		) {
			const subSectionIdKey = `EAuthFieldValues[${fieldValuesIndex}].SubSectionId`;
			const dataValueKey = `EAuthFieldValues[${fieldValuesIndex}].DataValue`;
			formData.append(subSectionIdKey, value.SubSectionId);
			formData.append(dataValueKey, value.DataValue);
			fieldValuesIndex += 1;
		}
	});

	if (attachment) {
		formData.append('file', attachment);
	}

	return restDataService.post(
		`${gatewayAuthManagementApiBasePath}/Auth/EAuth`,
		formData,
		{
			headers: { 'Content-Type': 'multipart/form-data' },
		}
	);
};
