import Specialty from './specialty';

export const AuthFormLabels = Object.freeze({
	PtotNuero: 'PTOT Neuro Conditions E-MNR',
	PtotOrthopedic: 'PTOT Orthopedic Conditions E-MNR',
	PtotPediatric: 'PTOT Pediatric Conditions E-MNR',
	StDysphagia: 'ST Dysphagia Conditions E-MNR',
	StCognitive: 'ST Speech/Language/Cognitive Conditions E-MNR',
	StContinuingCare: 'ST Continuing Care E-MNR',
	AtOrthopedic: 'AT Orthopedic Conditions E-MNR',
	MedicalRecords: 'Medical Records',
});

// These values come from an enum in the legacy project. They have been copied here so that both
// projects save the same value for e_CTF_Type in the database. The hope is that in the future
// we will be able to redo this structure into a single enum that spans all specialties.
export const LegacyRehabAuthFormTypes = Object.freeze({
	Neuro_Peds: 1,
	Orthopedic: 2,
	Dysphagia: 3,
	Pediatric: 4,
	Cognitive: 5,
	ContinuingCare: 6,
	AT_Orthopedic: 7,
	Ortho_VPT: 11,
	Hand: 12,
	MedicalRecords: 13,
});

export const GetAuthFormLabelFromLegacyRehabMnrFormType =
	LegacyRehabMnrFormType => {
		switch (LegacyRehabMnrFormType) {
			case LegacyRehabAuthFormTypes.Neuro_Peds:
				return AuthFormLabels.PtotNuero;
			case LegacyRehabAuthFormTypes.Orthopedic:
				return AuthFormLabels.PtotOrthopedic;
			case LegacyRehabAuthFormTypes.Pediatric:
				return AuthFormLabels.PtotPediatric;
			case LegacyRehabAuthFormTypes.Dysphagia:
				return AuthFormLabels.StDysphagia;
			case LegacyRehabAuthFormTypes.Cognitive:
				return AuthFormLabels.StCognitive;
			case LegacyRehabAuthFormTypes.ContinuingCare:
				return AuthFormLabels.StContinuingCare;
			case LegacyRehabAuthFormTypes.AT_Orthopedic:
				return AuthFormLabels.AtOrthopedic;
			case LegacyRehabAuthFormTypes.MedicalRecords:
				return AuthFormLabels.MedicalRecords;
			default:
				throw new Error(
					`No auth form label found for legacy rehab mnr form type ${LegacyRehabMnrFormType}`
				);
		}
	};

// These values come from an enum in the legacy project. They have been copied here so that both
// projects save the same values in the database.
export const LegacyRehabSubSectionIds = Object.freeze({
	PractitionerNpi: 222, // practitionerNPI
	AuthTherapyType: 223, // e_MNR_TherapyType
	AuthFormType: 224, // e_MNR_FormType
	SubmitHealthStatusForm: 225, // e_MNR_IncludeHSF
});
export const LegacySpeechSubSectionIds = Object.freeze({
	AuthTherapyType: 258, // e_MNR_TherapyType
	AuthFormType: 259, // e_MNR_FormType
	ProviderTherapist: 260, // // prov_therapist
	SubmitHealthStatusForm: 336, // e_MNR_IncludeHSF
	ProviderAddress: 358, // prov_address
	ProviderClinicName: 359, // prov_clinic_name
	ProviderCity: 360, // prov_city
	ProviderState: 361, // prov_state
	ProviderZip: 362, // prov_zip
	PractitionerNpi: 374, // practitionerNPI
});

export const EAuthFormFieldSubSectionIds = Object.freeze({
	MedicalRecord_TreatmentServicesSubmittingForReview_AreServicesForHabilitativeCare: 1201,
	MedicalRecord_TreatmentServicesSubmittingForReview_InitialStartOfCareForThisCondition: 1202,
	MedicalRecord_TreatmentServicesSubmittingForReview_DateOfEvaluationOrReevaluationFindingsAttached: 1203,
	MedicalRecord_TreatmentServicesSubmittingForReview_NumberOfOfficeVisitsAlreadyRendered: 1204,
	MedicalRecord_TreatmentServicesSubmittingForReview_ICD10DiagnosesHighestLevelOfSpecificityPrimaryConditionAndPathologyCodesOne: 1205,
	MedicalRecord_TreatmentServicesSubmittingForReview_ICD10DiagnosesHighestLevelOfSpecificityPrimaryConditionAndPathologyCodesTwo: 1206,
	MedicalRecord_TreatmentServicesSubmittingForReview_ICD10DiagnosesHighestLevelOfSpecificityPrimaryConditionAndPathologyCodesThree: 1207,
	MedicalRecord_TreatmentServicesSubmittingForReview_ICD10DiagnosesHighestLevelOfSpecificityPrimaryConditionAndPathologyCodesFour: 1208,
	MedicalRecord_TreatmentServicesSubmittingForReview_DateRangeForCareFrom: 1209,
	MedicalRecord_TreatmentServicesSubmittingForReview_DateRangeForCareThrough: 1210,
	MedicalRecord_TreatmentServicesSubmittingForReview_ServicesRequestedWithinAboveDateRangePleaseIndicateEvaluation: 1211,
	MedicalRecord_TreatmentServicesSubmittingForReview_ServicesRequestedWithinAboveDateRangePleaseIndicateReevaluation: 1212,
	MedicalRecord_TreatmentServicesSubmittingForReview_TotalNumberOfRehabServiceVisitsDuringThisDuration: 1213,
	MedicalRecord_TreatmentServicesSubmittingForReview_EMGNVCFCEOtherStudiesByCPTCodesOne: 1214,
	MedicalRecord_TreatmentServicesSubmittingForReview_EMGNVCFCEOtherStudiesByCPTCodesTwo: 1215,
	MedicalRecord_TreatmentServicesSubmittingForReview_EMGNVCFCEOtherStudiesByCPTCodesThree: 1216,
	MedicalRecord_TreatmentServicesSubmittingForReview_EMGNVCFCEOtherStudiesByCPTCodesFour: 1217,
	MedicalRecord_TreatmentServicesSubmittingForReview_DurableMedicalEquipmentByHCPCSCodesOne: 1218,
	MedicalRecord_TreatmentServicesSubmittingForReview_DurableMedicalEquipmentByHCPCSCodesTwo: 1219,
	MedicalRecord_TreatmentServicesSubmittingForReview_DurableMedicalEquipmentByHCPCSCodesThree: 1220,
	MedicalRecord_TreatmentServicesSubmittingForReview_DurableMedicalEquipmentByHCPCSCodesFour: 1221,
	MedicalRecord_TreatmentServicesSubmittingForReview_AdditionalUpdatedClinicalInformationNotIncludedInAttachedRecords: 1222,
	MedicalRecord_TreatmentServicesSubmittingForReview_IfYouAreTreatingThisMemberForAnAutismSpectrumDisorderASDPleaseAttestToTheFollowingByCheckingTheBoxBelow: 1223,
	MedicalRecord_TreatmentServicesSubmittingForReview_AttachMedicalRecord: 1224,
});

export const AuthTherapyTypes = Object.freeze({
	PT: 1,
	OT: 2,
	ST: 3,
	AT: 4,
});

export const GetAuthTherapyType = (
	authFormType,
	practitionerSpecialtyCode,
	providerSpecialtyCode
) => {
	switch (authFormType) {
		case LegacyRehabAuthFormTypes.Neuro_Peds:
		case LegacyRehabAuthFormTypes.Orthopedic:
		case LegacyRehabAuthFormTypes.Pediatric:
			if (
				practitionerSpecialtyCode === Specialty.PTPractitioner ||
				providerSpecialtyCode === Specialty.HPPTProvider
			) {
				return AuthTherapyTypes.PT;
			}
			if (
				practitionerSpecialtyCode === Specialty.OTPractitioner ||
				providerSpecialtyCode === Specialty.HPOTProvider
			) {
				return AuthTherapyTypes.OT;
			}
			return undefined;
		case LegacyRehabAuthFormTypes.Dysphagia:
		case LegacyRehabAuthFormTypes.Cognitive:
		case LegacyRehabAuthFormTypes.ContinuingCare:
			return AuthTherapyTypes.ST;
		case LegacyRehabAuthFormTypes.AT_Orthopedic:
			return AuthTherapyTypes.AT;
		case LegacyRehabAuthFormTypes.MedicalRecords:
			if (
				practitionerSpecialtyCode === Specialty.PTPractitioner ||
				providerSpecialtyCode === Specialty.HPPTProvider
			) {
				return AuthTherapyTypes.PT;
			}
			if (
				practitionerSpecialtyCode === Specialty.OTPractitioner ||
				providerSpecialtyCode === Specialty.HPOTProvider
			) {
				return AuthTherapyTypes.OT;
			}
			if (practitionerSpecialtyCode === Specialty.SLPPractitioner) {
				return AuthTherapyTypes.ST;
			}
			if (practitionerSpecialtyCode === Specialty.AthleticTrainer) {
				return AuthTherapyTypes.AT;
			}
			return undefined;
		default:
			return undefined;
	}
};

export const AuthTypeLabels = Object.freeze({
	NewCondition: 'New Condition',
	ContinuingCare: 'Continuing Care',
});

export const AuthTypes = Object.freeze({
	NewCondition: 1,
	ContinuingCare: 2,
});
