import Typography from '@mui/material/Typography';
import ClinicalFileType from '../../scripts/clinicalFileType';

import ResourceTablePage from './shared/ResourceTablePage';

const ProviderPractitionerVirtualServicesAgreement = () => (
	<ResourceTablePage
		pageTitle='Provider Services / Practitioner Virtual Service Agreement'
		pageSummary={
			<Typography mb={2} fontWeight='fontWeightBold'>
				The Provider Services/Practitioner Virtual Services Agreement
				goes into detail about Provider/Practitioner obligations agreed
				to for participation in the network.
			</Typography>
		}
		resourceColumnTitle='Provider Services / Practitioner Virtual Service Agreement'
		clinicalFileType={
			ClinicalFileType.ashlinkClinicalProviderServicesAgreementResource
				.value
		}
		disableReleaseDateColumn
		showAgreementTypeColumn
		autoRowHeight
	/>
);

export default ProviderPractitionerVirtualServicesAgreement;
