import { createContext } from 'react';

export const AuthSubmissionContext = createContext({
	sessionProviderId: null,
	sessionProviderSpecialtyCode: null,
	sessionProviderNetCode: null,
	sessionProviderFirstName: null,
	sessionProviderLastName: null,
	sessionPractitionerId: null,
	sessionPractitionerSpecialtyCode: null,
	errorMessage: null,
	setError: () => {},
	selectionData: null,
	setSelectionData: () => {},
	authFormData: null,
	setAuthFormData: () => {},
});
