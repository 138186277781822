import { rest } from 'msw';

export const responses = {
	success: {
		resultCode: 0,
		resultText: 'Success',
		data: {
			result: [
				{
					id: '3OealYJTA4AgkBi22NhUgH',
					displayName: 'Policy 2',
					effectiveDate: '2023-01-01',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'Policies',
				},
				{
					id: '2Z67AsG2kG0AUFRrfX7eQm',
					displayName: 'Policy 1',
					effectiveDate: '2023-01-01',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'Policies',
				},
				{
					id: '7EG32FQIrK1aAUyTM1f3Ve',
					displayName: 'Arthritis Treatments',
					effectiveDate: '2023-01-01',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'Patient Education',
				},
				{
					id: '40rA9ROXcIEZWGGiEdsTJg',
					displayName: 'Health Effects of Tobacco Use',
					effectiveDate: '2023-01-01',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'Patient Education',
				},
				{
					id: '2Egx7q1SNlCEVumxqmyY4C',
					displayName: 'Networks Rehab PAHAC Actions Log',
					effectiveDate: '2023-01-01',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection:
						'Professional Affairs Healthcare Advisory Committee',
				},
				{
					id: '43tlsMHpnkV7oxGrRr6tmE',
					displayName: 'Plans Rehab PAHAC Minutes',
					effectiveDate: '2023-01-01',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection:
						'Professional Affairs Healthcare Advisory Committee',
				},
				{
					id: '4SG6i3vHUAvfgwnkBHLih5',
					displayName: 'What is EEC?',
					effectiveDate: '2023-01-01',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'Evidence Evaluation Committee',
				},
				{
					id: '4pKht7xv1q3QufRbQgQtDq',
					displayName: 'Covered Conditions',
					effectiveDate: '2023-01-01',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'Clinical Topics',
				},
				{
					id: '3Mq1riMmQB8TAeg6fESsj6',
					displayName:
						'CMS required Acu Benefit for Chronic Lower Back Pain',
					effectiveDate: '2023-01-01',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'Clinical Topics',
				},
				{
					id: '3daKul5tuBLt6DHU9jBBP9',
					displayName: 'Clearinghouse Guide',
					effectiveDate: '2023-01-01',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'Admin Topics',
				},
				{
					id: '2Lx42PfEPUjexm1fAfgudy',
					displayName: 'Rehab Welcome Flier',
					effectiveDate: '2023-01-01',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'Admin Topics',
				},
				{
					id: '6F6Zx1bT4XkPp0TuRC9Cyp',
					displayName: 'Functional Outcome Measures',
					effectiveDate: '2023-01-01',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'Admin Topics',
				},
				{
					id: '65553d9efc13ae4f4c22b736',
					displayName: 'Accreditation and Delegation',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'About ASH',
				},
				{
					id: '65553d9efc13ae4f4c22b737',
					displayName: 'Administrative Committees',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'About ASH',
				},

				{
					id: '65553d9efc13ae4f4c22b738',
					displayName:
						'Annual Quality Improvement Program Evaluation',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'About ASH',
				},

				{
					id: '65553d9efc13ae4f4c22b739',
					displayName: 'Board of Directors',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'About ASH',
				},

				{
					id: '65553d9efc13ae4f4c22b740',
					displayName: 'Clinical Committees',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'About ASH',
				},

				{
					id: '65553d9efc13ae4f4c22b741',
					displayName: 'Commitment to Quality',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'About ASH',
				},

				{
					id: '65553d9efc13ae4f4c22b742',
					displayName: 'Committees and Key Process Teams',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'About ASH',
				},

				{
					id: '65553d9efc13ae4f4c22b743',
					displayName: 'Confidentiality',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'About ASH',
				},
				{
					id: '65553d9efc13ae4f4c22b744',
					displayName: 'Overview of ASH Network Programs',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'About ASH',
				},
				{
					id: '65553d9efc13ae4f4c22b745',
					displayName: 'Supplier Diversity Program',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'About ASH',
				},
				{
					id: '65553d9efc13ae4f4c22b746',
					displayName: 'List of Technical Contacts',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'About ASH',
				},
				{
					id: '65553d9efc13ae4f4c22b747',
					displayName: 'Our Development Teams',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'About ASH',
				},
				{
					id: '65553d9efc13ae4f4c22b748',
					displayName: 'IT Security and Information Policies',
					url: '//assets.ctfassets.net/1g6by23er3py/5dFeHxIZwsKGnAVQOJqU8r/15209dcbea404719a5587d983238b42d/Test_PDF.pdf',
					siteSection: 'About ASH',
				},
				{
					id: '65553d9efc13ae4f4c22b6e8',
					displayName: 'ASH Clinical Performance System',
					videoUrl: 'Video Series',
					url: 'https://ep-prod-ashn-saas-mediaservice-p-fac2.westus2.streaming.mediakind.com/261967b7-485e-4cf8-b657-f95623a22d0b/Acupuncture%20Overview.ism/manifest',
					videoDuration: '12:34',
				},
				{
					id: '65553d9efc13ae4f4c22b6e9',
					displayName:
						'Improving the Documentation of Chiropractic Services',
					siteSection: 'Video Series',
					videoUrl:
						'https://ep-prod-ashn-saas-mediaservice-p-fac2.westus2.streaming.mediakind.com/261967b7-485e-4cf8-b657-f95623a22d0b/Acupuncture%20Overview.ism/manifest',
					videoDuration: '20:10',
				},
				{
					id: '65553d9efc13ae4f4c22b6ea',
					displayName: 'Communications',
					siteSection: 'Video Series',
					videoUrl:
						'https://ep-prod-ashn-saas-mediaservice-p-fac2.westus2.streaming.mediakind.com/261967b7-485e-4cf8-b657-f95623a22d0b/Acupuncture%20Overview.ism/manifest',
					videoDuration: '06:04',
				},
				{
					id: '65553d9efc13ae4f4c22b6eb',
					displayName: 'Test Video',
					siteSection: 'Video Series',
					videoUrl:
						'https://ep-prod-ashn-saas-mediaservice-p-fac2.westus2.streaming.mediakind.com/261967b7-485e-4cf8-b657-f95623a22d0b/Acupuncture%20Overview.ism/manifest',
					videoDuration: '02:00',
				},
				{
					id: '65553d9efc13ae4f4c22b6ec',
					displayName: 'Test Video MP4',
					siteSection: 'Video Series',
					videoUrl:
						'https://videos.ctfassets.net/1g6by23er3py/3ffttJJB87e7P8DcFwerUh/7d26e1b81788beec33e488f361ef531c/Acu_Association_Provider_Webinar_updated_8.16.24_KO.mp4',
					videoDuration: '02:00',
				},
			],
		},
		date: '2023-02-15T23:25:39.313711Z',
	},
	fail: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 32,
		subResultText: 'ContentManagementApiFail',
		errors: [
			{
				message:
					'An error occured while getting the ClinicalPELResources.',
			},
		],
		date: '2023-11-10T08:37:17.4001843-08:00',
	},
	notFound: {
		resultCode: 1,
		resultText: 'Fail',
		subResultCode: 9001,
		subResultText: 'ContentNotFound',
		errors: [
			{
				message: 'No content returned from Contentful.',
			},
		],
		date: '2023-02-22T05:36:28.3714538Z',
	},
};

export const clinicalPELResourcesSuccess = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalPELResources',
		(req, res, ctx) => res(ctx.status(200), ctx.json(responses.success))
	),
];

export const clinicalPELResourcesLoading = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalPELResources',
		(req, res, ctx) => res(ctx.delay('infinite'))
	),
];

export const clinicalPELResourcesFailWithSubResultCode32 = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalPELResources',
		(req, res, ctx) => res(ctx.status(200), ctx.json(responses.fail))
	),
];

export const clinicalPELResourcesNoResults = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalPELResources',
		(req, res, ctx) => res(ctx.status(200), ctx.json(responses.notFound))
	),
];

export const clinicalPELResourcesFail = [
	rest.get(
		'/clinical/api/ashlinknextapigateway/Clinical/PostLogin/ClinicalPELResources',
		(req, res, ctx) => res(ctx.status(500), ctx.json(responses.fail))
	),
];

export const clinicalPELResources = [
	...clinicalPELResourcesSuccess,
	...clinicalPELResourcesLoading,
	...clinicalPELResourcesNoResults,
	...clinicalPELResourcesFail,
	...clinicalPELResourcesFailWithSubResultCode32,
];
