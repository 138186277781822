import { LegacyRehabAuthFormTypes } from './authFormHelpers';

export const legacyAuthFormsList = [
	LegacyRehabAuthFormTypes.Neuro_Peds,
	LegacyRehabAuthFormTypes.Orthopedic,
	LegacyRehabAuthFormTypes.Pediatric,
	LegacyRehabAuthFormTypes.Dysphagia,
	LegacyRehabAuthFormTypes.Cognitive,
	LegacyRehabAuthFormTypes.ContinuingCare,
	LegacyRehabAuthFormTypes.AT_Orthopedic,
];

export const RedirectToLegacyForm = authFormType => {
	switch (authFormType) {
		case LegacyRehabAuthFormTypes.Neuro_Peds:
			window.open(
				`/ASH/transactions/treatments/pt_ot/emnr.aspx`,
				'_self',
				'noreferrer'
			);
			break;
		case LegacyRehabAuthFormTypes.Orthopedic:
			window.open(
				`/ASH/transactions/treatments/pt_ot/emnr.aspx`,
				'_self',
				'noreferrer'
			);
			break;
		case LegacyRehabAuthFormTypes.Pediatric:
			window.open(
				`/ASH/transactions/treatments/pt_ot/pediatric.aspx`,
				'_self',
				'noreferrer'
			);
			break;
		case LegacyRehabAuthFormTypes.Dysphagia:
			window.open(
				`/ASH/transactions/treatments/pt_ot/dysphagiaSt.aspx`,
				'_self',
				'noreferrer'
			);
			break;
		case LegacyRehabAuthFormTypes.Cognitive:
			window.open(
				`/ASH/transactions/treatments/pt_ot/cognitiveSt.aspx`,
				'_self',
				'noreferrer'
			);
			break;
		case LegacyRehabAuthFormTypes.ContinuingCare:
			window.open(
				`/ASH/transactions/treatments/speech/speech_cont_care.aspx`,
				'_self',
				'noreferrer'
			);
			break;
		case LegacyRehabAuthFormTypes.AT_Orthopedic:
			window.open(
				`/ASH/transactions/treatments/pt_ot/emnr.aspx`,
				'_self',
				'noreferrer'
			);
			break;
		default:
			throw new Error(`No redirect found for ${authFormType} form`);
	}
};
