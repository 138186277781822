import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

const PageNotFound = () => (
	<Container align='center'>
		<Typography
			variant='h1'
			sx={{ '@media (min-width: 600px)': { fontSize: 288 } }}
		>
			404
		</Typography>
		<Typography variant='h2' sx={{ fontWeight: 600, mb: 2 }}>
			Page not found
		</Typography>
		<Typography
			variant='subtitle2'
			component='p'
			sx={{ fontSize: 18, mb: 2 }}
		>
			We&apos;re sorry, the page you are looking for doesn&apos;t exist or
			an error occured
		</Typography>
	</Container>
);

export default PageNotFound;
