import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import EveThemeProvider from '@ashn/eve-themes-react/EveThemeProvider';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';

import SideNavigation from '../SideNavigation/SideNavigation';
import useIsMobile from '../../../hooks/useIsMobile';

const PageLayout = ({ pageTitle, navItems, children }) => {
	const isMobile = useIsMobile();

	return (
		<EveThemeProvider themeKey='internal' themeMode='light'>
			<Container>
				<Typography variant='h4' component='h1' mb={2} pt={3}>
					{pageTitle}
				</Typography>
				<Divider sx={{ mb: 4 }} />
				<Grid container direction='row' spacing={3}>
					{!isMobile && navItems && (
						<Grid item sx={{ width: '274px' }}>
							<SideNavigation pages={navItems} />
						</Grid>
					)}
					<Grid item xs>
						{children}
						<Outlet />
					</Grid>
				</Grid>
			</Container>
		</EveThemeProvider>
	);
};

export default PageLayout;

PageLayout.propTypes = {
	pageTitle: PropTypes.string.isRequired,
	navItems: PropTypes.array,
	children: PropTypes.node,
};

PageLayout.defaultProps = {
	children: undefined,
	navItems: undefined,
};
