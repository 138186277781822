import Typography from '@mui/material/Typography';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import {
	SpecialtyDescription,
	specialtyLabel,
} from '../../../../../../../scripts/specialty';
import { AuthSubmissionContext } from '../../AuthSubmissionContext';

const ShowSpecialty = ({ specialtyCodeOverride }) => {
	const { sessionProviderSpecialtyCode, sessionPractitionerSpecialtyCode } =
		useContext(AuthSubmissionContext);

	return (
		<>
			<Typography variant='h5' component='h1' color='primary'>
				Specialty
			</Typography>
			<Typography>
				{specialtyCodeOverride
					? SpecialtyDescription[specialtyCodeOverride]
					: specialtyLabel(
							sessionProviderSpecialtyCode,
							sessionPractitionerSpecialtyCode
					  )}
			</Typography>
		</>
	);
};

ShowSpecialty.propTypes = {
	specialtyCodeOverride: PropTypes.number,
};

ShowSpecialty.defaultProps = {
	specialtyCodeOverride: null,
};

export default ShowSpecialty;
