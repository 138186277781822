import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { AuthSubmissionContext } from '../AuthSubmissionContext';
import MedicalRecordsForm from './shared/MedicalRecordsForm/MedicalRecordsForm';
import { LegacyRehabAuthFormTypes } from '../../../../../../scripts/authFormHelpers';

const CompleteAuth = ({ onFormComplete }) => {
	const {
		selectionData: { authFormType },
	} = useContext(AuthSubmissionContext);
	switch (authFormType) {
		case LegacyRehabAuthFormTypes.MedicalRecords:
			return <MedicalRecordsForm onFinalContinueClick={onFormComplete} />;
		default:
			return <Typography> This form is not supported</Typography>;
	}
};

CompleteAuth.propTypes = {
	onFormComplete: PropTypes.func.isRequired,
};

export default CompleteAuth;
