import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const SideNavigation = ({ pages }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const onNavItemClick = (path, isLegacy) => {
		if (isLegacy) {
			window.open(path, '_self', 'noreferrer');
		} else {
			navigate(path);
		}
	};

	return (
		<Paper variant='outlined' square={false}>
			<List>
				{pages.map(item => (
					<ListItem key={item.label} disablePadding>
						<ListItemButton
							selected={location.pathname === item.path}
						>
							<ListItemText
								primary={item.label}
								onClick={() =>
									onNavItemClick(item.path, item.isLegacy)
								}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Paper>
	);
};

export default SideNavigation;

SideNavigation.propTypes = {
	pages: PropTypes.array.isRequired,
};
