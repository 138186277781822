import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useQuery } from 'react-query';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import useSessionContext from '../../data/queryHooks/useSessionContext';
import { GetFAQBySpecialtyFn } from '../../data/apiCalls';
import CustomLoading from '../shared/CustomLoading';
import CustomNoResultsOverlay from '../shared/CustomNoResultsOverlay';
import MarkdownLinkRenderer from '../shared/MarkdownLinkRenderer';
import MarkdownParagraphRenderer from '../shared/MarkdownParagraphRenderer';

const FAQ = () => {
	const { drFedId } = useSessionContext();

	const frequentlyAskedQuestions = useQuery(
		['frequentlyAskedQuestions'],
		() => GetFAQBySpecialtyFn(drFedId),
		{ staleTime: Infinity }
	);

	const {
		isLoading,
		data: {
			status,
			data: {
				resultCode,
				subResultCode,
				data: { entries = [] } = {},
			} = {},
		} = {},
	} = frequentlyAskedQuestions;

	const [expanded, setExpanded] = useState(false);

	const sortEntries = () => {
		const result = {};
		const finalList = [];

		entries.forEach(faq => {
			const { category, categoryOrderNumber } = faq;
			if (!result[category]) {
				result[category] = {
					entries: [],
					orderNumber: categoryOrderNumber,
				};
			}
			result[category].entries.push(faq);
		});
		Object.keys(result).forEach(category =>
			finalList.push({
				categoryName: category,
				orderNumber: result[category].orderNumber,
				entries: result[category].entries.sort(
					(a, b) => a.questionOrderNumber - b.questionOrderNumber
				),
			})
		);
		return finalList.sort((a, b) => a.orderNumber - b.orderNumber);
	};

	const FAQEntries = sortEntries();

	const handleChange = (isExpanded, panel) => {
		setExpanded(isExpanded ? panel : false);
	};

	const getContentBody = () =>
		entries.length === 0 ? (
			<CustomNoResultsOverlay
				resultCode={resultCode}
				statusCode={status}
				subResultCode={subResultCode}
			/>
		) : (
			FAQEntries.map(faq => (
				<Box key={faq.categoryName}>
					<Typography variant='h6' mb={1} mt={2} component='h2'>
						{faq.categoryName}
					</Typography>
					{faq.entries.map(entry => (
						<Box mb={1} key={entry.id}>
							<Accordion
								expanded={expanded === entry.id}
								onChange={(e, isExpanded) =>
									handleChange(isExpanded, entry.id)
								}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls={entry.id}
								>
									<Typography>{entry.question}</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<ReactMarkdown
										components={{
											a: MarkdownLinkRenderer,
											p: MarkdownParagraphRenderer,
										}}
									>
										{entry.answer}
									</ReactMarkdown>
								</AccordionDetails>
							</Accordion>
						</Box>
					))}
				</Box>
			))
		);

	return (
		<Paper elevation={0}>
			<Container sx={{ py: 2 }}>
				<Typography variant='h5' component='h1' mb={2}>
					FAQ
				</Typography>
				<Divider sx={{ mb: 2 }} />
				<Box>
					{!isLoading ? (
						getContentBody()
					) : (
						<Box mt={5}>
							<CustomLoading />
						</Box>
					)}
				</Box>
			</Container>
		</Paper>
	);
};

export default FAQ;
