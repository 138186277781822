import PageLayout from '../shared/PageLayout/PageLayout';
import useSessionContext from '../../data/queryHooks/useSessionContext';
import { getMyAccountNavs } from '../../scripts/navigationHelpers';

const MyAccount = () => {
	const { permissions, redirectionLinks } = useSessionContext();
	return (
		<PageLayout
			pageTitle='My Account'
			navItems={getMyAccountNavs(permissions, redirectionLinks)}
		/>
	);
};

export default MyAccount;
