/* eslint-disable lines-between-class-members */
class ClinicalFileType {
	// Create new instances of the same class as static attributes
	static ashlinkClinicalProviderServicesAgreementResource =
		new ClinicalFileType(1);
	static ashlinkClinicalOperationsManualResource = new ClinicalFileType(3);
	static ashlinkClinicalClientSummaryResource = new ClinicalFileType(4);
	static ashlinkClinicalFormResource = new ClinicalFileType(5);
	static ashlinkClinicalNotificationResource = new ClinicalFileType(6);
	static ashlinkClinicalNewsletterResource = new ClinicalFileType(7);
	constructor(value) {
		this.value = value;
	}
}

export default ClinicalFileType;
