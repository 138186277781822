import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useSessionContext from '../../../data/queryHooks/useSessionContext';
import TierLevel from './TierLevel';

export const SWITCH_CLINIC_URL = `/ASH/site/Authentication/chooseclinic.aspx`;

const PractitionerInfo = () => {
	const { palette } = useTheme();

	const { userInfo } = useSessionContext();
	const {
		lastName = '',
		firstName = '',
		address1 = '',
		city = '',
		specialtyDescription = '',
		state = '',
		tierLevel = 1,
		zipCode = '',
	} = userInfo;

	return (
		<Stack
			spacing={2}
			divider={
				<Box
					display='flex'
					sx={{
						borderRight: '1px solid',
						backgroundColor: palette.text.light,
					}}
				/>
			}
			color={palette.text.primary}
			direction='row'
			justifyContent='flex-end'
		>
			<Typography variant='subtitle2'>
				{`${firstName} ${lastName}`}
			</Typography>
			<Typography variant='subtitle2'>
				{`${address1} - ${city}, ${state} ${zipCode}`}
			</Typography>
			<TierLevel
				tierLevel={tierLevel}
				specialtyDescription={specialtyDescription}
			/>
			{specialtyDescription !== 'PT/OT' && (
				<Link href={SWITCH_CLINIC_URL} variant='subtitle2'>
					Switch Clinic
				</Link>
			)}
		</Stack>
	);
};

export default PractitionerInfo;
