import PageLayout from '../shared/PageLayout/PageLayout';
import useSessionContext from '../../data/queryHooks/useSessionContext';
import { getResourcesNavs } from '../../scripts/navigationHelpers';

const Resources = () => {
	const { permissions, redirectionLinks } = useSessionContext();
	return (
		<PageLayout
			pageTitle='Resources'
			navItems={getResourcesNavs(permissions, redirectionLinks)}
		/>
	);
};

export default Resources;
