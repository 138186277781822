import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import Alert from '@mui/material/Alert';
import CustomLoading from '../../../../../../shared/CustomLoading';
import { AuthSubmissionContext } from '../../AuthSubmissionContext';
import { GetServiceCodes } from '../../../../../../../data/ihisApiCalls';

const ConditionAndTreatmentCodeSearch = ({
	codeType,
	onCodeSelected,
	onCancelClick,
	titleId,
}) => {
	const [codeSearchText, setCodeSearchText] = useState('');
	const [descriptionSearchText, setDescriptionSearchText] = useState('');
	const [showDataGrid, setShowDataGrid] = useState(false);
	const [pageSize, setPageSize] = useState(10);

	const { sessionProviderSpecialtyCode } = useContext(AuthSubmissionContext);

	const { isLoading, isError, refetch, data } = useQuery(
		['ServiceCodeSearch', codeType, codeSearchText, descriptionSearchText],
		() =>
			GetServiceCodes(
				codeType,
				codeSearchText,
				descriptionSearchText,
				sessionProviderSpecialtyCode
			),
		{
			staleTime: Infinity,
			refetchOnWindowFocus: false,
			enabled: false,
			retry: false,
		}
	);

	const { data: { data: { serviceCodes = [] } = {} } = {} } = data ?? {};

	const handleCodeSearchTextChange = event => {
		setCodeSearchText(event.target.value);
		setDescriptionSearchText('');
	};
	const handleDescriptionSearchTextChange = event => {
		setDescriptionSearchText(event.target.value);
		setCodeSearchText('');
	};
	const handleSearchButtonClick = () => {
		refetch();
		setShowDataGrid(true);
	};

	const columns = [
		{
			field: 'code',
			headerName: 'Code',
			renderCell: params => (
				<Button
					onClick={() =>
						onCodeSelected(params.value, params.row.description)
					}
				>
					{params.value}
				</Button>
			),
		},
		{
			field: 'description',
			headerName: 'Description',
			flex: 1,
		},
	];

	return (
		<Stack spacing={3}>
			{isError && (
				<Alert severity='error'>
					Something went wrong. Please try again later
				</Alert>
			)}
			<Typography id={titleId} variant='h6'>
				{codeType} Code Search
			</Typography>
			<TextField
				placeholder={`Enter ${codeType} Code`}
				value={codeSearchText}
				onChange={handleCodeSearchTextChange}
			/>
			<Stack direction='row' justifyContent='center'>
				<Typography textTransform='uppercase'>or</Typography>
			</Stack>
			<TextField
				placeholder='Enter Text Description'
				value={descriptionSearchText}
				onChange={handleDescriptionSearchTextChange}
			/>
			<Stack direction='row' spacing={2} justifyContent='right'>
				<Button onClick={onCancelClick}>cancel</Button>
				<Button variant='contained' onClick={handleSearchButtonClick}>
					search
				</Button>
			</Stack>
			{showDataGrid && (
				<DataGrid
					autoHeight
					rows={serviceCodes ?? []}
					loading={isLoading}
					columns={columns}
					getRowId={row => row.code}
					components={{
						LoadingOverlay: CustomLoading,
					}}
					componentsProps={{
						toolbar: {
							printOptions: {
								disableToolbarButton: true,
							},
							csvOptions: { disableToolbarButton: true },
						},
					}}
					pageSize={pageSize}
					onPageSizeChange={newPageSize => setPageSize(newPageSize)}
					rowsPerPageOptions={[10, 20, 50]}
					hideFooterSelectedRowCount
					disableMultipleSelection
					disableColumnMenu
					disableColumnFilter
					disableColumnSelector
					disableDensitySelector
				/>
			)}
		</Stack>
	);
};
ConditionAndTreatmentCodeSearch.propTypes = {
	codeType: PropTypes.oneOf(['Dx', 'CPT', 'HCPCS']).isRequired,
	onCodeSelected: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
	titleId: PropTypes.string,
};

ConditionAndTreatmentCodeSearch.defaultProps = {
	titleId: undefined,
};

export default ConditionAndTreatmentCodeSearch;
