import { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import SelectAuthType from './shared/SelectAuthType';
import SelectPractitioner from './shared/SelectPractitioner';
import SelectServiceLocation from './shared/SelectServiceLocation';
import ShowSpecialty from './shared/ShowSpecialty';
import SelectSubmissionType from './shared/SelectSubmissionType';
import SelectAuthForm from './shared/SelectAuthForm';
import SelectSubmitHealthStatus from './shared/SelectSubmitHealthStatus';
import {
	AuthTypes,
	GetAuthTherapyType,
	LegacyRehabAuthFormTypes,
} from '../../../../../../scripts/authFormHelpers';
import useSessionContext from '../../../../../../data/queryHooks/useSessionContext';

const SelectAuthFormAndPractitioner = ({ onContinueClick }) => {
	const { providerSpecialtyCode } = useSessionContext();

	const [practitioner, setPractitioner] = useState();
	const [submissionType, setSubmissionType] = useState();
	const [authType, setAuthType] = useState();
	const [authFormType, setAuthFormType] = useState();
	const [submitHealthStatusForm, setSubmitHealthStatusForm] = useState();
	const [serviceLocation, setServiceLocation] = useState();

	const isValid = () =>
		practitioner &&
		((submissionType === 'ASH Electronic MNR Form' && authFormType) ||
			submissionType === 'Medical Records') &&
		authType &&
		submitHealthStatusForm &&
		serviceLocation;

	const handleContinueClick = () => {
		const authFormTypeValue =
			submissionType === 'Medical Records'
				? LegacyRehabAuthFormTypes.MedicalRecords
				: authFormType;
		const practitionerNameNpi = `${practitioner.firstName} ${practitioner.lastName} - ${practitioner.npiTypeI}`;
		const authTherapyType = GetAuthTherapyType(
			authFormTypeValue,
			practitioner.specialtyCode,
			providerSpecialtyCode
		);
		onContinueClick({
			practitioner,
			practitionerNameNpi,
			submissionType,
			authType,
			authFormType: authFormTypeValue,
			authTherapyType,
			submitHealthStatusForm,
			serviceLocation,
		});
	};

	return (
		<Paper elevation={1} variant='wrapper'>
			<Stack gap={2}>
				<SelectPractitioner onSelect={p => setPractitioner(p)} />
				{practitioner && (
					<>
						<ShowSpecialty
							specialtyCodeOverride={practitioner.specialtyCode}
						/>
						<SelectSubmissionType
							onSelect={p => {
								setSubmissionType(p);
								setAuthFormType(null);
							}}
						/>
					</>
				)}
				{practitioner && submissionType && (
					<>
						<Typography variant='h5' component='h1' color='primary'>
							E-MNR
						</Typography>
						<SelectAuthType
							onSelect={p => {
								setAuthType(p);
								setAuthFormType(null);
							}}
						/>
					</>
				)}
				{practitioner && authType && (
					<>
						<SelectAuthForm
							value={authFormType}
							practitionerSpecialtyCodeOverride={
								practitioner.specialtyCode
							}
							isNewCare={authType === AuthTypes.NewCondition}
							isMedicalRecords={
								submissionType === 'Medical Records'
							}
							onSelect={p => setAuthFormType(p)}
						/>
						<SelectSubmitHealthStatus
							onSelect={p => setSubmitHealthStatusForm(p)}
						/>
					</>
				)}
				{practitioner && authType && submissionType && (
					<SelectServiceLocation
						practitionerId={practitioner.practitionerId}
						onSelect={p => setServiceLocation(p)}
					/>
				)}
				<Stack direction='row' justifyContent='center'>
					<Button
						disabled={!isValid()}
						onClick={handleContinueClick}
						variant='contained'
						sx={{ marginTop: 2 }}
					>
						Continue
					</Button>
				</Stack>
			</Stack>
		</Paper>
	);
};

SelectAuthFormAndPractitioner.propTypes = {
	onContinueClick: PropTypes.func.isRequired,
};

export default SelectAuthFormAndPractitioner;
