import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import HelpIcon from '@mui/icons-material/Help';
import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import PageLayout from '../shared/PageLayout/PageLayout';
import useSessionContext from '../../data/queryHooks/useSessionContext';
import {
	GetClinicalComplianceFn,
	GetClinicalComplianceStatusFn,
} from '../../data/apiCalls';

import CustomNoResultsOverlay from '../shared/CustomNoResultsOverlay';
import CustomLoading from '../shared/CustomLoading';

const Compliance = () => {
	const { palette } = useTheme();
	const {
		data: {
			status = undefined,
			data: {
				resultCode = undefined,
				data: { compliancePage = {} } = {},
			} = {},
		} = {},
		isLoading,
		isError,
	} = useQuery(['Compliance'], () => GetClinicalComplianceFn(), {
		staleTime: Infinity,
	});
	const { providerId } = useSessionContext();
	const {
		data: {
			complianceStatus = undefined,
			data: { data: { complianceStatuses = [] } = {} } = {},
		} = {},
		complianceIsLoading,
		complianceIsError,
	} = useQuery(
		['ComplianceStatus'],
		() => GetClinicalComplianceStatusFn(providerId),
		{
			staleTime: Infinity,
		}
	);
	const [expanded, setExpanded] = useState(false);
	const handleChange = (isExpanded, panel) => {
		setExpanded(isExpanded ? panel : false);
	};
	const [orderedRequirements, setOrderedRequirements] = useState([]);
	useEffect(() => {
		if (compliancePage?.requirements?.length) {
			const requirements = [...compliancePage.requirements];

			requirements.sort((a, b) => a.number - b.number);

			setOrderedRequirements(requirements);
		}
	}, [isLoading]);

	const formatMilestoneList = milestoneList => {
		let sections = '';
		if (milestoneList.length === 1) {
			sections = `${milestoneList[0]}`;
		} else {
			sections = `${milestoneList
				.slice(0, -1)
				.join(', ')} & ${milestoneList.slice(-1)}`;
		}
		return `Complete Section ${sections}`;
	};
	const showNoResultsOverlay = isError || (status >= 400 && status <= 505);
	const complianceStatusError =
		complianceIsError ||
		(complianceStatus >= 400 && complianceStatus <= 505);
	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.action.hover,
		},
		td: {
			border: 0,
		},
	}));

	return (
		<PageLayout pageTitle='Compliance'>
			{isLoading && <CustomLoading />}
			{showNoResultsOverlay && (
				<CustomNoResultsOverlay
					resultCode={resultCode}
					statusCode={status}
				/>
			)}
			{!isLoading &&
				!complianceIsLoading &&
				!showNoResultsOverlay &&
				compliancePage.requirements && (
					<>
						<Paper sx={{ pt: 1, px: 3, pb: 2, mb: 3 }}>
							<ReactMarkdown>
								{compliancePage.pageSummary}
							</ReactMarkdown>
						</Paper>
						<Paper sx={{ px: 4, pt: 2, pb: 4 }}>
							<Typography
								variant='h5'
								component='h1'
								fontWeight='fontWeightBold'
							>
								Compliance Requirement Timeline
							</Typography>
							<Divider sx={{ my: 2 }} />
							<Grid container>
								{compliancePage.timelines &&
									compliancePage.timelines.map(
										(timeline, index) => (
											<Grid
												item
												xs={12}
												md={6}
												key={timeline.id}
												sx={
													index % 2 === 0
														? {
																borderRight: {
																	md: `2px solid #BDBDBD`,
																},
																pr: {
																	md: 3,
																},
														  }
														: { pl: { md: 3 } }
												}
											>
												<Typography
													sx={{
														color: palette.primary
															.dark,
													}}
													component='h1'
													variant='h6'
												>
													{timeline.title}
												</Typography>
												<Table>
													<TableBody>
														{timeline.milestones.map(
															milestone => (
																<StyledTableRow
																	key={
																		milestone.id
																	}
																>
																	<TableCell>
																		<Typography>
																			{
																				milestone.title
																			}
																		</Typography>
																	</TableCell>
																	<TableCell>
																		<Typography>
																			{formatMilestoneList(
																				milestone.requirementNumbers
																			)}
																		</Typography>
																	</TableCell>
																</StyledTableRow>
															)
														)}
													</TableBody>
												</Table>
											</Grid>
										)
									)}
							</Grid>
						</Paper>
						<Box sx={{ mb: 7, mt: 4 }}>
							<Paper sx={{ pt: 2, pb: 4, px: 2.5 }}>
								{orderedRequirements.map(
									(requirement, index) => (
										<Accordion
											key={requirement.id}
											id={requirement.id}
											expanded={
												expanded === requirement.id
											}
											onChange={(e, isExpanded) =>
												handleChange(
													isExpanded,
													requirement.id
												)
											}
											sx={{ mt: 2 }}
										>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls={`${requirement.id}-panel-content`}
											>
												<Stack>
													<Typography
														sx={{
															fontWeight: 'bold',
														}}
													>
														{`${index + 1}. ${
															requirement.title
														}`}

														{requirement.links.some(
															x =>
																complianceStatuses.some(
																	y =>
																		y.complianceType ===
																			x.attestationLinkType &&
																		y.complete ===
																			false
																)
														) && (
															<WarningIcon
																data-testid='warning-icon'
																style={{
																	color: '#FFB400',
																}}
																severity='warning'
															/>
														)}
													</Typography>
													<Typography>
														{requirement.summary}
													</Typography>
												</Stack>
											</AccordionSummary>
											<AccordionDetails>
												{requirement.links.map(link => (
													<Typography key={link.id}>
														{link.prefix &&
															`${link.prefix} `}
														{(link.url ||
															link.fileUrl) && (
															<Link
																href={
																	link.url ||
																	link.fileUrl
																}
																target='_blank'
																rel='noreferrer'
															>
																{link.text}
																{!complianceStatusError &&
																	complianceStatuses?.some(
																		x =>
																			x.complianceType ===
																				link.attestationLinkType &&
																			x.complete ===
																				false
																	) && (
																		<WarningIcon
																			data-testid='warning-icon'
																			id='warning-icon'
																			style={{
																				color: '#FFB400',
																			}}
																			severity='warning'
																		/>
																	)}
															</Link>
														)}
														{link.tooltip && (
															<Tooltip
																title={
																	link.tooltip
																}
																placement='right'
															>
																<HelpIcon
																	color='primary'
																	fontSize='1rem'
																	sx={{
																		ml: 1,
																	}}
																/>
															</Tooltip>
														)}
													</Typography>
												))}
											</AccordionDetails>
										</Accordion>
									)
								)}
							</Paper>
						</Box>
					</>
				)}
		</PageLayout>
	);
};

export default Compliance;
