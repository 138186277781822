import {
	RESOURCES_NAV_ITEMS,
	BATCH_CLAIMS_NAV_ITEMS,
	HELP_NAV_ITEMS,
	PROFILE_ITEMS,
	TOP_NAV_ITEMS,
	FOOTER_ITEMS,
	TOP_SEARCH_NAV_ITEMS,
	MESSAGE_CENTER,
	MY_ACCOUNT_NAV_ITEMS,
} from './navigationItems';
import { pageKey, retrievePermissionWithKey } from './pageConstants';

export const getRedirectionLink = (page, redirectionLinks) =>
	redirectionLinks.filter(link => link.pageKey === page)[0]?.redirectUrl ||
	null;

// Use the nav's legacy redirection path if appropriate
export const updateNavsRedirectionPath = (navs, redirectionLinks) => {
	navs.forEach(nav => {
		const legacyLink = getRedirectionLink(nav.key, redirectionLinks);
		if (legacyLink) {
			nav.path = legacyLink;
			nav.isLegacy = true;
		}
	});
};

// Get all nav items that the user has permissions for
const getAccessibleDropdownNavs = (navItems, permissions) =>
	navItems.filter(navItem =>
		permissions.includes(retrievePermissionWithKey[navItem.key])
	);

const sortNavigationItems = navs => navs.sort((a, b) => a.order - b.order);

export const getResourcesNavs = (permissions, redirectionLinks) => {
	const navs = getAccessibleDropdownNavs(RESOURCES_NAV_ITEMS, permissions);

	// Search inside an array, these page keys have an array of permissions.
	[
		pageKey.providerEducationLibrary,
		pageKey.practitionerEducationLibrary,
	].forEach(pk => {
		if (
			retrievePermissionWithKey[pk].some(perm =>
				permissions.includes(perm)
			)
		) {
			navs.push(RESOURCES_NAV_ITEMS.find(item => item.key === pk));
		}
	});
	updateNavsRedirectionPath(navs, redirectionLinks);
	sortNavigationItems(navs);

	return navs;
};

export const getBatchClaimsNavs = (permissions, redirectionLinks) => {
	const navs = getAccessibleDropdownNavs(BATCH_CLAIMS_NAV_ITEMS, permissions);
	updateNavsRedirectionPath(navs, redirectionLinks);
	sortNavigationItems(navs);

	return navs;
};

export const getHelpNavs = (permissions, redirectionLinks) => {
	const navs = getAccessibleDropdownNavs(HELP_NAV_ITEMS, permissions);
	updateNavsRedirectionPath(navs, redirectionLinks);
	sortNavigationItems(navs);

	return navs;
};

const profileNavsHelper = (pagePermissions, permissions, navs, key) => {
	pagePermissions.every(permission => {
		if (permissions.includes(permission)) {
			navs.push(PROFILE_ITEMS.filter(item => item.key === key)[0]);
			return false;
		}
		return true;
	});
};

export const getMessageCenterPath = redirectionLinks => {
	updateNavsRedirectionPath([MESSAGE_CENTER], redirectionLinks);
	return MESSAGE_CENTER.path;
};

export const getProfileNavs = (permissions, redirectionLinks) => {
	const navs = getAccessibleDropdownNavs(PROFILE_ITEMS, permissions);

	profileNavsHelper(
		retrievePermissionWithKey[pageKey.accountManagement],
		permissions,
		navs,
		pageKey.accountManagement
	);

	profileNavsHelper(
		retrievePermissionWithKey[pageKey.confirmUpdateInformation],
		permissions,
		navs,
		pageKey.confirmUpdateInformation
	);

	updateNavsRedirectionPath(navs, redirectionLinks);
	sortNavigationItems(navs);

	return navs;
};

export const getFooterNavs = (permissions, redirectionLinks) => {
	const navs = getAccessibleDropdownNavs(FOOTER_ITEMS, permissions);
	updateNavsRedirectionPath(navs, redirectionLinks);

	return navs;
};

export const getSearchNavs = (permissions, redirectionLinks) => {
	const navs = getAccessibleDropdownNavs(TOP_SEARCH_NAV_ITEMS, permissions);
	updateNavsRedirectionPath(navs, redirectionLinks);
	sortNavigationItems(navs);

	return navs;
};

const headerNavsHelper = (navs, items, navKey, redirectionLinks) => {
	if (items && items.length > 0) {
		const topNav = TOP_NAV_ITEMS.filter(
			navItem => navItem.key === navKey
		)[0];
		topNav.menuItems = items;
		const redirectLink = getRedirectionLink(items[0].key, redirectionLinks);
		topNav.path = redirectLink || items[0].path;
		navs.push(topNav);
	}
};

export const getHeaderNavs = (permissions, redirectionLinks) => {
	const navs = getAccessibleDropdownNavs(TOP_NAV_ITEMS, permissions);

	headerNavsHelper(
		navs,
		getResourcesNavs(permissions, redirectionLinks),
		'resources',
		redirectionLinks
	);

	headerNavsHelper(
		navs,
		getBatchClaimsNavs(permissions, redirectionLinks),
		'batch-claims',
		redirectionLinks
	);

	headerNavsHelper(
		navs,
		getHelpNavs(permissions, redirectionLinks),
		'help',
		redirectionLinks
	);

	headerNavsHelper(
		navs,
		getSearchNavs(permissions, redirectionLinks),
		'search',
		redirectionLinks
	);

	updateNavsRedirectionPath(navs, redirectionLinks);

	sortNavigationItems(navs);
	return navs;
};

export const getHomePagePath = (permissions, redirectionLinks) => {
	const homePath = getHeaderNavs(permissions, redirectionLinks).filter(
		item => item.key === pageKey.home
	)[0]?.path;
	return homePath;
};

export const getMyAccountNavs = (permissions, redirectionLinks) => {
	const navs = getAccessibleDropdownNavs(MY_ACCOUNT_NAV_ITEMS, permissions);
	updateNavsRedirectionPath(navs, redirectionLinks);
	sortNavigationItems(navs);

	return navs;
};
