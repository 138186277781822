import Typography from '@mui/material/Typography';
import ClinicalFileType from '../../scripts/clinicalFileType';
import ResourceTablePage from './shared/ResourceTablePage';

const clinicalFileType =
	ClinicalFileType.ashlinkClinicalOperationsManualResource.value;

const OperationsManual = () => {
	const pageTitle = 'Operations Manuals';
	const resourceColumnTitle = 'Operations Manual Resource';
	const pageSummary = (
		<Typography fontWeight='fontWeightBold' mb={2}>
			The Operations Manual provides an overview of ASH as well as our
			confidentiality and accreditation requirements. It also contains the
			administrative procedures that Providers, Practitioners and their
			office staff should review. The clinical section outlines ASH
			Clinical Services Program. Practitioners should read this manual in
			its entirety, as they are accountable for all guidelines.
		</Typography>
	);

	return (
		<ResourceTablePage
			pageTitle={pageTitle}
			resourceColumnTitle={resourceColumnTitle}
			pageSummary={pageSummary}
			clinicalFileType={clinicalFileType}
			disableReleaseDateColumn
		/>
	);
};

export default OperationsManual;
