import { rest } from 'msw';

export const GetServiceCodeSuccess = [
	rest.get('/api/ashlinknextapigateway/ihis/ServiceCode', (req, res, ctx) => {
		const serviceCodeType = req.url.searchParams.get('serviceCodeType');
		const serviceCode = req.url.searchParams.get('serviceCode');

		if (!['Dx', 'CPT', 'HCPCS'].includes(serviceCodeType)) {
			return res(
				ctx.status(200),
				ctx.json({
					resultCode: 1,
					resultText: 'Fail',
					subResultCode: 12,
					subResultText: 'RequestValidationFail',
					errors: [
						{
							message: 'invalid service code type',
						},
					],
					date: '2024-07-02T09:25:48.3042649-05:00',
				})
			);
		}

		let categoryId = null;
		if (serviceCodeType === 'CPT') categoryId = 16;
		if (serviceCodeType === 'HCPCS') categoryId = 3;
		return res(
			ctx.status(200),
			ctx.json({
				resultCode: 0,
				resultText: 'Success',
				data: {
					serviceCodeType,
					code: serviceCode,
					description:
						serviceCode === '100' ? 'TEST APPLICATION CODE' : null,
					isValid: serviceCode === '100',
					categoryId,
					isCorrectCategory: ['CPT', 'HCPCS'].includes(
						serviceCodeType
					)
						? true
						: null,
				},
				date: '2024-07-02T09:25:48.3042649-05:00',
			})
		);
	}),
];

export const GetServiceCodeLoading = [
	rest.get('/api/ashlinknextapigateway/ihis/ServiceCode', (req, res, ctx) =>
		res(ctx.delay('infinite'))
	),
];

export const GetServiceCodeError = [
	rest.get('/api/ashlinknextapigateway/ihis/ServiceCode', (req, res, ctx) =>
		res(
			ctx.status(500),
			ctx.json({
				resultCode: 1,
				resultText: 'Fail',
				errors: [
					{
						message: 'Mocked error',
					},
				],
				date: '2024-07-02T09:25:48.3042649-05:00',
			})
		)
	),
];

export const GetServiceCodesSuccess = [
	rest.get(
		'/api/ashlinknextapigateway/ihis/ServiceCodes',
		(req, res, ctx) => {
			const serviceCodeType = req.url.searchParams.get('serviceCodeType');
			const codeSearch = req.url.searchParams.get('codeSearch');
			const descriptionSearch =
				req.url.searchParams.get('descriptionSearch');

			if (!['Dx', 'CPT', 'HCPCS'].includes(serviceCodeType)) {
				return res(
					ctx.status(200),
					ctx.json({
						resultCode: 1,
						resultText: 'Fail',
						subResultCode: 12,
						subResultText: 'RequestValidationFail',
						errors: [
							{
								message: 'invalid service code type',
							},
						],
						date: '2024-07-02T09:25:48.3042649-05:00',
					})
				);
			}

			if (
				!codeSearch.includes('100') &&
				!descriptionSearch.includes('TEST APPLICATION CODE')
			) {
				return res(
					ctx.status(200),
					ctx.json({
						resultCode: 0,
						resultText: 'Success',
						data: {
							serviceCodes: [],
						},
						date: '2024-07-02T09:25:48.3042649-05:00',
					})
				);
			}

			let categoryId = '';
			switch (serviceCodeType) {
				case 'Dx':
					categoryId = null;
					break;
				case 'CPT':
					categoryId = 16;
					break;
				case 'HCPCS':
					categoryId = 3;
					break;
				default:
					categoryId = null;
					break;
			}

			return res(
				ctx.status(200),
				ctx.json({
					resultCode: 0,
					resultText: 'Success',
					data: {
						serviceCodes: [
							{
								code: '100',
								description: 'TEST APPLICATION CODE',
								categoryId,
							},
						],
					},
					date: '2024-07-02T09:25:48.3042649-05:00',
				})
			);
		}
	),
];

export const GetServiceCodesEmpty = [
	rest.get('/api/ashlinknextapigateway/ihis/ServiceCodes', (req, res, ctx) =>
		res(
			ctx.status(200),
			ctx.json({
				resultCode: 0,
				resultText: 'Success',
				data: {
					serviceCodes: [],
				},
				date: '2024-07-02T09:25:48.3042649-05:00',
			})
		)
	),
];

export const GetServiceCodesLoading = [
	rest.get('/api/ashlinknextapigateway/ihis/ServiceCodes', (req, res, ctx) =>
		res(ctx.delay('infinite'))
	),
];

export const GetServiceCodesError = [
	rest.get('/api/ashlinknextapigateway/ihis/ServiceCodes', (req, res, ctx) =>
		res(
			ctx.status(500),
			ctx.json({
				resultCode: 1,
				resultText: 'Fail',
				errors: [
					{
						message: 'Mocked error',
					},
				],
				date: '2024-07-02T09:25:48.3042649-05:00',
			})
		)
	),
];
