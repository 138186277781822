import { useQuery } from 'react-query';
import { GetSessionData } from '../apiCalls';
import { QueryKey } from './QueryKeys';

const useSessionData = name => {
	const { isLoading, isError, data, error } = useQuery(
		[QueryKey.GET_SESSION_DATA, name],
		GetSessionData,
		{
			refetchOnWindowFocus: false,
		}
	);

	let parsedData;

	if (data?.resultCode === 0 && !!data.data?.jsonData) {
		parsedData = JSON.parse(data.data.jsonData);
	}

	return {
		isLoading,
		isError,
		error,
		data: parsedData,
	};
};

export default useSessionData;
